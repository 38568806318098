import React, { Fragment, useContext, useEffect, useState } from "react";
import EventPreview from "./EventPreview";
import { SavedEventsApi } from "../../constants/API";
import Spinner from "../Spinner";
import { toast } from "react-toastify";
import { Language } from "../../App";

const SavedEvents = () => {
  const localData = JSON.parse(sessionStorage.getItem("profileData"));
  const user_id = localData?.user?.id;
  const [eventsData, setEventsData] = useState([]);
  const [spin, setSpin] = useState(false);
  const { lang } = useContext(Language);
  const handleEvents = () => {
    setSpin(true);
    fetch(`${SavedEventsApi}${user_id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.status) {
          setEventsData(data.data);
        } else {
          toast.error(data.error);
        }
        setSpin(false);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    handleEvents(formattedDate);
  }, []);
  return (
    <>
      {spin ? (
        <div className="profilejobspinner align-items-start">
          <Spinner />
        </div>
      ) : (
        <>
          <div>
            <h1 className="fs-20 fs-md-16 roboto-medium">{lang?.saved_events}</h1>
          </div>

          {eventsData?.map((value, index) => {
            return (
              <>
                <div key={index} className="my-4">
                  <h2 className="fs-20 fs-md-16 roboto-regular">
                    {formatDate(value.date)}
                  </h2>
                </div>
                <div
                  className="d-flex"
                  style={{ overflowX: "auto", overflowY: "hidden" }}
                >
                  {value.data.map((event) => (
                    <Fragment key={event.id}>
                      <EventPreview
                        id={event.id}
                        image={event.cover}
                        title={event.title}
                        location={event.short_location}
                        time={event.start_timestamp}
                        available={event.availability}
                      />
                    </Fragment>
                  ))}
                </div>
              </>
            );
          })}
        </>
      )}
    </>
  );
};

export default SavedEvents;

function formatDate(dateString) {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
}
