import React, { useContext } from "react";
import { Col, Row } from "reactstrap";
import ImageComponent from "../common/ImageComponent";
import { Language, Sponsorpost } from "../../App";
import { CONTEST_LIST } from "../../constants/RoutesUrl";
import { useNavigate } from "react-router-dom";

// Individual contest item component
const ContestItem = ({ title, content }) => (
  <Col md={12} className="border border-1 border-inherit rounded p-2">
    <h5 style={{ color: "#de505e" }} className="roboto-bold">
      {title}
    </h5>
    <p
      className="roboto-medium mb-0"
      dangerouslySetInnerHTML={{ __html: content }}
    ></p>
  </Col>
);

// Contest details component
const ContestDetails = ({ details }) => (
  <Row className="justify-content-center gap-3 m-3">
    {details.map((item, index) => (
      <ContestItem key={index} {...item} />
    ))}
  </Row>
);

const ContestMain = () => {
  const { lang } = useContext(Language);
  const Navigate = useNavigate();
  const { postImage } = useContext(Sponsorpost);
  const contestDetails = [
    {
      title: `${lang?.about_brilliannt_minds}`,
      content: `${lang?.about_brilliannt_mind_para}`,
    },
    {
      title: `${lang?.calender}`,
      content: `${lang?.calender_para}`,
    },
    {
      title: `${lang?.presentation_clicical_case}`,
      content: `${lang?.presention_clicical_para}`,
    },
    {
      title: `${lang?.prizes_per_category}`,
      content: `${lang?.prizes_per_category_para}`,
    },
    {
      title: `${lang?.requirment}`,
      content: `${lang?.requirment_para}`,
    },
    {
      title: `${lang?.jury}`,
      content: `${lang?.jury_para}`,
    },
  ];

  return (
    <Col lg={6} className="py-3">
      <div className="br-16 bg-white py-3">
        <Row>
          <Col className="text-center">
            <h5 className="mb-0 roboto-bold">
              {lang?.briliant_mind_competion}
            </h5>
          </Col>
        </Row>
        <ImageComponent
          src={postImage}
          compClassName="img-fluid rounded"
          loader_height="405px"
        />
        <ContestDetails details={contestDetails} />
        <Col md={12} className="text-center">
          <button
            className="btn-blue border-0 w-75 py-3"
            style={{ backgroundColor: "#de505e" }}
            onClick={() => {
              Navigate(CONTEST_LIST);
            }}
          >
            {lang?.participate}
          </button>
        </Col>
      </div>
    </Col>
  );
};

export default ContestMain;
