import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import login_top_img from "../../assets/images/auth/login_top_img.svg";
import login_bottom_img from "../../assets/images/auth/login_bottom_img.svg";
import { LOGIN } from "../../constants/RoutesUrl";
import axios from "axios";
import { toast } from "react-toastify";
import { ChangePasswordApi } from "../../constants/API";
import show_eye from "../../assets/images/auth/show_eye.svg";
import hide_eye from "../../assets/images/auth/hide_eye.svg";
import lock from "../../assets/images/auth/lock.svg";
import { Language } from "../../App";

const ChangePassword = () => {
  const { lang } = useContext(Language);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (password.length < 6 || password.length > 30 || /\s/.test(password)) {
      toast.error(lang?.password_should_least_6char_long);
      setIsLoading(false);
      return;
    }

    try {
      const { data } = await axios.post(ChangePasswordApi, {
        email: sessionStorage.getItem("ForgotPasswordEmail"),
        password,
      });

      data.status ? navigate(LOGIN) : toast.error(data.action);
    } catch {
      toast.error(lang?.an_error_ocured_please_try_again);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className="d-flex justify-content-center align-items-center py-2 min-vh-100 login-bg">
      <div className="container">
        <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9 mx-auto">
          <div className="position-relative">
            <img
              src={login_top_img}
              className="position-absolute"
              alt="loginTop"
            />
            <img
              src={login_bottom_img}
              className="position-absolute"
              alt="loginBottom"
            />
            <div className="card position-relative border-0">
              <div className="card-body px-4 py-4">
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <h5 className="text-normal-black">{lang?.new_password}</h5>
                  </div>

                  <div className="mb-3 position-relative">
                    <img
                      src={lock}
                      className="position-absolute"
                      alt="login-email"
                    />
                    <input
                      onChange={(e) => setPassword(e.target.value)}
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      style={{ paddingLeft: "40px" }}
                      placeholder="******"
                    />
                    <img
                      src={showPassword ? hide_eye : show_eye}
                      onClick={() => setShowPassword(!showPassword)}
                      className="position-absolute cursor"
                      style={{
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                      alt="toggle-password-visibility"
                    />
                  </div>

                  <div className="mb-3">
                    <button
                      type="submit"
                      className="btn btn-login text-white fw-bold w-100"
                      disabled={!password}
                    >
                      {isLoading ? <div className="loader"></div> : lang?.reset}
                    </button>
                  </div>
                  <p className="fs-16 roboto-medium text-gray">
                    {lang?.already_account}{" "}
                    <Link to={LOGIN} className="fs-16 roboto-bold text-blue">
                      {lang?.login}
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ChangePassword;
