import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import login_top_img from "../../assets/images/auth/login_top_img.svg";
import login_bottom_img from "../../assets/images/auth/login_bottom_img.svg";
import email_icon from "../../assets/images/auth/email_A.svg";
import { CHANGE_PASSWORD } from "../../constants/RoutesUrl";
import { ForgotPasswordApi, OtpVerificationApi } from "../../constants/API";
import axios from "axios";
import { toast } from "react-toastify";
import ResendCodeModal from "../../components/auth/ResendCodeModal";
import { Language } from "../../App";

const OtpVerification = () => {
  const { lang } = useContext(Language);
  const email = sessionStorage.getItem("ForgotPasswordEmail");

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const [modalStatus, setModalStatus] = useState(false);
  const [timer, setTimer] = useState(59);
  const [isCounting, setIsCounting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // OTP Validation
    if (!/^\d{6}$/.test(otpCode)) {
      toast.error(lang?.please_enter_otp);
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(OtpVerificationApi, {
        email,
        code: otpCode,
      });
      if (response.data.status) {
        navigate(CHANGE_PASSWORD);
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      console.error("Error during OTP verification", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResend = async () => {
    setModalStatus(false);
    setIsLoading(true);

    try {
      const response = await axios.post(ForgotPasswordApi, { email });
      if (response.data.status) {
        setIsCounting(true);
        setTimer(59);
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      console.error("Error during OTP resend", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let interval;
    if (isCounting) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1;
          } else {
            setIsCounting(false);
            clearInterval(interval);
            return 0;
          }
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isCounting]);

  return (
    <main className="d-flex justify-content-center align-items-center py-2 min-vh-100 login-bg">
      <ResendCodeModal
        isOpen={modalStatus}
        onCancel={() => setModalStatus(false)}
        onResend={handleResend}
        email={email}
      />
      <div className="container">
        <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9 mx-auto">
          <div className="position-relative">
            <img
              src={login_top_img}
              className="position-absolute"
              alt="loginTop"
            />
            <img
              src={login_bottom_img}
              className="position-absolute"
              alt="loginBottom"
            />
            <div className="card position-relative border-0">
              <div className="card-body px-4 py-4">
                <form onSubmit={handleSubmit}>
                  <div className="mb-lg-4 mb-md-3 mb-sm-3 mb-2">
                    <h5 className="text-normal-black">
                      {lang?.email_verification}
                    </h5>
                    <p className="text-normal-black">
                      {lang?.email_verification_desc}
                    </p>
                  </div>
                  <div className="mb-3">
                    <div className="position-relative">
                      <img
                        src={email_icon}
                        className="position-absolute"
                        alt="otp-input"
                      />
                      <input
                        type="tel"
                        value={otpCode}
                        onChange={(e) => setOtpCode(e.target.value)}
                        className="form-control"
                        style={{ paddingLeft: "40px" }}
                        placeholder="xxxxxx"
                        maxLength="6"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <button
                      type="submit"
                      className="btn btn-login text-white fw-bold w-100"
                      disabled={otpCode.length !== 6 || isLoading}
                    >
                      {isLoading ? (
                        <div className="loader"></div>
                      ) : (
                        lang?.verify
                      )}
                    </button>
                  </div>
                  <p className="fs-16 roboto-medium text-gray">
                    {lang?.didn_received_code}{" "}
                    <span
                      className={`fs-16 roboto-bold text-blue ${
                        !isCounting ? "cursor" : ""
                      }`}
                      onClick={() => !isCounting && setModalStatus(true)}
                    >
                      {!isCounting
                        ? lang?.resend_it
                        : `00:${String(timer).padStart(2, "0")}`}
                    </span>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default OtpVerification;
