let UserProfile = (function() {
    const user = JSON.parse(sessionStorage.getItem("user"));
    let getId = function() {
      return user.id;
    };
    let getName = function() {
      return user.name;
    };
    let getImage = function() {
      return user.image;
    };
    let getUserName = function() {
      return user.user_name;
    };
    let getEmail = function() {
      return user.email;
    };
    let getCategory = function() {
      return user.category;
    };
    let getSubcategory = function() {
      return user.subcategory;
    };
    let getLocation = function() {
      return user.location;
    };
    let getBio = function() {
      return user.bio;
    };
    let getLink = function() {
      return user.link;
    };
  
    return {
      getId: getId,
      getName: getName,
      getImage: getImage,
      getUserName: getUserName,
      getEmail: getEmail,
      getCategory: getCategory,
      getSubcategory: getSubcategory,
      getLocation: getLocation,
      getBio: getBio,
      getLink: getLink,
    }
  
  })();
  
  export default UserProfile;