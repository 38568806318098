import React from "react";
const Input = ({
  label,
  errors,
  name,
  touched,
  className,
  inputClassName,
  type,
  ...rest
}) => {
  return (
    <div className={`course-feilds${className || "title-size"}`}>
      {label && <label>{label}</label>}
      <br />
      <input type={type} {...rest} className={inputClassName || ""} />
      {errors && <div className="error">{errors}</div>}
    </div>
  );
};
export default Input;
