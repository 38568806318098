import React, { useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import ImageComponent from "../common/ImageComponent";
import { Language, Sponsorpost } from "../../App";
import { useNavigate } from "react-router-dom";
import NavBar from "../NavBar";
import { CONTEST_LIST } from "../../constants/RoutesUrl";

// Individual contest item component
const ContestItem = ({ title, content }) => {
  const { postImage } = useContext(Sponsorpost);
  
  return (
    <Col md={12} className="border border-1 border-inherit rounded p-2">
      <h5 style={{ color: "#275c9b"
        // postImage?.color_code 
        }} className="roboto-bold">
        {title}
      </h5>
      <p
        className="roboto-medium mb-0"
        dangerouslySetInnerHTML={{ __html: content }}
      ></p>
    </Col>
  );
};

// Contest details component
const ContestDetails = ({ details }) => (
  <Row className="justify-content-center gap-3 m-3">
    {details.map((item, index) => (
      <ContestItem key={index} {...item} />
    ))}
  </Row>
);

const NewContest = () => {
  const { lang } = useContext(Language);
  const Navigate = useNavigate();
  const { postImage } = useContext(Sponsorpost);
  const _content = `El contenido del caso clínico debe ser: <br/> <br/>
    - Oftalmológico <br/> 
    - Anónimo <br/> 
    - Original <br/> 
    - Formato PDF (máximo 3 páginas) <br/> 
    - Inédito <br/> 
    - Contenido mínimo: título, descripción del caso, exploración y pruebas complementarias, resultados y conclusiones. <br/><br/> 
    Las bases completas del concurso están disponibles en la web de Brill Pharma 
    <a href="https://www.brillpharma.com" target="_blank" style="color: black !important;"><strong>(www.brillpharma.com)</strong></a>`;






  const contestDetails = [
    {
      title: "SOBRE BRILLIANT MINDS",
      content:
        "Se trata de un <strong>concurso de casos clínicos individual</strong> con el objetivo de aprender y compartir conocimientos, así como fomentar la publicación y presentación de trabajos en las categorías de Glaucoma, Retina, Superficie y Oculoplastia.",
    },
    {
      title: "CALENDARIO",
      content:
        "- Fecha límite para participar: <strong>10 de diciembre de 2024</strong> a las 23:59 h. <br/> <br/>- Comunicación de los 3 finalistas de cada categoría: <strong>1 de febrero de 2025.</strong> <br/><br/> - Presentación de los casos clínicos finalistas: <strong>21 de febrero a las 11h.<strong>",
    },
    {
      title: "PRESENTACIÓN DE LOS CASOS CLÍNICOS",
      content:
        "Presentación presencial de los casos clínicos finalistas en la sede de Brill Pharma, S.L. (C/ Munner, 8 de Barcelona). Retransmitida por streaming. <br/><br/> El Jurado decidirá la clasificación de los ganadores in situ.",
    },
    {
      title: "3 PREMIOS* POR CATEGORÍA",
      content:
        "<strong>- 1er PREMIO</strong> 750€  <br/> <strong>- 2o PREMIO</strong> 500€ <br/><strong> - 3er PREMIO</strong> 250€ <br/> *En concepto de formación",
    },
    {
      title: "REQUISITOS",
      content:_content
       
      // "El contenido del caso clínico debe ser: <br/> - Oftalmológico <br/> - Anónimo <br/> - Original <br/> - Formato PDF (máximo 3 páginas) <br/> - Inédito <br/> - Contenido mínimo: título, descripción del caso, exploración y pruebas complementarias, resultados y conclusiones. <br/> <br/> Las bases completas del concurso están disponibles en la web de Brill Pharma <strong>(www.brillpharma.com).</strong>",
    },
    {
      title: "JURADO",
      content:
        "<strong>CATEGORÍA GLAUCOMA </strong><br/> - <strong>Dr. Juan Carlos Pinto</strong> (Complejo Hospitalario de Navarra) <br/> - <strong>Dra. María Rojo</strong> (Hospital Virgen de la Luz) <br/> <br/> <strong>CATEGORÍA RETINA </strong><br/> - <strong>Dra. Elena Rodríguez Neila </strong>(Hospital Juan Ramón Jiménez) <br/> - <strong>Dra. María Gómez</strong> (Hospital de Son Espases) <br/> <br/> <strong>CATEGORÍA SUPERFICIE </strong><br/> - <strong>Dra. María Teresa Rodríguez </strong>(Complejo Hospitalario Universitario de Santiago) <br/> - <strong>Dra. Itziar Martínez-Soroa </strong>(Hospital Universitario Donostia) <br/> - <strong>Dra. Núria Ibáñez </strong>(Institut Català de Retina) <br/> - <strong>Dr. Marco Sales</strong> (IMO Grupo Miranza) <br/> <br/> <strong>CATEGORÍA OCULOPLASTIA </strong><br/> <strong>- Dra. Núria Ibáñez</strong> (Institut Català de Retina) <br/> <strong>- Dr. Marco Sales</strong> (IMO Grupo Miranza)",
    },
    
  ];

  return (
    <>
      <Container fluid className="vh-100 overflowY-auto scroll-hide bg-grey">
        <Row>
          <NavBar />
        </Row>
        <Row className="justify-content-center space-top">
          <Col lg={6} className="py-3">
            <div className="br-16 bg-white py-3">
              <Row>
                <Col className="text-center mb-2">
                  <h5 className="mb-0 roboto-bold">CONCURSO BRILLIANT MINDS</h5>
                </Col>
              </Row>
              <ImageComponent
                src={postImage?.image}
                compClassName="img-fluid rounded"
                loader_height="405px"
              />
              <ContestDetails details={contestDetails} />
              <Col md={12} className="text-center">
                <button
                  className="btn-blue border-0 w-75 py-3"
                  style={{ backgroundColor:"#275c9b"
                    //  postImage?.color_code

                   }}
                  onClick={() => {
                    Navigate(CONTEST_LIST);
                  }}
                >
                  {lang?.participate}
                </button>
              </Col>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NewContest;
