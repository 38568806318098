import React from "react";
import { Carousel } from "react-responsive-carousel";
import { BaseURL } from "../../constants/API";
import ImageComponent from "../common/ImageComponent";

const BannerCarousel = ({ banner }) => {
  return (
    <Carousel
      autoPlay={true}
      infiniteLoop={true}
      interval={2000}
      showIndicators={false}
      showThumbs={false}
      showStatus={false}
    >
      {banner &&
        banner?.map((value, index) => (
          <div className="bg-recommended-loader br-10">
            <ImageComponent
              key={index}
              src={BaseURL + value.media}
              compClassName="img-fluid br-10"
              img_height="auto"
              img_width="100%"
              loader_height="317px"
              loader_width="100%"
            />
          </div>
        ))}
    </Carousel>
  );
};

export default BannerCarousel;
