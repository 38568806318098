import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { BaseURL, ProfileApi } from "../../constants/API";
import { PROFILE } from "../../constants/RoutesUrl";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import ImageComponent from "../common/ImageComponent";
import { Language } from "../../App";
import { CountContext } from "../../constants/ProtectedRoute";

const ProfileInfoCard = () => {
  const [profileInfo, setProfileInfo] = useState({});
  const { lang } = useContext(Language);
  const { count } = useContext(CountContext);

  const localData = JSON.parse(localStorage.getItem("SignupData"));
  useEffect(() => {
    axios
      .post(ProfileApi + "1", {
        user_id: localData.data.id,
        login_id: localData.data.id,
        type: "post",
      })
      .then((res) => {
        if (res.data.status) {
          sessionStorage.setItem("profileData", JSON.stringify(res.data));
          setProfileInfo(res.data.user);
        } else {
          console.error(res.data.data.action);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const profile = (oriId, id, type) => {
    const url = `${PROFILE}/${oriId}?id=${id}&type=${type}`;
    window.location.href = url;
  };

  return (
    <div
      className="d-xl-block d-none mt-3"
      style={{
        maxWidth: "225px",
        width: "100%",
        position: "fixed",
      }}
    >
      <div
        style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
        className="bg-white position-relative rounded"
      >
        <div
          style={{
            background: "rgba(0,197,222,0.09)",
            minHeight: "6rem",
            borderRadius: "8px 8px 0px 0px",
          }}
        ></div>

        <div
          className="bg-white position-absolute top-0 mt-5 dropdown-avatar-shadow rounded"
          style={{
            padding: "2px",
            marginLeft: "5rem",
            maxHeight: "70px",
            maxWidth: "70px",
          }}
        >
          <Link to={PROFILE + "/" + localData.data.id}>
            <ImageComponent
              src={profileInfo?.image ? BaseURL + profileInfo.image : Avatar}
              loader_height="70px"
              loader_width="70px"
              compClassName="img-fluid rounded"
            />
          </Link>
        </div>

        <div className="text-center" style={{ marginTop: "2.2rem" }}>
          <Link
            to={PROFILE + "/" + localData.data.id}
            className="fs-16 roboto-bold hover-blue"
          >
            {profileInfo?.name}
          </Link>
          <Link to={PROFILE + "/" + localData.data.id}>
            <p className="fs-12 roboto-regular hover-blue">
              {profileInfo?.user_name}
            </p>
          </Link>
        </div>
        <hr style={{ opacity: "20%" }} />

        <div
          onClick={() => profile(profileInfo.id, 1, "post")}
          className="d-flex px-3 justify-content-between py-2 cursor profile-bg align-self-center my-2 hover-blue"
        >
          <div>
            <h3 className="fs-12 roboto-bold mb-0" style={{ opacity: "75%" }}>
              {lang?.posts}
            </h3>
          </div>
          <div>
            <h3 className="fs-12 roboto-bold mb-0" style={{ color: "#0089FF" }}>
              {count?.social}
            </h3>
          </div>
        </div>

        <div
          onClick={() => profile(profileInfo.id, 2, "webinar")}
          className="d-flex px-3 justify-content-between py-2 cursor profile-bg align-self-center my-2 hover-blue"
        >
          <div>
            <h3 className="fs-12 roboto-bold mb-0" style={{ opacity: "75%" }}>
              {lang?.webinars}
            </h3>
          </div>
          <div>
            <h3 className="fs-12 roboto-bold mb-0" style={{ color: "#0089FF" }}>
              {count?.webinar}
            </h3>
          </div>
        </div>

        <div
          onClick={() => profile(profileInfo.id, 3, "clinical")}
          className="d-flex px-3 justify-content-between py-2 cursor profile-bg align-self-center my-2 hover-blue"
        >
          <div>
            <h3 className="fs-12 roboto-bold mb-0" style={{ opacity: "75%" }}>
              {lang?.clinical_studies}
            </h3>
          </div>
          <div>
            <h3 className="fs-12 roboto-bold mb-0" style={{ color: "#0089FF" }}>
              {count?.clinical}
            </h3>
          </div>
        </div>
        <div
          onClick={() => profile(profileInfo.id, 6, "poll")}
          className="d-flex px-3 justify-content-between py-2 cursor profile-bg align-self-center my-2 hover-blue"
        >
          <div>
            <h3 className="fs-12 roboto-bold mb-0" style={{ opacity: "75%" }}>
              {lang?.poll}
            </h3>
          </div>
          <div>
            <h3 className="fs-12 roboto-bold mb-0" style={{ color: "#0089FF" }}>
              3
            </h3>
          </div>
        </div>
        <div
          onClick={() => profile(profileInfo.id, 4, "course")}
          className="d-flex px-3 justify-content-between py-2 cursor profile-bg align-self-center my-2 hover-blue"
        >
          <div>
            <h3 className="fs-12 roboto-bold mb-0" style={{ opacity: "75%" }}>
              {lang?.courses}
            </h3>
          </div>
          <div>
            <h3 className="fs-12 roboto-bold mb-0" style={{ color: "#0089FF" }}>
              {count?.course}
            </h3>
          </div>
        </div>
        <div
          onClick={() => profile(profileInfo.id, 5, "event")}
          className="d-flex px-3 justify-content-between py-2 cursor profile-bg align-self-center my-2 hover-blue"
        >
          <div>
            <h3 className="fs-12 roboto-bold mb-0" style={{ opacity: "75%" }}>
              {lang?.events}
            </h3>
          </div>
          <div>
            <h3 className="fs-12 roboto-bold mb-0" style={{ color: "#0089FF" }}>
              {count?.event}
            </h3>
          </div>
        </div>

        <hr style={{ opacity: "20%" }} className="mt-3 mb-2" />

        <div className="text-center pb-3 cursor">
          <Link
            to={PROFILE + "/" + profileInfo.id}
            className="fs-14 roboto-bold hover-blue"
          >
            {lang?.see_complete_profile}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfoCard;
