import React, { useContext, useState } from "react";
import axios from "axios";
import { Col, Row, Spinner } from "reactstrap";
import { BaseURL, FollowApi, GlobalSearchApi } from "../../constants/API";
import Search from "../../assets/images/icon/search.png";
import { Link } from "react-router-dom";
import { PROFILE } from "../../constants/RoutesUrl";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import VideoWithAspectRatio from "../common/VideowithAspectRatio.tsx";
import ImageWithAspectRatio from "../common/ImagewithAspectRatio.tsx";
import { Carousel } from "react-responsive-carousel";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { UnixToRelative } from "../notification/Notification";
import CoursePreview from "../courses/CoursePreview";
import EventPreview from "../events/EventPreview";
import JobsPreview from "../jobs/JobsPreview";
import ImageComponent from "../common/ImageComponent.jsx";
import { ReadMore } from "../../pages/Feed.jsx";
import { Language } from "../../App.js";
import { toast } from "react-toastify";

const SearchComponent = () => {
  // localStorage
  const userData = localStorage.getItem("SignupData");
  const user = userData ? JSON.parse(userData) : null;
  const user_id = user?.data?.id;

  // States
  const [dataLoad, setDataLoad] = useState(false);
  const [activeBtn, setActiveBtn] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [searchType, setSearchType] = useState("user");
  const [fetchData, setFetchData] = useState([]);
  const [btnLoad, setBtnLoad] = useState(false);
  const { lang } = useContext(Language);

  // Functions
  let timeout;
  const debounceSearch = (keyword, type) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      handleSearch(keyword, type);
    }, 2000);
  };

  const handleSearch = (keyword, type) => {
    if (keyword === "") {
      setFetchData([]);
      return;
    }
    setDataLoad(true);
    const data = { user_id, keyword, type: type || searchType };
    axios
      .post(GlobalSearchApi, data)
      .then((res) => {
        if (res.data.status) {
          setFetchData(res.data.data.data);
        } else {
          toast.error(res.data.error);
        }
        setDataLoad(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleFollow = (id) => {
    setBtnLoad(true);
    axios
      .get(FollowApi + id + "/" + user_id)
      .then((res) => {
        if (res.data.status) {
          const updatedProfiles = fetchData.map((profile) => {
            if (profile.id === id) {
              return { ...profile, follow: !profile.follow };
            }
            return profile;
          });
          setFetchData(updatedProfiles);
        } else {
          toast.error(res.data.error);
        }
        setBtnLoad(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const btns = [
    { id: 1, type: "user", name: lang?.doctors },
    { id: 2, type: "posts", name: lang?.posts },
    { id: 3, type: "webinar", name: lang?.webinars },
    { id: 4, type: "clinical", name: lang?.clinicals },
    { id: 5, type: "courses", name: lang?.courses },
    { id: 6, type: "events", name: lang?.events },
    { id: 7, type: "jobs", name: lang?.jobs },
  ];

  return (
    <>
      <Row className="justify-content-center">
        <Col
          lg={6}
          className="vh-100 overflowY-auto scroll-hide pt-3"
          style={{ paddingBottom: "4rem" }}
        >
          <div className="bg-white br-16 p-3 border border-1 border-inherit">
            <div className="py-3">
              <div className="position-relative">
                <img
                  src={Search}
                  className="position-absolute start-0 ms-3 mt-11"
                  width={20}
                  height={20}
                  alt="picture"
                />
                <input
                  type="text"
                  className="form-control py-2 ps-5"
                  placeholder={lang?.search}
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                    debounceSearch(e.target.value);
                  }}
                />
              </div>
            </div>

            {/* Tabs menu */}
            <div className="d-flex gap-3 mb-3 overflowX-auto scroll-hide">
              {btns &&
                btns.map((value) => (
                  <div key={value.id}>
                    <button
                      type="button"
                      disabled={dataLoad}
                      onClick={() => {
                        setActiveBtn(value.id);
                        setSearchType(value.type);
                        if (searchInput !== "") {
                          handleSearch(searchInput, value.type);
                        }
                      }}
                      className={`rounded px-5 py-2 fw-bold manue-hover hover-blue ${
                        activeBtn === value.id
                          ? "bg-dark text-white border-0"
                          : "border border-1 border-inherit"
                      }`}
                    >
                      {value.name}
                    </button>
                  </div>
                ))}
            </div>
            {/* Tabs menu ended */}

            <div>
              {dataLoad ? (
                <div className="text-center pt-3">
                  <Spinner />
                </div>
              ) : searchType === "user" ? (
                <>
                  {fetchData.map((value) => (
                    <>
                      <div
                        className="d-flex p-2 justify-content-between manue-hover rounded"
                        key={value.id}
                      >
                        <Link
                          to={PROFILE + "/" + value.id}
                          className="d-flex gap-2 text-dark w-100"
                        >
                          <div
                            className="bg-white dropdown-avatar-shadow align-self-center rounded-3"
                            style={{
                              padding: "1.8px",
                              width: "55px",
                              height: "55px",
                            }}
                          >
                            <ImageComponent
                              src={
                                value?.image ? BaseURL + value.image : Avatar
                              }
                              compClassName="img-fluid rounded-3"
                              loader_height="4.5rem"
                              loader_width="4.5rem"
                            />
                          </div>
                          <div className="align-self-center">
                            <div className="text-dark">
                              <p className="fs-14 roboto-bold m-0 search-name">
                                {value.name}
                              </p>
                            </div>
                            <p className="mb-0 fs-12 roboto-regular search-name">
                              @{value.user_name}
                            </p>
                          </div>
                        </Link>
                        <div className="align-self-center">
                          <button
                            className="btn btn-white border border-1 px-3 py-1"
                            disabled={btnLoad}
                            onClick={() => {
                              handleFollow(value.id);
                            }}
                          >
                            <span className="roboto-medium">
                              {value.follow ? lang?.following : lang?.follow}
                            </span>
                          </button>
                        </div>
                      </div>
                      <hr className="m-0" style={{ opacity: "6%" }} />
                    </>
                  ))}
                </>
              ) : searchType === "posts" ? (
                <div className="d-flex justify-content-center flex-wrap">
                  {fetchData.map((value) => (
                    <PostCard value={value} />
                  ))}
                </div>
              ) : searchType === "webinar" ? (
                <div className="d-flex justify-content-center flex-wrap">
                  {fetchData.map((value) => (
                    <WebinarCard value={value} />
                  ))}
                </div>
              ) : searchType === "clinical" ? (
                <div className="d-flex justify-content-center flex-wrap">
                  {fetchData.map((value) => (
                    <ClinicalCard value={value} />
                  ))}
                </div>
              ) : searchType === "courses" ? (
                <div className="gap-2 d-flex w-100 flex-wrap">
                  {fetchData.map((course) => (
                    <div>
                      <CoursePreview
                        certificates={course.certificates}
                        owner_username={course.owner_username}
                        owner_image={course.owner_image}
                        thumbnail={course.thumbnail}
                        image={course.cover}
                        price={course.price}
                        name={course.title}
                        course_id={course.id}
                      />
                    </div>
                  ))}
                </div>
              ) : searchType === "events" ? (
                <div className="d-flex w-100 flex-wrap gap-2 justify-content-center">
                  {fetchData.map((value) => (
                    <EventPreview
                      id={value.id}
                      image={value.cover}
                      title={value.title}
                      location={value.short_location}
                      time={value.start_timestamp}
                      available={value.availability}
                    />
                  ))}
                </div>
              ) : searchType === "jobs" ? (
                <>
                  {fetchData.map((value) => (
                    <JobsPreview
                      id={value.id}
                      job={value?.title}
                      hospital_name={value?.hospital_name}
                      location={value?.short_location}
                      date={value?.time}
                      description={value?.description}
                      publisher_name={value?.user?.name}
                      publisher_username={value?.user?.user_name}
                      publisher_img={value?.user?.image}
                    />
                  ))}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SearchComponent;

const PostCard = ({ value }) => {
  const { lang } = useContext(Language);
  const indicatorStyles = {
    background: "#fff",
    width: 20,
    height: 4,
    display: "inline-block",
    margin: "0 4px",
    borderRadius: "1px",
  };
  return (
    <>
      <div
        className="bg-white br-6 my-3 py-2 border border-1 border-inherit w-100"
        style={{ maxWidth: "579px" }}
      >
        {value?.repost_user?.image && value?.repost_user?.name && (
          <>
            <Row>
              <Col className="d-flex">
                <div
                  className="bg-white rounded-2 dropdown-avatar-shadow ms-3"
                  style={{
                    maxWidth: "2.5rem",
                    maxHeight: "2.5rem",
                    padding: "0.8px",
                  }}
                >
                  <ImageComponent
                    src={BaseURL + value.repost_user.image}
                    compClassName="img-fluid rounded-2"
                    loader_height="2.5rem"
                    loader_width="2.5rem"
                  />
                </div>
                <div className="align-self-center">
                  <h6 className="mx-3 mb-0 hover-blue">
                    {value.repost_user.name} {lang?.reposted_this}
                  </h6>
                </div>
              </Col>
            </Row>
            <hr style={{ opacity: "0.1px" }} />
          </>
        )}
        <Row className="justify-content-between px-3 position-relative">
          <Col md={10} className="d-flex gap-3">
            <div
              className="bg-white dropdown-avatar-shadow rounded-3 align-self-center"
              style={{
                padding: "2px",
                height: "50px",
                width: "50px",
              }}
            >
              <Link to={PROFILE + "/" + value.user.id}>
                <ImageComponent
                  src={value.user.image ? BaseURL + value.user.image : Avatar}
                  img_height="100%"
                  img_width="100%"
                  loader_height="100%"
                  loader_width="100%"
                  compClassName="rounded-3"
                />
              </Link>
            </div>
            <div className="align-self-center">
              <div>
                <Link to={PROFILE + "/" + value.user.id}>
                  <h1 className="fs-16 roboto-bold mb-0 search-post-name hover-blue">
                    {value.user.name}
                  </h1>
                </Link>
                <p
                  className="mb-0 fs-12 roboto-regular"
                  style={{
                    color: "#434343",
                    opacity: "80%",
                  }}
                >
                  <UnixToRelative unixTimestamp={value?.time} />
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="pt-3 pb-2">
          <Col>
            {value.type === "video" ? (
              <>
                {value.media &&
                  value.media.map((value) => (
                    <VideoWithAspectRatio
                      src={BaseURL + value}
                      aspectRatio={value.sizes}
                    />
                  ))}
              </>
            ) : value.media && value.media.length === 1 ? (
              value.media.map((value) => (
                <ImageWithAspectRatio
                  aspectRatio={value.sizes}
                  src={BaseURL + value}
                  alt="picture"
                />
              ))
            ) : (
              <Carousel
                renderArrowNext={(onClickHandler, hasNext) =>
                  hasNext && (
                    <IoIosArrowDroprightCircle
                      className="position-absolute m-auto top-0 bottom-0 end-0 me-3"
                      size={30}
                      color="white"
                      onClick={onClickHandler}
                    />
                  )
                }
                renderArrowPrev={(onClickHandler, hasPrev) =>
                  hasPrev && (
                    <IoIosArrowDropleftCircle
                      className="position-absolute m-auto start-0 ms-3 top-0 bottom-0"
                      size={30}
                      style={{ zIndex: "1" }}
                      color="white"
                      onClick={onClickHandler}
                    />
                  )
                }
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                  return (
                    <>
                      {isSelected ? (
                        <li
                          style={{
                            ...indicatorStyles,
                            background: "#00c5de",
                          }}
                          aria-label={`Selected: ${label} ${index + 1}`}
                          title={`Selected: ${label} ${index + 1}`}
                        />
                      ) : (
                        <li
                          style={indicatorStyles}
                          onClick={onClickHandler}
                          onKeyDown={onClickHandler}
                          value={index}
                          key={index}
                          role="button"
                          tabIndex={0}
                          title={`${label} ${index + 1}`}
                          aria-label={`${label} ${index + 1}`}
                        />
                      )}
                    </>
                  );
                }}
                statusFormatter={(current, total) => {
                  return (
                    <>
                      <div
                        className="rounded-pill me-2 mt-3 px-3 py-1"
                        style={{
                          backgroundColor: "rgba(0,0,0,0.2)",
                        }}
                      >
                        <p className="mb-0 fs-12 roboto-bold">{`${current}/${total}`}</p>
                      </div>
                    </>
                  );
                }}
                autoPlay={false}
                infiniteLoop={false}
                showIndicators={true}
                showThumbs={false}
                showStatus={true}
              >
                {value.media &&
                  value.media.map((data) => (
                    <ImageWithAspectRatio
                      aspectRatio={value.sizes}
                      src={BaseURL + data}
                      alt="picture"
                    />
                  ))}
              </Carousel>
            )}
          </Col>
        </Row>
        {value?.caption && (
          <>
            <Row className="p-2">
              <Col>
                <ReadMore text={value.caption} />
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

const WebinarCard = ({ value }) => {
  const { lang } = useContext(Language);
  return (
    <>
      <div
        key={value.id}
        className="bg-white br-6 my-3 py-2 border border-1 border-inherit w-100"
        style={{ maxWidth: "579px" }}
      >
        <Row className="justify-content-between px-3 position-relative">
          <Col md={10} className="d-flex gap-3">
            <div
              className="bg-white p-1 dropdown-avatar-shadow rounded-2 align-self-center"
              style={{
                maxHeight: "4rem",
                maxWidth: "4rem",
              }}
            >
              <Link to={PROFILE + "/" + value.user.id}>
                <ImageComponent
                  src={value.user.image ? BaseURL + value.user.image : Avatar}
                  compClassName="rounded-2 img-fluid"
                  loader_height="4rem"
                  loader_width="4rem"
                />
              </Link>
            </div>
            <div className="align-self-end ">
              <Link to={PROFILE + "/" + value.user.id}>
                <h1 className="fs-16 roboto-medium text-dark">
                  {value?.user?.name}
                </h1>
              </Link>
              <span className="fs-12 roboto-regular">
                <UnixToRelative unixTimestamp={value?.time} />
              </span>
            </div>
          </Col>
        </Row>
        <Row className="pt-3 pb-2">
          <Col>
            {value.media.map((value) => (
              <ImageWithAspectRatio
                aspectRatio={value.sizes}
                src={BaseURL + value}
                alt="picture"
              />
            ))}
          </Col>
        </Row>
        {value?.caption || value?.title ? (
          <>
            <Row className="px-3 py-2">
              <Col>
                <h4>
                  {value?.title && value.title.length > 35
                    ? value.title.slice(0, 35) + "..."
                    : value.title}
                </h4>
                <ReadMore text={value.caption} />
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
        <Row className="px-3 justify-content-between">
          <Col className="align-self-center">
            <p className="mb-0 text-warning">{lang?.scheduled_on}</p>
            <small className="mb-0">{value?.scheduled}</small>
          </Col>
        </Row>
      </div>
    </>
  );
};

const ClinicalCard = ({ value }) => {
  const { lang } = useContext(Language);
  const indicatorStyles = {
    background: "#fff",
    width: 20,
    height: 4,
    display: "inline-block",
    margin: "0 4px",
    borderRadius: "1px",
  };
  return (
    <>
      <div
        className="bg-white br-6 my-3 py-3 border border-1 border-inherit w-100"
        style={{ maxWidth: "579px" }}
      >
        {value.repost_user.image && value.repost_user.name && (
          <>
            <Row>
              <Col className="d-flex">
                <div
                  className="bg-white rounded-2 dropdown-avatar-shadow ms-3"
                  style={{
                    maxWidth: "2.5rem",
                    maxHeight: "2.5rem",
                    padding: "0.8px",
                  }}
                >
                  <ImageComponent
                    src={BaseURL + value.repost_user.image}
                    loader_height="2.5rem"
                    loader_width="2.5rem"
                    compClassName="rounded-2 img-fluid"
                  />
                </div>
                <div className="align-self-center">
                  <h6 className="mx-3 mb-0">
                    {value.repost_user.name} {lang?.reposted_this}
                  </h6>
                </div>
              </Col>
            </Row>
            <hr style={{ opacity: "0.1px" }} />
          </>
        )}
        <Row className="justify-content-between px-3 position-relative">
          <Col md={10} className="d-flex gap-3">
            <div
              className="bg-white dropdown-avatar-shadow rounded-2 align-self-center"
              style={{
                padding: "2px",
                height: "55px",
                width: "55px",
              }}
            >
              <Link to={PROFILE + "/" + value.user.id}>
                <ImageComponent
                  src={value.user.image ? BaseURL + value.user.image : Avatar}
                  img_height="100%"
                  img_width="100%"
                  loader_height="100%"
                  loader_width="100%"
                  compClassName="rounded-2"
                />
              </Link>
            </div>
            <div className="align-self-center">
              <div>
                <Link to={PROFILE + "/" + value.user.id}>
                  <h1 className="fs-16 roboto-bold text-dark mb-0">
                    {value.user.name}
                  </h1>
                </Link>
                <p
                  className="mb-0 fs-12 roboto-regular"
                  style={{
                    color: "#434343",
                    opacity: "80%",
                  }}
                >
                  <UnixToRelative unixTimestamp={value?.time} />
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="pt-3 pb-2">
          <Col>
            {value?.media && value?.media.length === 1 ? (
              value?.media.map((data) => (
                <ImageWithAspectRatio
                  aspectRatio={value?.sizes}
                  src={BaseURL + data}
                  alt="picture"
                />
              ))
            ) : (
              <Carousel
                statusFormatter={(current, total) => {
                  return (
                    <>
                      <div
                        className="rounded-pill me-2 mt-3 px-3 py-1"
                        style={{
                          backgroundColor: "rgba(0,0,0,0.2)",
                        }}
                      >
                        <p className="mb-0 fs-12 roboto-bold">{`${current}/${total}`}</p>
                      </div>
                    </>
                  );
                }}
                renderArrowNext={(onClickHandler, hasNext) =>
                  hasNext && (
                    <IoIosArrowDroprightCircle
                      className="position-absolute m-auto top-0 bottom-0 end-0 me-3"
                      size={30}
                      color="white"
                      onClick={onClickHandler}
                    />
                  )
                }
                renderArrowPrev={(onClickHandler, hasPrev) =>
                  hasPrev && (
                    <IoIosArrowDropleftCircle
                      className="position-absolute m-auto start-0 ms-3 top-0 bottom-0"
                      size={30}
                      style={{ zIndex: "1" }}
                      color="white"
                      onClick={onClickHandler}
                    />
                  )
                }
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                  if (isSelected) {
                    return (
                      <li
                        style={{
                          ...indicatorStyles,
                          background: "#00c5de",
                        }}
                        aria-label={`Selected: ${label} ${index + 1}`}
                        title={`Selected: ${label} ${index + 1}`}
                      />
                    );
                  }
                  return (
                    <li
                      style={indicatorStyles}
                      onClick={onClickHandler}
                      onKeyDown={onClickHandler}
                      value={index}
                      key={index}
                      role="button"
                      tabIndex={0}
                      title={`${label} ${index + 1}`}
                      aria-label={`${label} ${index + 1}`}
                    />
                  );
                }}
                autoPlay={false}
                infiniteLoop={false}
                showIndicators={true}
                showThumbs={false}
                showStatus={true}
              >
                {value?.media &&
                  value?.media.map((data) => (
                    <ImageWithAspectRatio
                      aspectRatio={value?.sizes}
                      src={BaseURL + data}
                      alt="picture"
                    />
                  ))}
              </Carousel>
            )}
          </Col>
        </Row>
        {value?.caption || value?.title ? (
          <>
            <Row className="p-2 justify-content-between">
              <Col className="align-self-center">
                <h4 className="mb-0">
                  {value?.title && value.title.length > 26
                    ? value.title.slice(0, 26) + "..."
                    : value.title}
                </h4>
              </Col>
              {value?.pdf && (
                <Col className="text-end">
                  <button
                    className="btn-blue border-0 text-white px-3 py-1"
                    onClick={() => {
                      window.open(BaseURL + value.pdf, "_blank");
                    }}
                  >
                    {lang?.view_Case_Study}
                  </button>
                </Col>
              )}
            </Row>
            <Row className="px-2">
              <Col>
                <ReadMore text={value.caption} />
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
