import React, { useEffect, useState, useCallback, useContext } from "react";
import NavBar from "../components/NavBar";
import { Col, Container, Row } from "reactstrap";
import dropIcon from "../../src/assets/images/icon/Union.svg";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { PhotoProvider, PhotoView } from "react-photo-view";
import upload from "../../src/assets/images/icon/upload-icon.svg";
import close from "../../src/assets/images/icon/close-icon.svg";
import axios from "axios";
import ApiUrl from "../../src/shared/ApiUrl";
import Cropper from "../../src/cropper/components/CroppersWizard.tsx";
import Input from "../../src/shared/Input";
import pdfIcon from "../../src/assets/images/icon/pdfFile.svg";
import youtubeIcon from "../../src/assets/images/icon/youtube-icon.svg";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Modal } from "reactstrap";
import LinearProgress from "@mui/material/LinearProgress";
import { Language } from "../App.js";
import { toast } from "react-toastify";
import VideoIcon from "../assets/images/video-icon.png";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const CourseContent = () => {
  const content_id = sessionStorage.getItem("courseMediaContentId");
  const user = JSON.parse(sessionStorage.getItem("SplashData"));
  const user_id = user.user.id;

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const maxNumber = 1;
  const inputRef = React.useRef();

  const [source, setSource] = React.useState(null);
  const [showUploadStatus, setShowUploadStatus] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [showMedia, setShowMedia] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [cropperOpen, setCropperOpen] = React.useState(false);
  const [coverImg, setCoverImg] = useState([]);
  const [fileUploadRefs, setFileUploadRefs] = React.useState({});
  const [numberOfImages, setNumberOfImages] = React.useState(
    coverImg.length < maxNumber ? coverImg.length : maxNumber
  );
  const [ar, setAR] = React.useState(undefined);
  const [minARatio, setMinAR] = React.useState(undefined);
  const [maxARatio, setMaxAR] = React.useState(undefined);
  const [resizable, setResizable] = React.useState(true);
  const [modalShow, setModalShow] = React.useState(false);
  const [hasSettings, setHasSettings] = React.useState(true);
  const [updateCoordinatesCheck, setUpdateCoordinatesCheck] =
    React.useState(true);
  const [height, setHeight] = React.useState(undefined);
  const [width, setWidth] = React.useState(undefined);
  const [top, setTop] = React.useState(undefined);
  const [left, setLeft] = React.useState(undefined);
  const [disableVideo, setDisableVideo] = useState(true);
  const [disableImg, setDisableImg] = useState(true);
  const [disablePdf, setDisablePdf] = useState(true);
  const [disableYoutube, setDisableYoutube] = useState(true);
  const [getMedia, setGetMedia] = useState([]);
  const [videourl, setVideoUrl] = useState(null);
  const [isTrueVal, setIsTrueVal] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [link, setLink] = useState(null);
  const [deleteContent, setDeleteContent] = useState();
  const [isValid, setIsValid] = useState(true);
  const { lang } = useContext(Language);

  function validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }
  const changeUrl = useCallback((event) => {
    const { value } = event.target;
    const isValid = !value || validURL(value);
    setYoutubeUrl(event.target.value);
    // setURL(value);
    setIsTrueVal(isValid);
  }, []);
  const handleClickOpen = (type) => {
    setModalShow(true);
    setDeleteContent(type);
  };
  const handleClose = () => {
    setModalShow(false);
    setCropperOpen(false);
  };
  const onImageRemove = (index) => {
    const updatedImages = [...coverImg];
    updatedImages.splice(index, 1);
    setCoverImg(updatedImages);
    setHasSettings(true);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContent = e.target.result;
        setSource(file);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setSource(null);
    }
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedPdf(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContent = e.target.result;
        setSource(file);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedPdf(null);
      setSource(null);
    }
  };
  const handleChoose = () => {
    inputRef.current.click();
  };
  const onVideoRemove = () => {
    setSource(null);
  };
  const fileRemove = () => {
    setSelectedPdf(null);
  };
  const uploadMedia = async (data) => {
    setShowUploadStatus(true);
    setLoading(true);
    let newData = {
      video_id: content_id,
      time: "",
      ...data,
    };
    try {
      await axios.post(ApiUrl + "course/v1/media/content/add", newData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      });
      setProgress(0);
    } catch (error) {
      setShowUploadStatus(true);
    } finally {
      setShowUploadStatus(false);
      setLoading(false);
    }
  };
  const getUploadMedia = async (id) => {
    const res = await axios.get(
      ApiUrl + `course/v1/media/content/${content_id}/${user_id}`
    );
    setGetMedia(res.data);
    if (res.data.status === true) {
      if (res.data.data.media) {
        setVideoUrl("https://medicalradar.es" + res.data.data.media);
        hideVideo();
      }
      if (res.data.data.image) {
        setImgUrl("https://medicalradar.es/" + res.data.data.image);
        hideImg();
      } else if (res.data.data.media) {
        setValue(1);
      }
      if (res.data.data.pdf) {
        setPdfUrl("https://medicalradar.es" + res.data.data.pdf);
        hidePdf();
      } else if (res.data.data.image) {
        setValue(2);
      }
      if (res.data.data.link) {
        setLink(res.data.data.link);
        hideYoutubeLink();
      } else if (res.data.data.pdf) {
        setValue(3);
      }
    }
  };
  const deleteUploadMedia = async () => {
    const res = await axios.get(
      ApiUrl + `course/v1/media/content/remove/${content_id}/${deleteContent}`
    );
    getUploadMedia();
    handleClose();
    setVideoUrl(null);
    setImgUrl(null);
    setPdfUrl(null);
    setLink(null);
    setDisableVideo(true);
    setDisableImg(true);
    setDisablePdf(true);
    setDisableYoutube(true);
  };
  const showUploadVideo = async () => {
    if (!source || source.length === 0) {
      toast.error(lang?.please_chose_file_upload);
      return;
    }
    await uploadMedia({ media: source });
    getUploadMedia();
    setShowMedia(true);
  };
  const showUploadImg = async () => {
    if (!coverImg || coverImg.length === 0) {
      toast.error(lang?.please_chose_file_upload);
      return;
    } else {
      await uploadMedia({ image_base64: coverImg[0] });
      getUploadMedia();
      setShowImage(true);
    }
  };
  const showUploadFIle = async () => {
    if (!selectedPdf || selectedPdf.length === 0) {
      toast.error(lang?.please_chose_file_upload);
      return;
    }
    await uploadMedia({ pdf: selectedPdf });
    getUploadMedia();
    setShowFile(true);
  };
  const youtubeLink = async () => {
    if (!youtubeUrl) {
      toast.error(lang?.youtube_url_empty);
    } else {
      await uploadMedia({ link: youtubeUrl });
      getUploadMedia();
      setShowFile(true);
    }
  };
  const hideVideo = () => {
    setDisableVideo(false);
  };
  const hideImg = () => {
    setDisableImg(false);
  };
  const hidePdf = () => {
    setDisablePdf(false);
  };
  const hideYoutubeLink = () => {
    setDisableYoutube(false);
  };
  const openYoutubeLink = () => {
    console.log(link, "link");
    window.open(link);
  };
  const openPdf = () => {
    window.open(pdfUrl);
  };
  useEffect(() => {
    getUploadMedia();
  }, []);
  return (
    <>
      <Container fluid className="bg-offwhite vh-100 overflowY-hidden">
        <Row>
          <NavBar />
        </Row>
        <Modal
          zIndex={9}
          centered
          isOpen={modalShow}
          toggle={() => setModalShow(!modalShow)}
        >
          <div className="p-3">
            <div className="heading-title">
              <h5>{lang?.are_you_sure}</h5>
              <p>{lang?.are_you_sure_delete_para}</p>
            </div>
            <div className="hrTag" />
            <div className="cancel-logoutBtn justify-content-end d-flex gap-3">
              <button
                className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
                onClick={handleClose}
              >
                {lang?.cancel}
              </button>
              <div className="vl" />
              <button
                className="btn-red border-0 rounded w-100 py-2 fs-md-14"
                onClick={deleteUploadMedia}
              >
                {lang?.delete}
              </button>
            </div>
          </div>
        </Modal>
        <Row className="bg-offwhite  ">
          <Col xl={12} style={{ padding: 20 }}>
            <div className="d-flex upload-main">
              <div className="f-direction ">
                <div className="d-flex f-wrap s-between paddingg">
                  <div className="chapter-content">
                    {
                      <div className="p-relative ">
                        <div className="chapter-content">
                          <div className="upload-content">
                            <img src={dropIcon} />
                          </div>
                        </div>
                        {videourl !== null && (
                          <div
                            className="bg-white position-absolute top-0 bottom-0 start-0 end-0 br-10 border"
                            style={{ marginTop: "10%" }}
                          >
                            <div className="position-relative h-100 w-100">
                              <img src={VideoIcon} className="img-fluid mt-3" />
                            </div>
                          </div>
                        )}
                        {videourl !== null && (
                          <img
                            className=" imgUrl-close c-pointer"
                            onClick={() => {
                              handleClickOpen("media");
                              setVideoUrl(null);
                              setSource(null);
                            }}
                            src={close}
                          />
                        )}
                      </div>
                    }
                    <p className="color">{lang?.video}</p>
                  </div>
                  <div className="chapter-content">
                    <div className="p-relative ">
                      <div className="chapter-content">
                        <div className="upload-content">
                          <img src={dropIcon} />
                        </div>
                      </div>
                      {imgUrl !== null && (
                        <PhotoProvider
                          speed={() => 800}
                          easing={(type) =>
                            type === 2
                              ? "cubic-bezier(0.36, 0, 0.66, -0.56)"
                              : "cubic-bezier(0.34, 1.56, 0.64, 1)"
                          }
                        >
                          <PhotoView src={imgUrl}>
                            <img
                              className="add-video object-fit c-pointer"
                              src={imgUrl}
                            />
                          </PhotoView>
                        </PhotoProvider>
                      )}
                      {imgUrl !== null && (
                        <img
                          onClick={() => {
                            handleClickOpen("image");
                            setImgUrl(null);
                            setCoverImg("");
                          }}
                          className="imgUrl-close c-pointer"
                          src={close}
                        />
                      )}
                    </div>
                    <p className="color">{lang?.image}</p>
                  </div>
                  <div className="chapter-content ">
                    <div className="p-relative ">
                      <div className="chapter-content">
                        <div className="upload-content">
                          <img src={dropIcon} />
                        </div>
                      </div>
                      {pdfUrl !== null && (
                        <div
                          className="bg-white c-pointer  position-absolute top-0 bottom-0 start-0 end-0 br-10 border"
                          style={{ marginTop: "10%" }}
                          onClick={openPdf}
                        >
                          <div className="position-relative h-100 w-100">
                            <img
                              src={pdfIcon}
                              className="position-absolute top-0"
                              style={{
                                marginTop: "3.8rem",
                                marginLeft: "4rem",
                              }}
                            />
                          </div>
                          <div className="pdfFile-upload"></div>
                        </div>
                      )}
                      {pdfUrl !== null && (
                        <img
                          onClick={() => {
                            handleClickOpen("pdf");
                            setSelectedPdf(null);
                            setPdfUrl(null);
                          }}
                          className="imgUrl-close c-pointer"
                          src={close}
                        />
                      )}
                    </div>
                    <p className="color">{lang?.pdf_file}</p>
                  </div>
                  <div className="chapter-content ">
                    <div className="p-relative ">
                      <div className="chapter-content">
                        <div className="upload-content">
                          <img src={dropIcon} />
                        </div>
                      </div>
                      {link !== null && (
                        <div
                          className="bg-white c-pointer  position-absolute top-0 bottom-0 start-0 end-0 br-10 border"
                          style={{ marginTop: "10%" }}
                          onClick={openYoutubeLink}
                        >
                          <div className="position-relative h-100 w-100">
                            <img
                              src={youtubeIcon}
                              className="position-absolute top-0"
                              style={{
                                marginTop: "4.3rem",
                                marginLeft: "3rem",
                              }}
                            />
                          </div>
                          <div className="pdfFile-upload"></div>
                        </div>
                      )}
                      {link !== null && (
                        <img
                          onClick={() => {
                            handleClickOpen("link");
                            setLink(null);
                            setYoutubeUrl(null);
                          }}
                          className="imgUrl-close c-pointer"
                          src={close}
                        />
                      )}
                    </div>
                    <p className="color">{lang?.youtube_url}</p>
                  </div>
                </div>
              </div>
              <div className="verticalLine" />
              <Col xl={7} style={{ padding: 20 }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  sx={{
                    ".MuiTabs-indicator": {
                      display: "none",
                      textTransform: "cap",
                      justifyContent: "space-between",
                    },
                  }}
                  disabled={loading}
                >
                  {disableVideo && (
                    <Tab
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        fontWeight: "600",
                      }}
                      disabled={loading}
                      value={0}
                      label="Video"
                      {...a11yProps(0)}
                    />
                  )}
                  {disableImg && (
                    <Tab
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        fontWeight: "600",
                      }}
                      value={1}
                      disabled={loading}
                      label="Image"
                      {...a11yProps(1)}
                    />
                  )}
                  {disablePdf && (
                    <Tab
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        fontWeight: "600",
                      }}
                      value={2}
                      label="Pdf File"
                      disabled={loading}
                      {...a11yProps(2)}
                    />
                  )}
                  {disableYoutube && (
                    <Tab
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        fontWeight: "600",
                      }}
                      value={3}
                      label="youtube link"
                      {...a11yProps(3)}
                      disabled={loading}
                    />
                  )}
                </Tabs>
                <CustomTabPanel value={value} index={0}>
                  {disableVideo && (
                    <>
                      <div className="VideoInput p-relative">
                        <input
                          ref={inputRef}
                          className="VideoInput_input"
                          type="file"
                          onChange={handleFileChange}
                          accept=".mov,.mp4"
                        />
                        {source && (
                          <img
                            className="uploadVideo-close c-pointer"
                            onClick={onVideoRemove}
                            src={close}
                            alt="Remove"
                          />
                        )}
                        {!source && (
                          <div
                            className="file-upload c-pointer"
                            onClick={handleChoose}
                          >
                            <img src={upload} />
                            <p>{lang?.choose_file_upload}</p>
                          </div>
                        )}
                        {source !== null && (
                          <img
                            className="VideoInput_video"
                            src={VideoIcon}
                          />
                        )}
                      </div>
                      <button
                        className="add-btn c-pointer"
                        onClick={showUploadVideo}
                      >
                        {loading ? (
                          <CircularProgress size={18} color="inherit" />
                        ) : (
                          <>{lang?.add}</>
                        )}
                      </button>
                    </>
                  )}
                  {showUploadStatus && (
                    <div className="upload-status">
                      <p className="f-weight650">{lang?.upload_status}</p>
                      <p>{lang?.upload_status_para}</p>
                      <div>
                        <Box sx={{ width: "100%" }}>
                          <LinearProgressWithLabel value={progress} />
                        </Box>
                      </div>
                      {progress === 100 && (
                        <p className="success-mess">
                          {lang?.post_upload_success}
                        </p>
                      )}
                    </div>
                  )}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  {disableImg && (
                    <>
                      <div className="p-relative">
                        {!coverImg.length && !cropperOpen && (
                          <div
                            className="file-upload c-pointer p-relative"
                            onClick={() =>
                              fileUploadRefs[numberOfImages - 1].current.click()
                            }
                          >
                            <img src={upload} alt={"Choose file upload"} />
                            <p> {lang?.choose_file_upload}</p>
                          </div>
                        )}
                        <Cropper
                          images={coverImg}
                          setImages={setCoverImg}
                          setImagesArr={setCoverImg}
                          value={coverImg}
                          max={maxNumber}
                          allowCrop={true}
                          ar={ar}
                          setAR={setAR}
                          setMaxAR={setMaxAR}
                          setMinAR={setMinAR}
                          resizable={resizable}
                          setResizable={setResizable}
                          handleClose={handleClose}
                          fileUploadRefs={fileUploadRefs}
                          setFileUploadRefs={setFileUploadRefs}
                          numberOfImages={numberOfImages}
                          setNumberOfImages={setNumberOfImages}
                          hasSettings={hasSettings}
                          setHasSettings={setHasSettings}
                          updateCoordinatesCheck={updateCoordinatesCheck}
                          setUpdateCoordinatesCheck={setUpdateCoordinatesCheck}
                          height={height}
                          setHeight={setHeight}
                          width={width}
                          setWidth={setWidth}
                          top={top}
                          setTop={setTop}
                          left={left}
                          setLeft={setLeft}
                          cropperOpen={cropperOpen}
                          setCropperOpen={setCropperOpen}
                        />
                        <div>
                          {Array(maxNumber)
                            .fill()
                            .map((_, index) => (
                              <>
                                {coverImg[index] ? (
                                  <>
                                    <PhotoProvider
                                      speed={() => 800}
                                      easing={(type) =>
                                        type === 2
                                          ? "cubic-bezier(0.36, 0, 0.66, -0.56)"
                                          : "cubic-bezier(0.34, 1.56, 0.64, 1)"
                                      }
                                    >
                                      <PhotoView src={coverImg[index]}>
                                        <div
                                          key={index}
                                          className="upload_imgg"
                                        >
                                          <img
                                            src={coverImg[index]}
                                            alt="..."
                                          />
                                        </div>
                                      </PhotoView>
                                    </PhotoProvider>
                                    <img
                                      className="uploadImg-close c-pointer"
                                      onClick={() => onImageRemove(index)}
                                      value={coverImg}
                                      onChange={(e) =>
                                        setCoverImg(e.target.value)
                                      }
                                      src={close}
                                      alt="Remove"
                                    />
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            ))}
                        </div>
                      </div>
                      <button
                        className="add-btn c-pointer"
                        onClick={showUploadImg}
                      >
                        {loading ? (
                          <CircularProgress size={18} color="inherit" />
                        ) : (
                          <>{lang?.add}</>
                        )}
                      </button>
                    </>
                  )}
                  {showUploadStatus && (
                    <div className="upload-status">
                      <p className="f-weight650">{lang?.upload_status}</p>
                      <p>{lang?.upload_status_para}</p>
                      <div>
                        <Box sx={{ width: "100%" }}>
                          <LinearProgressWithLabel value={progress} />
                        </Box>
                      </div>
                      {progress === 100 && (
                        <p className="success-mess">
                          {lang?.post_upload_success}
                        </p>
                      )}
                    </div>
                  )}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  {disablePdf && (
                    <>
                      <div className="d-flex">
                        <div className="VideoInput">
                          <input
                            ref={inputRef}
                            className="VideoInput_input"
                            type="file"
                            onChange={handleFileUpload}
                            accept="application/pdf, application/vnd.ms-excel"
                          />
                          <div
                            className="file-upload c-pointer"
                            onClick={handleChoose}
                          >
                            <img src={upload} alt="Upload" />
                            <p>{lang?.choose_file_upload}</p>
                          </div>
                        </div>
                        <div className="p-relative">
                          {selectedPdf && (
                            <div className="pdfIcon">
                              <img src={pdfIcon} />
                              <div className="pdfFile-upload">
                                <p>{selectedPdf.name}</p>
                              </div>
                            </div>
                          )}
                          {selectedPdf && (
                            <img
                              className=" closFile c-pointer"
                              onClick={fileRemove}
                              src={close}
                              alt="Remove"
                            />
                          )}
                        </div>
                      </div>
                      <button
                        className="add-btn c-pointer"
                        onClick={showUploadFIle}
                      >
                        {loading ? (
                          <CircularProgress size={18} color="inherit" />
                        ) : (
                          <>{lang?.add}</>
                        )}
                      </button>
                    </>
                  )}
                  {showUploadStatus && (
                    <div className="videoUpload-status heading-title">
                      <p>{lang?.upload_status}</p>
                      <p>{lang?.upload_status_para}</p>
                      <div>
                        <Box sx={{ width: "100%" }}>
                          <LinearProgressWithLabel value={progress} />
                        </Box>
                      </div>
                      {progress === 100 && (
                        <p className="success-mess">
                          {lang?.post_upload_success}
                        </p>
                      )}
                    </div>
                  )}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                  {disableYoutube && (
                    <>
                      <div className="title-size course-feilds url_input">
                        <Input
                          label="Link:"
                          placeholder="http://www.example.com"
                          type="text"
                          name="youtubeUrl"
                          value={youtubeUrl}
                          onChange={changeUrl}
                        />
                        {isValid ? null : (
                          <p style={{ color: "red" }}>{lang?.invalid_url}</p>
                        )}
                      </div>
                      <button
                        className="add-btn c-pointer"
                        type="submit"
                        onClick={() => youtubeLink()}
                      >
                        {loading ? (
                          <CircularProgress size={18} color="inherit" />
                        ) : (
                          <>{lang?.add}</>
                        )}
                      </button>
                    </>
                  )}
                  {showUploadStatus && (
                    <div className="videoUpload-status heading-title">
                      <p>{lang?.upload_status}</p>
                      <p>{lang?.upload_status_para}</p>
                      <div>
                        <Box sx={{ width: "100%" }}>
                          <LinearProgressWithLabel value={progress} />
                        </Box>
                      </div>
                      {progress === 100 && (
                        <p className="success-mess">
                          {lang?.post_upload_success}
                        </p>
                      )}
                    </div>
                  )}
                </CustomTabPanel>
              </Col>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CourseContent;
