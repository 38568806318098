import React from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import EventPost from "../../components/posts-details/EventPost";

const EventSharedPost = () => {
  const { event_id } = useParams();
  return (
    <Container fluid>
      <div
        className="vh-100 bg-offwhite space-top overflowY-auto scroll-hide overflowX-hidden"
        style={{ paddingBottom: "3rem" }}
      >
        <Row className="mt-3 mb-5">
          <Col xl={3}></Col>
          <Col xl={9}>
            <EventPost event_id={event_id} />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default EventSharedPost;
