import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Container,
  Modal,
  ModalBody,
  Collapse,
  Row,
  Spinner,
} from "reactstrap";
import NavBar from "../../components/NavBar";
import Details from "../../components/clinical-sessions/Details";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BaseURL, DeletePostApi } from "../../constants/API";
import { toast } from "react-toastify";
import { CLINICAL_SESSIONS } from "../../constants/RoutesUrl";
import InvitedUsers from "../../components/clinical-sessions/InvitedUsers";
import InviteUsers from "../../components/clinical-sessions/InviteUsers";
import { Language } from "../../App";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { CiCircleRemove } from "react-icons/ci";

const ClinicalSessionsDetails = () => {
  const screenWidth = window.innerWidth;
  const [openSidebar, setOpenSidebar] = useState(
    screenWidth < 1200 ? false : true
  );
  const { lang } = useContext(Language);
  const { id } = useParams(); // post_id from url
  const Navigate = useNavigate();
  // sessionStorage
  const SplashData = JSON.parse(sessionStorage.getItem("SplashData"));
  var user_id = SplashData?.user?.id;

  const AdmintabsData = [
    {
      id: "1",
      title: lang?.clinical_session_details,
    },
    {
      id: "2",
      title: lang?.send_invites,
    },
    {
      id: "3",
      title: lang?.invited_users,
    },
    {
      id: "4",
      title: lang?.delete_session,
    },
  ];

  const UsertabsData = [
    {
      id: "1",
      title: lang?.clinical_session_details,
    },
    {
      id: "3",
      title: lang?.invited_users,
    },
  ];

  const [tabs, setTabs] = useState("1");
  const [isLoading, setIsLoading] = useState(false);
  const [spin, setSpin] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState({});

  const handleDelete = () => {
    axios
      .get(DeletePostApi + id + "/delete")
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.action);
          Navigate(CLINICAL_SESSIONS);
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setSpin(true);
    fetch(BaseURL + `api/post/${id}/${user_id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`
          );
        }
        return response.json();
      })
      .then((data) => {
        if (data.status) {
          setData(data.data);
        } else {
          toast.error(data.error);
        }
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        setSpin(false);
      });
  }, []);

  return (
    <>
      <Container fluid className="bg-offwhite vh-100 overflowY-hidden">
        <Row>
          <NavBar />
        </Row>
        <Row>
          <Col lg={3} className="mt-3">
            <div className="bg-white border rounded jobs-side-shadow overflow-hidden">
              <div
                onClick={() => setOpenSidebar(!openSidebar)}
                className="p-3 d-flex justify-content-between align-items-center cursor"
              >
                <h1 className="fs-20 fs-md-16 m-0 roboto-bold">
                  {lang?.clinical_sessions}
                </h1>
                <span className="d-xl-none">
                  {openSidebar ? (
                    <CiCircleRemove size={22} />
                  ) : (
                    <MdOutlineKeyboardArrowRight size={26} />
                  )}
                </span>
              </div>
              {!spin && (
                <Collapse isOpen={openSidebar}>
                  <div>
                    {user_id === data.user_id
                      ? AdmintabsData.map((value, index) => (
                          <div
                            key={index}
                            className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                              tabs === value.id && "active"
                            }`}
                            onClick={() => {
                              if (screenWidth < 1200) {
                                if (value.id === "4") {
                                  setDeleteModal(true);
                                } else {
                                  setTabs(value.id);
                                }
                                setOpenSidebar(!openSidebar);
                              } else {
                                if (value.id === "4") {
                                  setDeleteModal(true);
                                } else {
                                  setTabs(value.id);
                                }
                              }
                            }}
                          >
                            <h1
                              className={`fs-18 fs-md-16 m-0 roboto-medium ${
                                value.id === "4" && "text-red"
                              }`}
                            >
                              {value.title}
                            </h1>
                          </div>
                        ))
                      : UsertabsData.map((value, index) => (
                          <div
                            key={index}
                            className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                              tabs === value.id && "active"
                            }`}
                            onClick={() => {
                              value.id === "4"
                                ? setDeleteModal(true)
                                : setTabs(value.id);
                            }}
                          >
                            <h1
                              className={`fs-18 fs-md-16 m-0 roboto-medium ${
                                value.id === "4" && "text-red"
                              }`}
                            >
                              {value.title}
                            </h1>
                          </div>
                        ))}
                  </div>
                </Collapse>
              )}
            </div>
          </Col>

          <Col lg={8} className="mt-3">
            <div className="bg-white p-3 rounded">
              <Row>
                <Col className="text-center roboto-bold">
                  {user_id === data.user_id
                    ? AdmintabsData.map((value) => (
                        <h5 className="mb-0" key={value.id}>
                          {tabs === value.id && value.title}
                        </h5>
                      ))
                    : UsertabsData.map((value) => (
                        <h5 className="mb-0" key={value.id}>
                          {tabs === value.id && value.title}
                        </h5>
                      ))}
                </Col>
                {tabs === "1" ? (
                  <div
                    className="overflow-scroll scroll-hide"
                    style={{ maxHeight: "100vh", paddingBottom: "7rem" }}
                  >
                    <Details user_id={user_id} data={data} load={spin} />
                  </div>
                ) : tabs === "2" ? (
                  <div
                    className="overflow-scroll scroll-hide"
                    style={{ maxHeight: "100vh", paddingBottom: "7rem" }}
                  >
                    <InviteUsers />
                  </div>
                ) : tabs === "3" ? (
                  <div
                    className="overflow-scroll scroll-hide"
                    style={{ maxHeight: "100vh", paddingBottom: "7rem" }}
                  >
                    <InvitedUsers />
                  </div>
                ) : (
                  ""
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Delete Clinical Session Modal */}
      <Modal isOpen={deleteModal} centered zIndex={9}>
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.delete_clinical_session_confirmation}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.delete_clinical_session_confirmation_para}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={handleDelete}
            >
              {isLoading ? <Spinner size="sm" /> : lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* Delete Clinical Session Modal Ended */}
    </>
  );
};

export default ClinicalSessionsDetails;
