import { useEffect, useContext, useState } from "react";
import axios from "axios";
import {
  BaseURL,
  BlockUnblockUserApi,
  GetBlockList,
} from "../../../constants/API";
import SpecSpinner from "../changeInterest/SpecSpinner";
import { toast } from "react-toastify";
import icon from "../../../assets/images/icon/placeholder_Awatar.png";
import { Language } from "../../../App";

function BlockAccount() {
  const localData = JSON.parse(sessionStorage.getItem("SplashData"));
  const login_id = localData?.user?.id;

  const [blockedUsers, setBlockUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { lang } = useContext(Language);

  const FetchData = () => {
    setIsLoading(true);
    axios
      .get(GetBlockList + login_id)
      .then((res) => {
        setBlockUsers(res.data.data.data);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    FetchData();
  }, []);

  const handleBlock = (id) => {
    axios
      .get(BlockUnblockUserApi + id + "/" + login_id)
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.action);
          FetchData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="rounded pxy-20 bg-white w-xl-75 w-lg-100 box-shadow1 m-xl-0 m-lg-auto m-md-auto m-sm-auto m-auto">
      <h3 className="d-flex justify-content-center mt--12 fs-20 fs-md-16 fw-bold">
        {lang?.block_accounts}
      </h3>

      {isLoading ? (
        <div className="text-center specloading">
          <SpecSpinner />
        </div>
      ) : (
        <div className="block-account d-flex flex-column gap-4 mt-4">
          {blockedUsers.map((item) => (
            <div
              className="box-shadow1 d-flex justify-content-between align-items-center p-16 br-10 ps-4 pe-4"
              key={item}
            >
              <div className="d-flex align-items-center gap-4">
                <span>
                  <img
                    src={item.image ? BaseURL + item.image : icon}
                    alt="picture"
                  />
                </span>
                <div>
                  <h3 className="fs-18 fs-md-16 fw-bold m-0">{item.name}</h3>
                  <h6 className="fs-18 fs-md-16 text-gray m-0">
                    {item.user_name}
                  </h6>
                </div>
              </div>
              <button
                className="bg-blue border-0 pxy-17 br-6 text-white fs-18 fs-md-16"
                onClick={() => handleBlock(item.id)}
              >
                {lang?.unblock}
              </button>
            </div>
          ))}
        </div>
      )}

      {/* <div className="mt-4 text-center">
        <button className="bg-blue border-0 pxy-17 w-30 fs-18 fs-md-16 fw-bold text-white br-6">
          Load More
        </button>
      </div> */}
    </div>
  );
}

export default BlockAccount;
