import { CropperRef, isEqualState, CropperProps, Cropper } from 'react-advanced-cropper';
import { Navigation } from './components/Navigation.tsx';
import { getCloserAngle } from 'advanced-cropper';
import React, { useRef, useState } from 'react';
import "react-advanced-cropper/dist/style.css";
import { Stencil } from '../../Stencil.tsx';
import './DefaultCropper.scss';
import cn from 'classnames';
import { useDebouncedCallback } from 'use-debounce';

export interface DefaultCropperProps extends CropperProps {
	wrapperClassName?: string;
	updateCoordinates?: Function;
	updateCoordinatesCheck?: boolean;
}

export type DefaultCropperMethods = CropperRef;

export const DefaultCropper = ({ wrapperClassName, updateCoordinates, updateCoordinatesCheck, className, ...props }: DefaultCropperProps) => {
	const [changed, setChanged] = useState(false);

	const cropperRef = useRef<CropperRef>(null);

	const getDefaultState = () => {
		const currentState = cropperRef.current?.getState();
		const defaultState = cropperRef.current?.getDefaultState();
		return currentState && defaultState
			? {
				...defaultState,
				transforms: {
					...defaultState.transforms,
					rotate: getCloserAngle(currentState.transforms.rotate, defaultState.transforms.rotate),
				},
			}
			: null;
	};

	const onRotate = (angle: number) => {
		cropperRef.current?.rotateImage(angle);
	};

	const onFlip = (horizontal: boolean, vertical: boolean) => {
		cropperRef.current?.flipImage(horizontal, vertical);
	};

	const onReset = () => {
		cropperRef.current?.setState(getDefaultState());
	};
	const onChange = useDebouncedCallback((cropper: CropperRef) => {
		setChanged(!isEqualState(cropper.getState(), getDefaultState()));
		if (updateCoordinatesCheck)
			updateCoordinates(cropper.getCoordinates());
	}, 500);

	return (
		<div className={cn('default-cropper', wrapperClassName)}>
			<Cropper
				onChange={onChange}
				className={cn('default-cropper__cropper', className)}
				ref={cropperRef}
				{...props}
			/>
			<div className="default-cropper__navigation">
				<Navigation changed={changed} onReset={onReset} onFlip={onFlip} onRotate={onRotate} />
			</div>
		</div>
	);
};

DefaultCropper.displayName = 'DefaultCropper';
