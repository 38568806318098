import React, { Fragment, useContext, useEffect, useState } from "react";
import JobsPreview from "./JobsPreview";
import { MyJobsApi } from "../../constants/API";
import { toast } from "react-toastify";
import SpecSpinner from "../manage_account/changeInterest/SpecSpinner";
import { Language } from "../../App";

const MyJobs = () => {
  // sessionStorage
  const SplashData = JSON.parse(sessionStorage.getItem("SplashData"));
  const user_id = SplashData?.user?.id;
  const { lang } = useContext(Language);

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [myJobs, setMyJobs] = useState([]);

  // Functions
  useEffect(() => {
    setIsLoading(true);
    fetch(`${MyJobsApi}${user_id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((res) => {
        if (res.status) {
          setMyJobs(res.data.data);
          setIsLoading(false);
        } else {
          toast.error(res.error);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  }, []);
  return (
    <>
      <div>
        <h1 className="fs-20 fs-sm-16 roboto-bold">{lang?.my_jobs}</h1>
      </div>
      {isLoading ? (
        <div className="profilejobspinner align-items-start">
          <SpecSpinner />
        </div>
      ) : (
        myJobs?.map((value, index) => {
          return (
            <Fragment key={index}>
              <JobsPreview
                id={value.id}
                job={value?.title}
                hospital_name={value?.hospital_name}
                location={value?.short_location}
                date={value?.time}
                description={value?.description}
                publisher_name={value?.user?.name}
                publisher_username={value?.user?.user_name}
                publisher_img={value?.user?.image}
              />
            </Fragment>
          );
        })
      )}
    </>
  );
};

export default MyJobs;
