import React from "react";
import NavBar from "../components/NavBar";
import Side from "../components/sideBar/Side";
import { Container, Row } from "reactstrap";

export const AddPost = () => {
  return (
    <div className="overflow-hidden">
      <Container fluid>
        <Row>
          <NavBar />
        </Row>
      </Container>
      <div>
        <Side />
      </div>
    </div>
  );
};
