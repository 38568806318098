import React, { useContext, useEffect, useState } from "react";
import NavBar from "../NavBar";
import { Link, useParams } from "react-router-dom";
import { JOBDETAIL } from "../../constants/RoutesUrl";
import { UnixToRelative } from "../notification/Notification";
import { BaseURL, MyJobsApi } from "../../constants/API";
import axios from "axios";
import SpecSpinner from "../manage_account/changeInterest/SpecSpinner";
import { Language } from "../../App";

const ProfileJob = (props) => {
  const param = useParams();
  const user_id = param.id;
  const [myJobs, setMyJobs] = useState([]);
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const { lang } = useContext(Language);

  useEffect(() => {
    axios
      .get(MyJobsApi + user_id)
      .then((res) => {
        setMyJobs(res.data.data.data);
        console.log("jobs all", res.data.data.data);
        if (res.data.data.data.length > 0) {
          setUserName(res.data.data.data[0].user.user_name);
        }
        setIsLoading(false); // Set loading to false once data is fetched
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false); // Set loading to false on error as well
      });
  }, []);

  return (
    <div>
      <div className="container-fluid ">
        <div className="row">
          <NavBar />
        </div>
        <div className="profilejobs">
          <div className="row  job-scroll  scroll-hide   ">
            <div className="col-md-6  mt-3  card   ">
              <h4 className="text-center mt-2">{userName}</h4>

              {isLoading ? (
                <div className="profilejobspinner">
                  <SpecSpinner />
                </div>
              ) : (
                <>
                  {myJobs.length > 0 ? (
                    myJobs.map((jobs) => (
                      <div key={jobs.id}>
                        <Link to={`${JOBDETAIL}/${jobs.id}`}>
                          <div
                            className="bg-white br-12 border jobs-preview-shadow mt-3 text-dark mb-4   "
                            key={jobs}
                          >
                            <div className="p-3">
                              <div>
                                <h2 className="fs-20 fs-md-16 roboto-medium">
                                  {jobs.title}
                                </h2>
                              </div>
                              <div>
                                <p
                                  className="fs-16 roboto-medium pt-2"
                                  style={{ color: "#2278EF" }}
                                >
                                  {jobs.hospital_name}
                                </p>
                              </div>
                              <div>
                                <p className="fs-16 roboto-regular fw-bold">
                                  {jobs.description}
                                </p>
                              </div>
                              <div className="d-flex justify-content-between py-2">
                                <div>
                                  <p>{jobs.location}</p>
                                </div>

                                <div>
                                  <p className="fs-16 roboto-regular text-gray">
                                    {" "}
                                    <UnixToRelative unixTimestamp={jobs.time} />
                                  </p>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="p-3">
                              <div>
                                <h3 className="fs-20 fs-md-16 roboto-medium">
                                  {lang?.published_by}:
                                </h3>
                              </div>
                              <div className="gap-3 d-flex py-2">
                                <div>
                                  <div className="bg-white p-1 br-24 dropdown-avatar-shadow">
                                    <img
                                      src={
                                        jobs.user.image
                                          ? BaseURL + jobs.user.image
                                          : ""
                                      }
                                      alt="picture"
                                      width={70}
                                      height={70}
                                      className="br-24"
                                    />
                                  </div>
                                </div>
                                <div className="align-self-center">
                                  <h4 className="fs-16 roboto-medium">
                                    {jobs.user.name}
                                  </h4>
                                  <span className="fs-16 roboto-regular">
                                    @{jobs.user.user_name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))
                  ) : (
                    <p className=" mt-5 fw-bold text-center">
                      {lang?.no_job_available}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileJob;
