import React, { useContext, useState } from "react";
import axios from "axios";
import { CreateJobApi } from "../../constants/API";
import { Modal, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { GOOGLE_KEY } from "../../constants/Keys";
import { TiCancel } from "react-icons/ti";
import { toast } from "react-toastify";
import { Language } from "../../App";

const CreateNewJob = () => {
  const localData = JSON.parse(sessionStorage.getItem("SplashData"));
  const login_id = localData?.user?.id;

  // Define states for your form inputs
  const [jobtitle, setJobTitle] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [completeAddress, setCompleteAddress] = useState("");
  const [jobDescription, setJobDescription] = useState("");

  //loading state
  const [isLoading, setIsLoading] = useState("");

  //google map states
  const [locationModal, setLocationModal] = useState(false);
  const [mapCenter, setMapCenter] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [formData, setFormData] = useState({});
  const { lang } = useContext(Language);

  // Marker drag
  const handleMarkerDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });
    reverseGeocode(lat, lng);
  };

  // To get city and country using lat lng
  const reverseGeocode = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = new window.google.maps.LatLng(lat, lng);

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results[0]) {
        const addressComponents = results[0].address_components;
        let cityName = "";
        let countryName = "";

        for (const component of addressComponents) {
          if (component.types.includes("locality")) {
            cityName = component.long_name;
          } else if (component.types.includes("country")) {
            countryName = component.long_name;
          }
        }

        setCity(cityName);
        setCountry(countryName);
      } else {
        setCity("");
        setCountry("");
      }
    });
  };

  // Location Lat_Lng
  const handleLocation = () => {
    if (formData?.lat_lng || markerPosition) {
      setLocationModal(true);
      return;
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          console.log({ latitude, longitude });
          setMapCenter({ lat: latitude, lng: longitude });
          setMarkerPosition({ lat: latitude, lng: longitude });
        },
        () => {}
      );
      setTimeout(() => {
        setLocationModal(true);
      }, 500);
    } else {
      toast.error(lang?.geolocation_not_suported);
    }
  };

  // Map click
  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });
    reverseGeocode(lat, lng);
  };

  // Google Map
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_KEY,
  });

  // Handle form submission
  const handleJobPost = async () => {
    setIsLoading(true);

    try {
      const locations = city && country ? `${city}, ${country}` : "";
      const lat = markerPosition ? markerPosition.lat : "";
      const lng = markerPosition ? markerPosition.lng : "";

      const response = await axios.post(CreateJobApi + login_id, {
        userId: login_id,
        title: jobtitle,
        hospital_name: hospitalName,
        location: locations,
        lat_lng: lat && lng ? `${lat},${lng}` : "",
        short_location: completeAddress,
        description: jobDescription,
      });

      setIsLoading(false);

      if (response.status) {
        window.location.reload();
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      console.error("Error posting job", error);
      setIsLoading(false);
    }
  };

  return (
    <div className="edit-profile rounded py-lg-5 py-md-4 py-sm-4 py-4 bg-white box-shadow1 m-xl-0 m-lg-auto m-md-auto">
      <h3 className="d-flex justify-content-center mt--12 fs-20 fs-sm-16 fw-bold">
        {lang?.create_job}
      </h3>
      <form className="w-75 m-auto">
        <div className="d-flex flex-column gap-4">
          <div>
            <h6 className="fs-16 fw-bold ">{lang?.title}</h6>
            <input
              placeholder={lang?.job_title}
              type="text"
              value={jobtitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />
          </div>

          <div>
            <h6 className="fs-16 fw-bold ">{lang?.hospital_name}</h6>
            <input
              placeholder={lang?.hospital_name}
              type="text"
              value={hospitalName}
              onChange={(e) => setHospitalName(e.target.value)}
            />
          </div>

          <div>
            <h6 className="fs-16 fw-bold ">{lang?.location}</h6>
            <div className="position-relative">
              <input
                onClick={handleLocation}
                type="text"
                className="py-2"
                placeholder={lang?.city_country}
                value={
                  formData?.lat_lng && city && country
                    ? `${city}, ${country}`
                    : ""
                }
              />
              {city !== "" && country !== "" && (
                <div
                  className="position-absolute end-0 top-0 me-2"
                  style={{ marginTop: "13px" }}
                >
                  <h6
                    className="text-primary cursor"
                    onClick={() => {
                      setFormData({
                        ...formData,
                        lat_lng: "",
                      });
                      setCity("");
                      setCountry("");
                    }}
                  >
                    <TiCancel size={18} />
                    {lang?.remove}
                  </h6>
                </div>
              )}
            </div>
          </div>

          <div>
            <h6 className="fs-16 fw-bold">{lang?.complete_adress}</h6>
            <input
              type="text"
              placeholder={lang?.complete_adress}
              value={completeAddress}
              onChange={(e) => setCompleteAddress(e.target.value)}
            />
          </div>

          <div>
            <h6 className="fs-16 fw-bold ">{lang?.job_description}</h6>
            <textarea
              placeholder={lang?.description}
              cols="30"
              rows="10"
              value={jobDescription}
              onChange={(e) => setJobDescription(e.target.value)}
            ></textarea>
          </div>

          <button
            type="button"
            className="bg-blue border-0 p-3 br-10 fs-16 fw-bold text-white mb-4"
            onClick={handleJobPost}
          >
            {isLoading ? (
              <div className="profilejobspinner align-items-start">
                <Spinner />
              </div>
            ) : (
              lang?.create_job
            )}
          </button>
        </div>
      </form>

      {/* Google map modal */}
      <Modal isOpen={locationModal} centered zIndex={9} size="lg">
        <ModalHeader toggle={() => setLocationModal(false)}></ModalHeader>
        {loadError && lang?.error_loading_maps}
        {!isLoaded && lang?.loading_maps}
        <div style={{ height: "50vh" }}>
          <GoogleMap
            mapContainerClassName="w-100 h-100"
            center={mapCenter}
            zoom={15}
            onClick={handleMapClick}
          >
            {markerPosition && (
              <Marker
                position={markerPosition}
                draggable={true}
                title={JSON.stringify(markerPosition)}
                icon={{
                  url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                }}
                onDragEnd={handleMarkerDragEnd}
              />
            )}
          </GoogleMap>
        </div>
        <ModalFooter className="gap-4">
          <button
            className="btn btn-secondary py-2 px-4"
            onClick={() => {
              setLocationModal(false);
            }}
          >
            {lang?.cancel}
          </button>
          <button
            className="btn-blue border-0 px-4 py-2"
            onClick={() => {
              setFormData({ ...formData, lat_lng: markerPosition });
              setLocationModal(false);
              const lat = markerPosition.lat;
              const lng = markerPosition.lng;
              reverseGeocode(lat, lng);
            }}
          >
            {lang?.save}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CreateNewJob;
