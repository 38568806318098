import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  BaseURL,
  ClinicalSessionUserSearchApi,
  ClinicalSessionsInviteUserApi,
  ClinicalSessionsInviteUsersListApi,
} from "../../constants/API";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { PROFILE } from "../../constants/RoutesUrl";
import { Col, Row, Spinner } from "reactstrap";
import InfiniteScroll from "react-infinite-scroller";
import { Language } from "../../App";
import Search from "../../assets/images/icon/search.png";

const InviteUsers = () => {
  const { lang } = useContext(Language);
  const { id } = useParams(); // post_id

  // sessionStorage
  const SplashData = JSON.parse(sessionStorage.getItem("SplashData"));
  var user_id = SplashData?.user?.id;

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(1);

  const handleFetch = (page_no) => {
    axios
      .get(
        ClinicalSessionsInviteUsersListApi +
          id +
          "/" +
          user_id +
          "?page=" +
          JSON.stringify(page_no)
      )
      .then((res) => {
        if (res.data.status) {
          setData(res.data.data);
          const data =
            userList && userList.length > 0
              ? userList.concat(res.data.data.data)
              : res.data.data.data;
          setUserList(data);
          setPage(page + 1);
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [fetchData, setFetchData] = useState([]);

  const handleSearch = (keyword) => {
    if (keyword === "") {
      setFetchData([]);
      return;
    }
    setIsLoading(true);
    const data = { post_id: id, user_id, keyword };
    axios
      .post(ClinicalSessionUserSearchApi, data)
      .then((res) => {
        if (res.data.status) {
          setFetchData(res.data.data.data);
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    handleFetch(page);
  }, []);

  const [searchInput, setSearchInput] = useState("");

  return (
    <>
      <div className="py-3">
        <div className="position-relative">
          <img
            src={Search}
            className="position-absolute start-0 ms-3 mt-11"
            width={20}
            height={20}
            alt="picture"
          />
          <input
            type="text"
            className="form-control py-2 ps-5"
            placeholder={lang?.search}
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
              handleSearch(e.target.value);
            }}
          />
        </div>
      </div>

      {isLoading ? (
        <Row>
          <Col className="text-center my-3">
            <Spinner />
          </Col>
        </Row>
      ) : (
        <>
          {fetchData && fetchData.length > 0 ? (
            fetchData.map((data) => (
              <div className="block-account mt-3">
                <UserCard data={data} />
              </div>
            ))
          ) : (
            <div
              className="vh-100 overflowY-auto scroll-hide"
              style={{ scrollPaddingBottom: "13rem" }}
            >
              <InfiniteScroll
                loadMore={() => handleFetch(page)}
                hasMore={data?.current_page !== data?.last_page ? true : false}
                loader={
                  <Row className="text-center my-3">
                    <Col>
                      <Spinner />
                    </Col>
                  </Row>
                }
                useWindow={false}
              >
                {userList &&
                  userList.map((value) => (
                    <div className="block-account mt-3">
                      <UserCard data={value} />
                    </div>
                  ))}
              </InfiniteScroll>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default InviteUsers;

const UserCard = (props) => {
  const { lang } = useContext(Language);
  const { id } = useParams(); // post_id
  const { data } = props;
  const Navigation = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(false);

  const handleSendInvites = () => {
    setIsLoading(true);
    axios
      .get(ClinicalSessionsInviteUserApi + id + "/" + data?.id)
      .then((res) => {
        if (res.data.status) {
          setStatus(!status);
          toast.success(res.data.action);
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    console.log(data.invite);
    setStatus(data.invite);
  }, []);

  return (
    <div
      className="box-shadow1 d-flex justify-content-between align-items-center p-2 manue-hover rounded"
      key={data?.id}
    >
      <div
        className="d-flex align-items-center gap-md-4 gap-sm-3 gap-2 cursor w-100"
        onClick={() => {
          Navigation(PROFILE + "/" + data?.id);
        }}
      >
        <span>
          <img
            src={data?.image ? BaseURL + data?.image : Avatar}
            alt="picture"
          />
        </span>
        <div>
          <h3 className="fs-18 fs-md-15 fw-bold text-ellips-110px">
            {data?.name}
          </h3>
          <h6 className="fs-16 text-gray m-0 text-ellips-110px">
            {data?.user_name}
          </h6>
        </div>
      </div>
      <button
        disabled={isLoading}
        onClick={handleSendInvites}
        className={`${
          status
            ? "bg-blue border-0 text-white"
            : "bg-white border border-1 border-inherit text-dark"
        } px-md-4 px-ms-3 px-2 py-2 br-6 fw-bold fs-18 fs-md-15`}
      >
        {status ? lang?.invited : lang?.invite}
      </button>
    </div>
  );
};
