import { RouterProvider } from "react-router-dom";
import Router from "./constants/Routing";
import { createContext, useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import English from "./lang/en.json";
import Spanish from "./lang/sp.json";

export const Sponsorpost = createContext();
export const Language = createContext();

function App() {
  const [postImage, setPostImage] = useState("");
  const [lang, setLang] = useState({});

  const updateContext = (newValue) => {
    setPostImage(newValue);
  };
  const updateLang = (newValue) => {
    setLang(newValue);
  };

  useEffect(() => {
    // Get the user's timezone using Intl.DateTimeFormat
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    sessionStorage.setItem("user_timezone", userTimezone);

    if (navigator.language.startsWith("en")) {
      setLang(English);
    } else {
      setLang(Spanish);
    }
  }, []);
  return (
    <Language.Provider value={{ lang, updateLang }}>
      <Sponsorpost.Provider value={{ postImage, updateContext }}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <RouterProvider router={Router} />
      </Sponsorpost.Provider>
    </Language.Provider>
  );
}

export default App;
