import React, { useState, useContext, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Collapse,
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap";

import NavBar from "../../components/NavBar";
import EditProfile from "../../components/manage_account/editProfile/EditProfile";
import TermPrivacy from "../../components/manage_account/termPrivacy/TermPrivacy";
import HelpSupport from "../../components/manage_account/helpSupport/HelpSupport";
import ChangePassword from "../../components/manage_account/ChangePassword";
import Specialization from "../../components/manage_account/changeInterest/Specialization";
import BlockAccount from "../../components/manage_account/blockAccount/BlockAccount";
import axios from "axios";
import { DeleteAccountApi, LogoutApi } from "../../constants/API";
import { useNavigate, useParams } from "react-router-dom";
import { LOGIN } from "../../constants/RoutesUrl";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { CiCircleRemove } from "react-icons/ci";
import { toast } from "react-toastify";
import { Language } from "../../App";

const SettingModule = () => {
  const param = useParams();

  const localData = JSON.parse(sessionStorage.getItem("SplashData"));
  const login_id = localData?.user?.id;
  const [tabs, setTabs] = useState(param?.id);

  const [deleteModal, setDeleteModal] = useState(false);
  const [signOutModal, setSignOutModal] = useState(false);
  const navigate = useNavigate("");
  const [isLoadingdelete, setIsLoadingDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { lang } = useContext(Language);
  const screenWidth = window.innerWidth;
  const [openSidebar, setOpenSidebar] = useState(
    screenWidth < 1200 ? false : true
  );

  const handleDelete = () => {
    setIsLoadingDelete(true);
    axios
      .get(DeleteAccountApi + login_id)
      .then((res) => {
        console.log("Account Delete successfully", res);
        if (res.data.status) {
          setIsLoadingDelete(false);
          sessionStorage.clear();
          localStorage.clear();
          navigate(LOGIN);
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((error) => {
        console.log("Error deleteing  data:", error);
        setIsLoadingDelete(false);
      });
  };

  const data = JSON.parse(localStorage.getItem("SignupData"));

  const handleSignOut = () => {
    setIsLoading(true);
    const userId = data?.data?.id;

    axios
      .post(LogoutApi, {
        userId,
      })
      .then((response) => {
        if (response.data.status) {
          sessionStorage.clear();
          localStorage.clear();
          window.location.reload();
          setSignOutModal(false);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Container fluid className="bg-offwhite vh-100 overflowY-hidden">
      <Row>
        <NavBar />
      </Row>
      <Row className="px-lg-5 px-md-4 px-sm-2 px-2 pt-3">
        <Col
          xl={3}
          className="overflow-scroll scroll-hide"
          style={{
            paddingBottom: openSidebar ? "5rem" : "1rem",
            maxHeight: "100vh",
          }}
        >
          <div className="bg-white border rounded jobs-side-shadow overflow-hidden">
            <div
              onClick={() => setOpenSidebar(!openSidebar)}
              className="p-3 d-flex justify-content-between align-items-center cursor"
            >
              <h1 className="fs-20 fs-md-16 m-0 roboto-bold">
                {lang?.manage_settings}
              </h1>
              <span className="d-xl-none">
                {openSidebar ? (
                  <CiCircleRemove size={22} />
                ) : (
                  <MdOutlineKeyboardArrowRight size={26} />
                )}
              </span>
            </div>
            <Collapse isOpen={openSidebar}>
              <div
                className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                  tabs == 1 && "active"
                }`}
                onClick={() => {
                  if (screenWidth < 1200) {
                    setTabs(1);
                    setOpenSidebar(!openSidebar);
                  } else {
                    setTabs(1);
                  }
                }}
              >
                <h1 className="fs-16 roboto-medium m-0">
                  {lang?.edit_profile}
                </h1>
              </div>
              <div
                className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                  tabs == 2 && "active"
                }`}
                onClick={() => {
                  if (screenWidth < 1200) {
                    setTabs(2);
                    setOpenSidebar(!openSidebar);
                  } else {
                    setTabs(2);
                  }
                }}
              >
                <h1 className="fs-16 roboto-medium m-0">
                  {lang?.change_interest_specializations}
                </h1>
              </div>
              <div
                className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                  tabs == 3 && "active"
                }`}
                onClick={() => {
                  if (screenWidth < 1200) {
                    setTabs(3);
                    setOpenSidebar(!openSidebar);
                  } else {
                    setTabs(3);
                  }
                }}
              >
                <h1 className="fs-16 roboto-medium m-0">
                  {lang?.change_password}
                </h1>
              </div>
              <div
                className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                  tabs == 4 && "active"
                }`}
                onClick={() => {
                  if (screenWidth < 1200) {
                    setTabs(4);
                    setOpenSidebar(!openSidebar);
                  } else {
                    setTabs(4);
                  }
                }}
              >
                <h1 className="fs-16 roboto-medium m-0">
                  {lang?.block_accounts}
                </h1>
              </div>
              <div
                className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                  tabs == 5 && "active"
                }`}
                onClick={() => {
                  if (screenWidth < 1200) {
                    setTabs(5);
                    setOpenSidebar(!openSidebar);
                  } else {
                    setTabs(5);
                  }
                }}
              >
                <h1 className="fs-16 roboto-medium m-0">
                  {lang?.help_support}
                </h1>
              </div>
              <div
                className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                  tabs == 6 && "active"
                }`}
                onClick={() => {
                  if (screenWidth < 1200) {
                    setTabs(6);
                    setOpenSidebar(!openSidebar);
                  } else {
                    setTabs(6);
                  }
                }}
              >
                <h1 className="fs-16 roboto-medium m-0">
                  {lang?.terms_privacy}
                </h1>
              </div>
              <div
                className="p-3 ps-4 border-top cursor manue-hover hover-blue"
                onClick={() => {
                  setDeleteModal(true);
                }}
              >
                <h1 className="fs-16 roboto-medium m-0 text-danger">
                  {lang?.delete_account}
                </h1>
              </div>
              <div
                className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                  tabs == 7 && "active"
                }`}
                onClick={() => {
                  setSignOutModal(true);
                }}
              >
                <h1 className="fs-16 roboto-medium m-0 text-danger">
                  {lang?.signout}
                </h1>
              </div>
            </Collapse>
          </div>
        </Col>
        <Col
          xl={9}
          lg={12}
          md={12}
          sm={12}
          className="overflowY-auto scroll-hide vh-100"
          style={{ paddingBottom: "12rem" }}
        >
          {tabs == 1 ? (
            <EditProfile />
          ) : tabs == 2 ? (
            <Specialization />
          ) : tabs == 3 ? (
            <ChangePassword />
          ) : tabs == 4 ? (
            <BlockAccount />
          ) : tabs == 5 ? (
            <HelpSupport />
          ) : (
            tabs == 6 && <TermPrivacy />
          )}

          {/* delete modal */}
          <Modal
            isOpen={deleteModal}
            toggle={() => setDeleteModal(!deleteModal)}
            centered
            zIndex={9}
          >
            <ModalBody>
              <div className="pr-62px">
                <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
                  {lang?.do_want_delete_account}
                </h2>
                <p className="fs-16 fs-md-15 roboto-regular">
                  {lang?.delete_account_para}
                </p>
              </div>
              <hr />
              <div className="justify-content-end gap-3 d-flex">
                <button
                  className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
                  onClick={() => {
                    setDeleteModal(false);
                  }}
                >
                  {lang?.cancel}
                </button>
                <button
                  className="btn-red border-0 rounded w-100 py-2 fs-md-14"
                  onClick={handleDelete}
                >
                  {isLoadingdelete ? (
                    <div>
                      <Spinner />
                    </div>
                  ) : (
                    lang?.yes_delete
                  )}
                </button>
              </div>
            </ModalBody>
          </Modal>

          {/* sign out modal */}
          <Modal
            isOpen={signOutModal}
            toggle={() => setSignOutModal(!signOutModal)}
            centered
            zIndex={9}
          >
            <ModalBody>
              <div className="pr-62px">
                <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
                  {lang?.do_want_sign_out_account}
                </h2>
                <p className="fs-16 fs-md-15 roboto-regular">
                  {lang?.signout_modal_para}
                </p>
              </div>
              <hr />
              <div className="justify-content-end gap-3 d-flex">
                <button
                  className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
                  onClick={() => {
                    setSignOutModal(false);
                  }}
                >
                  {lang?.cancel}
                </button>

                <button
                  className="btn-red border-0 rounded w-100 py-2 fs-md-14"
                  onClick={() => {
                    handleSignOut();
                  }}
                >
                  {isLoading ? <Spinner size="sm" /> : lang?.yes_signout}
                </button>
              </div>
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default SettingModule;
