import React, { useCallback, useContext, useEffect, useState } from "react";
import { Col, Container, Collapse, Modal, ModalBody, Row } from "reactstrap";
import NavBar from "../../components/NavBar";
import CourseDetail from "../../components/course-details/CourseDetail";
import PurchasedUser from "../../components/course-details/PurchasedUser";
import axios from "axios";
import {
  DeleteCourseApi,
  DetailCourseApi,
  ReportCourseApi,
  SaveCourseApi,
} from "../../constants/API";
import { useNavigate } from "react-router-dom";
import { COURSES } from "../../constants/RoutesUrl";
import CourseCertificate from "../../components/course-details/CourseCertificate";
import CourseContent from "../../components/course-details/CourseContent";
import EditCourse from "../../components/course-details/EditCourse";
import CourseSectionContent from "../../components/course-details/CourseSectionContent";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { CiCircleRemove } from "react-icons/ci";
import { Language } from "../../App";

const CoursesDetail = () => {
  const { lang } = useContext(Language);
  const navigate = useNavigate();
  const course_id = sessionStorage.getItem("CourseId");
  const user = JSON.parse(sessionStorage.getItem("SplashData"));
  const user_id = user.user.id;

  const [spin, setSpin] = useState(false);
  const [tabs, setTabs] = useState(1);
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [saveCourse, setSaveCourse] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [reportInput, setReportInput] = useState("");
  const screenWidth = window.innerWidth;
  const [openSidebar, setOpenSidebar] = useState(
    screenWidth < 1200 ? false : true
  );

  const handleSave = () => {
    setIsLoading(true);
    axios
      .get(SaveCourseApi + course_id + "/" + user_id)
      .then((res) => {
        setSaveCourse(!saveCourse);
        setSaveModal(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSaveModal(false);
        setIsLoading(false);
      });
  };
  const handleDelete = () => {
    setIsLoading(true);
    axios
      .get(DeleteCourseApi + course_id)
      .then((res) => {
        // console.log(res.data);
        setDeleteModal(false);
        setIsLoading(false);
        if (res.data.status) {
          navigate(COURSES + "/2");
        }
      })
      .catch((error) => {
        console.log(error);
        setDeleteModal(false);
        setIsLoading(false);
      });
  };
  const handleReport = () => {
    setIsLoading(true);
    axios
      .post(ReportCourseApi, {
        report: reportInput,
        user_id,
        report_id: course_id,
        type: "course",
      })
      .then((res) => {
        // console.log(res.data);
        setReportModal(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setReportModal(false);
        setIsLoading(false);
      });
  };
  const handleDetails = useCallback(() => {
    axios
      .get(DetailCourseApi + course_id + "/" + user_id)
      .then((res) => {
        setSaveCourse(res.data.data.isSave);
        setUserId(res.data.data.user.id);
        setSpin(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [saveCourse]);
  useEffect(() => {
    handleDetails();
  }, []);

  return (
    <>
      <Container fluid className="bg-offwhite vh-100 overflowY-hidden">
        <Row>
          <NavBar />
        </Row>
        <Row className="px-lg-5 px-md-4 px-sm-2 px-2">
          <Col xl={3} className="mt-3">
            {spin && (
              <div className="bg-white border rounded jobs-side-shadow overflow-hidden">
                <div
                  onClick={() => setOpenSidebar(!openSidebar)}
                  className="cursor p-3 d-flex justify-content-between align-items-center"
                >
                  <h1 className="fs-20 fs-md-16 m-0 roboto-bold">
                    {lang?.medical_learnings}
                  </h1>
                  <span className="d-xl-none">
                    {openSidebar ? (
                      <CiCircleRemove size={22} />
                    ) : (
                      <MdOutlineKeyboardArrowRight size={26} />
                    )}
                  </span>
                </div>
                <Collapse isOpen={openSidebar}>
                  <div
                    className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                      tabs === 1 && "active"
                    }`}
                    onClick={() => {
                      if (screenWidth < 1200) {
                        setTabs(1);
                        setOpenSidebar(!openSidebar);
                      } else {
                        setTabs(1);
                      }
                    }}
                  >
                    <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                      {lang?.course_detail}
                    </h1>
                  </div>
                  {userId === user_id && (
                    <>
                      <div
                        className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                          tabs === 2 && "active"
                        }`}
                        onClick={() => {
                          if (screenWidth < 1200) {
                            setTabs(2);
                            setOpenSidebar(!openSidebar);
                          } else {
                            setTabs(2);
                          }
                        }}
                      >
                        <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                          {lang?.purchased_user}
                        </h1>
                      </div>
                      <div
                        className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                          tabs === 3 || tabs === 6 ? "active" : ""
                        }`}
                        onClick={() => {
                          if (screenWidth < 1200) {
                            setTabs(3);
                            setOpenSidebar(!openSidebar);
                          } else {
                            setTabs(3);
                          }
                        }}
                      >
                        <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                          {lang?.edit_course_sec}
                        </h1>
                      </div>

                      <div
                        className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                          tabs === 4 && "active"
                        }`}
                        onClick={() => {
                          if (screenWidth < 1200) {
                            setTabs(4);
                            setOpenSidebar(!openSidebar);
                          } else {
                            setTabs(4);
                          }
                        }}
                      >
                        <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                          {lang?.edit_course_certi}
                        </h1>
                      </div>
                      <div
                        className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                          tabs === 5 && "active"
                        }`}
                        onClick={() => {
                          if (screenWidth < 1200) {
                            setTabs(5);
                            setOpenSidebar(!openSidebar);
                          } else {
                            setTabs(5);
                          }
                        }}
                      >
                        <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                          {lang?.edit_course}
                        </h1>
                      </div>
                    </>
                  )}
                  {userId !== user_id && (
                    <div
                      className={`p-3 ps-4 border-top cursor manue-hover hover-blue`}
                      onClick={() => {
                        setSaveModal(true);
                      }}
                    >
                      <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                        {saveCourse ? lang?.unsave : lang?.save} {lang?.course}
                      </h1>
                    </div>
                  )}
                  {userId === user_id && (
                    <>
                      <div
                        className={`p-3 ps-4 border-top cursor manue-hover hover-blue`}
                        onClick={() => {
                          setDeleteModal(true);
                        }}
                      >
                        <h1
                          className="fs-18 fs-md-16 m-0 roboto-medium"
                          style={{ color: "#FF1717" }}
                        >
                          {lang?.delete_course}
                        </h1>
                      </div>
                    </>
                  )}
                  {userId !== user_id && (
                    <div
                      className={`p-3 ps-4 border-top cursor manue-hover hover-blue`}
                      onClick={() => {
                        setReportModal(true);
                      }}
                    >
                      <h1
                        className="fs-18 fs-md-16 m-0 roboto-medium"
                        style={{ color: "#FF1717" }}
                      >
                        {lang?.report_course}
                      </h1>
                    </div>
                  )}
                </Collapse>
              </div>
            )}
          </Col>
          <Col
            xl={9}
            className="overflowY-auto vh-100 pt-3 scroll-hide"
            style={{ paddingBottom: "10rem" }}
          >
            {tabs === 1 ? (
              <CourseDetail />
            ) : tabs === 2 ? (
              <PurchasedUser />
            ) : tabs === 3 ? (
              <CourseContent
                clickContent={(num) => {
                  setTabs(num);
                }}
              />
            ) : tabs === 4 ? (
              <CourseCertificate />
            ) : tabs === 5 ? (
              <EditCourse />
            ) : (
              tabs === 6 && (
                <CourseSectionContent
                  clickContent={(num) => {
                    setTabs(num);
                  }}
                />
              )
            )}
          </Col>
        </Row>
      </Container>

      {/* Delete Modal */}
      <Modal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        centered
        zIndex={9}
      >
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_you_delete_course}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.do_you_delete_course_para}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              disabled={isLoading}
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={handleDelete}
            >
              {isLoading ? <div className="loader"></div> : lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* Modal Ended */}
      {/* Save/unSave Modal */}
      <Modal
        centered
        zIndex={9}
        isOpen={saveModal}
        toggle={() => setSaveModal(!saveModal)}
      >
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_you_want_to} {saveCourse ? lang?.unsave : lang?.save}{" "}
              {lang?.this_course}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.after} {saveCourse ? lang?.unsaving : lang?.saving}{" "}
              {lang?.this_course_you} {saveCourse ? lang?.cannot : lang?.can}
              {lang?.view_course_save_course}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setSaveModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              disabled={isLoading}
              className="btn-blue border-0 rounded w-100 py-2 fs-md-14"
              onClick={() => handleSave()}
            >
              {isLoading ? (
                <div className="loader"></div>
              ) : (
                `${lang?.yes}, ${saveCourse ? lang?.unsave : lang?.save}`
              )}
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* Modal Ended */}
      {/* Report Modal */}
      <Modal
        isOpen={reportModal}
        toggle={() => setReportModal(!reportModal)}
        centered
        zIndex={9}
      >
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.report_this_course}
            </h2>
            <p
              className="fs-16 fs-md-15 roboto-regular"
              style={{ opacity: "75%" }}
            >
              {lang?.report_this_event_para}
            </p>
          </div>
          <textarea
            className="form-control my-3"
            cols="30"
            rows="7"
            placeholder={lang?.enter_your_report}
            onChange={(e) => {
              setReportInput(e.target.value);
            }}
          ></textarea>
          <div className="d-flex justify-content-end gap-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => setReportModal(false)}
            >
              {lang?.cancel}
            </button>
            <button
              disabled={!reportInput || isLoading}
              className="btn-blue border-0 rounded w-100 py-2 fs-md-14"
              onClick={() => {
                handleReport();
              }}
            >
              {isLoading ? <div className="loader"></div> : lang?.submit_report}
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* Report Modal Ended */}
    </>
  );
};

export default CoursesDetail;
