import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import SpinnerGif from "../../assets/images/Spinner.gif";

const ImageComponent = ({
  src,
  compClassName,
  img_width,
  img_height,
  loader_width,
  loader_height,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const loadImage = () => {
      const imageToLoad = new Image();
      imageToLoad.src = src;
      imageToLoad.onload = () => {
        setImageSrc(src);
      };
    };

    loadImage();
  }, [src]);

  return (
    <>
      {isLoading && (
        <Col
          className={`text-center align-self-center d-flex align-items-center justify-content-center ${
            isLoading ? "d-block" : "d-none"
          }`}
          style={{
            margin: "0",
            height: loader_height,
            width: loader_width,
          }}
        >
          <div>
            <img src={SpinnerGif} alt="loading..." width={25} height={25} />
          </div>
        </Col>
      )}
      <img
        src={imageSrc}
        className={`${compClassName} ${isLoading ? "d-none" : "d-block"}`}
        alt="image"
        width={img_width}
        height={img_height}
        onLoad={() => {
          setIsLoading(false);
        }}
      />
    </>
  );
};

export default ImageComponent;
