import React, { useState, useEffect } from "react";

interface VideoWithAspectRatioProps {
  aspectRatio: number;
  src: string;
}

const VideoWithAspectRatio: React.FC<VideoWithAspectRatioProps> = ({
  aspectRatio,
  src,
}) => {
  const [width, setWidth] = useState<number | null>(null);
  const [height, setHeight] = useState<number | null>(null);

  useEffect(() => {
    const calculatedWidth = 555;
    const calculatedHeight = calculatedWidth / aspectRatio;

    setWidth(calculatedWidth);
    setHeight(calculatedHeight);
  }, [aspectRatio]);

  return (
    <div>
      <video src={src} controls style={{ width: "100%", height: "auto" }} />
    </div>
  );
};

export default VideoWithAspectRatio;
