import React, { Fragment, useEffect, useContext, useState } from "react";
import { Col, Row } from "reactstrap";
import Spinner from "../Spinner";
import PlaceholderImg from "../../assets/images/icon/placeholder_Awatar.png";
import ArrowRight from "../../assets/images/icon/arrow-right.png";
import axios from "axios";
import { BaseURL, UsersPurchasedCoursesApi } from "../../constants/API";
import { Language } from "../../App";
const PurchasedUser = () => {
  const course_id = sessionStorage.getItem("CourseId");
  const user = JSON.parse(sessionStorage.getItem("SplashData"));
  const user_id = user.user.id;
  const { lang } = useContext(Language);
  const [spin, setSpin] = useState(false);
  const [data, setdata] = useState({});
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleNewPageData = () => {
    setIsLoading(true);
    axios
      .get(
        UsersPurchasedCoursesApi +
          course_id +
          "/" +
          user_id +
          "?page=" +
          JSON.stringify(page + 1)
      )
      .then((res) => {
        const updatedValue = res.data.data.data;
        setUserData(userData.concat(updatedValue));
        setdata(res.data.data);
        setPage(page + 1);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handlePurchasedUsers = () => {
    setSpin(true);
    axios
      .get(
        UsersPurchasedCoursesApi +
          course_id +
          "/" +
          user_id +
          "?page=" +
          JSON.stringify(page)
      )
      .then((res) => {
        setUserData(res.data.data.data);
        setdata(res.data.data);

        setSpin(false);
      })
      .catch((error) => {
        console.error(error);
        setSpin(false);
      });
  };
  useEffect(() => {
    handlePurchasedUsers();
  }, []);
  return (
    <Row>
      {spin ? (
        <div className="ticketchatspinner">
          <Spinner />
        </div>
      ) : (
        <Col
          lg={10}
          md={12}
          sm={12}
          className="bg-white rounded p-4 ms-xl-3 ms-lg-0 ms-md-0 ms-sm-0 ms-0"
        >
          <Row>
            <Col className="text-center">
              <h3 className="fs-20 fs-md-16 roboto-bold">
                {" "}
                {lang?.course_purch_user}
              </h3>
            </Col>
          </Row>
          {userData &&
            userData.map((value, index) => (
              <Fragment key={index}>
                <Profile
                  user_image={value?.image}
                  user_name={value?.name}
                  category={value?.category}
                  sub_category={value?.subcategory}
                />
              </Fragment>
            ))}

          {data?.current_page === data?.last_page ? (
            <></>
          ) : (
            <Row>
              <Col className="text-center">
                <button
                  disabled={isLoading}
                  className="btn-blue border-0 px-md-3 py-3"
                  onClick={() => {
                    handleNewPageData();
                  }}
                >
                  {isLoading ? <div className="loader"></div> : lang?.load_more}
                </button>
              </Col>
            </Row>
          )}
        </Col>
      )}
    </Row>
  );
};

export default PurchasedUser;

const Profile = (props) => {
  return (
    <div
      className="pb-2 rounded my-3 d-flex justify-content-between px-3 py-1 align-items-center"
      style={{
        border: "1px solid rgba(0,0,0,0.1)",
      }}
    >
      <div className="d-flex gap-3">
        <div className="align-self-center">
          <div
            className="bg-white p-1 rounded dropdown-avatar-shadow"
            style={{ minHeight: "5rem", minWidth: "5rem" }}
          >
            <img
              src={
                props?.user_image === ""
                  ? PlaceholderImg
                  : BaseURL + props?.user_image
              }
              style={{
                height: "5rem",
                width: "5rem",
                objectFit: "fill",
              }}
              className="rounded"
              alt="picture"
            />
          </div>
        </div>
        <div className="align-self-center">
          <h4 className="fs-18 fs-md-16 roboto-bold">{props?.user_name}</h4>
          <span
            className="fs-18 fs-md-16 roboto-regular"
            style={{ color: "#555555" }}
          >
            {props?.category}{" "}
            {`(${
              props?.sub_category && props.sub_category.length > 50
                ? `${props.sub_category.slice(0, 25)}...`
                : props.sub_category
            })`}
          </span>
        </div>
      </div>
      <div className="align-self-center text-center" style={{ opacity: "50%" }}>
        <img src={ArrowRight} alt="picture" />
      </div>
    </div>
  );
};
