import { useContext } from "react";
import arrow from "../../../assets/images/icon/arrow-right.png";
import { Language } from "../../../App";

const style = {
  width: "14px",
  height: "18px",
  opacity: "50%",
};

const TermPrivacy = () => {
  const { lang } = useContext(Language);
  return (
    <div className="br-8 pxy-20 bg-white w-xl-75 w-lg-100 box-shadow1 m-xl-0 m-lg-auto m-md-auto">
      <h3 className="d-flex justify-content-center mt--12 fs-20 fs-md-16 fw-bold">
        {lang?.terms_privacy}
      </h3>
      <div className="d-flex flex-column gap-4 mt-4">
        <a href="https://medicalradar.es/privacy-policy" target="_blank">
          <div className="box-shadow1 d-flex justify-content-between align-items-center p-16 rounded ps-4 pe-4 manue-hover">
            <h4 className="fs-18 fs-md-15 fw-bold m-0 text-dark">
              {lang?.privacy_policy}
            </h4>
            <span>
              <img src={arrow} alt="picture" style={style} />
            </span>
          </div>
        </a>

        <a href="https://medicalradar.es/terms-and-conditions" target="_blank">
          <div className="box-shadow1 d-flex justify-content-between align-items-center p-16 rounded ps-4 pe-4 manue-hover">
            <h4 className="fs-18 fs-md-15 fw-bold m-0 text-dark">
              {lang?.terms_conditions}
            </h4>
            <span>
              <img src={arrow} alt="picture" style={style} />
            </span>
          </div>
        </a>
        <a href="https://medicalradar.es/eula" target="_blank">
          <div className="box-shadow1 d-flex justify-content-between align-items-center p-16 rounded ps-4 pe-4 manue-hover">
            <h4 className="fs-18 fs-md-15 fw-bold m-0 text-dark">
              {lang?.eula_medical_radar}
            </h4>
            <span>
              <img src={arrow} alt="picture" style={style} />
            </span>
          </div>
        </a>
        <a href="https://medicalradar.es/disclaimer" target="_blank">
          <div className="box-shadow1 d-flex justify-content-between align-items-center p-16 rounded ps-4 pe-4 manue-hover">
            <h4 className="fs-18 fs-md-15 fw-bold m-0 text-dark">
              {lang?.disclaimer_medical_radar}
            </h4>
            <span>
              <img src={arrow} alt="picture" style={style} />
            </span>
          </div>
        </a>
      </div>
    </div>
  );
};

export default TermPrivacy;
