import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import NavBar from "../NavBar";
import axios from "axios";
import {
  AllJobsApi,
  BaseURL,
  DeleteJobApi,
  EditJobApi,
  ReportJobApi,
  bookmarkJobApi,
} from "../../constants/API";
import CreateNewJob from "../AllJobs/CreateNewJob";
import { Link, useNavigate, useParams } from "react-router-dom";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { JOBS, PROFILE } from "../../constants/RoutesUrl";
import SpecSpinner from "../manage_account/changeInterest/SpecSpinner";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { GOOGLE_KEY } from "../../constants/Keys";
import { TiCancel } from "react-icons/ti";
import { UnixToRelative } from "../notification/Notification";
import { IoIosArrowForward } from "react-icons/io";
import { MdLocationOn } from "react-icons/md";
import { MdOutlineSubtitles } from "react-icons/md";
import { MdOutlineDescription } from "react-icons/md";
import { FaHospitalAlt } from "react-icons/fa";
import ImageComponent from "../common/ImageComponent";
import JobsPreview from "./JobsPreview";
import { toast } from "react-toastify";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { CiCircleRemove } from "react-icons/ci";
import { Language } from "../../App";

function JobDetails(props) {
  const [tabs, setTabs] = useState(1);
  const navigate = useNavigate("");

  const { id } = useParams();
  const [jobData, setJobData] = useState({});
  const [reportData, setReportData] = useState("");
  const localData = JSON.parse(sessionStorage.getItem("SplashData"));
  const login_id = localData?.user?.id;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [isreportLoading, setIsReportLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isReportButtonDisabled, setIsReportButtonDisabled] = useState(true);
  const screenWidth = window.innerWidth;
  const [openSidebar, setOpenSidebar] = useState(
    screenWidth < 1200 ? false : true
  );

  // Define state variables to hold the updated data
  const [updatedData, setUpdatedData] = useState({
    title: "",
    location: "",
    short_location: "",
    hospital_name: "",
    description: "",
  });

  //google map states
  const [locationModal, setLocationModal] = useState(false);
  const [mapCenter, setMapCenter] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [formData, setFormData] = useState({});
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const { lang } = useContext(Language);

  const handleReportTextareaChange = (e) => {
    setReportData(e.target.value);

    // Enable or disable the button based on whether the textarea has content
    setIsReportButtonDisabled(!e.target.value.trim());
  };

  // Google Map
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_KEY,
  });

  // Map click
  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setLat(lat);
    setLng(lng);
    setMarkerPosition({ lat, lng });
    reverseGeocode(lat, lng);
  };

  // Location Lat_Lng
  const handleLocation = () => {
    if (formData?.lat_lng || markerPosition) {
      setLocationModal(true);
      return;
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          console.log({ latitude, longitude });
          setMapCenter({ lat: latitude, lng: longitude });
          setMarkerPosition({ lat: latitude, lng: longitude });
        },
        () => {}
      );
      setTimeout(() => {
        setLocationModal(true);
      }, 500);
    } else {
      toast.error(lang?.geolocation_not_suported);
    }
  };

  // To get city and country using lat lng
  const reverseGeocode = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = new window.google.maps.LatLng(lat, lng);

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results[0]) {
        const addressComponents = results[0].address_components;
        let cityName = "";
        let countryName = "";

        for (const component of addressComponents) {
          if (component.types.includes("locality")) {
            cityName = component.long_name;
          } else if (component.types.includes("country")) {
            countryName = component.long_name;
          }
        }

        setCity(cityName);
        setCountry(countryName);

        // // Update the location in the updatedData
        // setUpdatedData({
        //   ...updatedData,
        //   location: `${cityName}, ${countryName}`,
        // });
      } else {
        setCity("");
        setCountry("");
      }
    });
  };

  // Marker drag
  const handleMarkerDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });
    reverseGeocode(lat, lng);

    // Set the city and country in the updatedData
    setUpdatedData({
      ...updatedData,
      location: `${city} ${country}`, //.
    });
  };

  const [isJobSaved, setIsJobSaved] = useState(() => {
    // Get the saved job state from local storage or default to false
    const savedState = sessionStorage.getItem(`jobSavedState_${id}`);
    return savedState ? JSON.parse(savedState) : false;
  });

  const toggleEditModal = () => {
    setUpdatedData({
      title: jobData.title,
      location: jobData.location,
      description: jobData.description,
      short_location: jobData.short_location,
      hospital_name: jobData.hospital_name,
    });
    setIsEditModalOpen(!isEditModalOpen);
  };

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const toggleReportModal = () => {
    setIsReportModalOpen(!isReportModalOpen);
  };

  const handleDeleteJob = () => {
    setIsLoadingDelete(true);
    axios
      .get(DeleteJobApi + id)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setIsLoadingDelete(false);
          toast.success(res.data.action);
          navigate(JOBS);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingDelete(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(AllJobsApi + login_id)
      .then((res) => {
        res.data.data.data.map((value) => {
          if (value.id == id) {
            setJobData(value);
            setIsLoading(false);
          }
        });
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  const handleToggleSaveJob = () => {
    const saveOrUnsaveJob = () => {
      if (isJobSaved) {
        axios
          .get(bookmarkJobApi + id + "/" + login_id)
          .then((res) => {
            if (res.data.status) {
              // toast.success(res.data.action);
              setIsJobSaved(false);
              sessionStorage.setItem(
                `jobSavedState_${id}`,
                JSON.stringify(false)
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // Send a request to save the job and handle success/failure
        axios
          .get(bookmarkJobApi + id + "/" + login_id)
          .then((res) => {
            if (res.data.status) {
              // toast.success(res.data.action);
              setIsJobSaved(true);
              sessionStorage.setItem(
                `jobSavedState_${id}`,
                JSON.stringify(true)
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };

    saveOrUnsaveJob();
  };

  // report
  const handleReport = () => {
    setIsReportLoading(true);
    axios
      .post(ReportJobApi, {
        report_id: id,
        user_id: login_id,
        report: reportData,
        type: "job",
      })
      .then((res) => {
        if (res.data.status) {
          setIsReportLoading(false);
          toast.success(res.data.action);
          toggleReportModal();
        } else {
          toast.error(lang?.report_action_failed);
          setIsReportLoading(false);
        }
      })
      .catch((err) => {
        toast.error(lang?.error_making_report + err);
        setIsReportLoading(false);
      });
  };

  // editjob
  const handleUpdatejob = () => {
    setIsLoadingUpdate(true);
    axios
      .post(EditJobApi, {
        userId: login_id,
        job_id: id,
        title: updatedData.title,
        hospital_name: updatedData.hospital_name,
        location: `${city} ${country}`, //.
        lat_lng: `${lat}, ${lng}`,
        short_location: updatedData.short_location,
        description: updatedData.description,
      })
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.action);
          setIsLoadingUpdate(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        toast.error(lang?.please_enter_correct_data, err);
        setIsLoadingUpdate(false);
      });
  };

  // Check if jobData.user is defined before accessing its properties
  const publisherName = jobData.user ? jobData.user.name : "Unknown";
  const publisherUsername = jobData.user ? jobData.user.user_name : "unknown";
  const publisherimage = jobData.user ? BaseURL + jobData.user.image : Avatar;

  // Check if login_id and jobData.user_id are the same
  const areIdsEqual = login_id === jobData.user_id;

  const Apidate = new Date(jobData.time * 1000);
  const formattedTime = Apidate.toLocaleString();

  return (
    <Container fluid className="bg-offwhite vh-100 overflowY-hidden">
      <Row>
        <NavBar />
      </Row>
      <Row className="px-lg-5 px-md-4 px-sm-2 px-2">
        <Col xl={3} className="overflowY-auto scroll-hide mt-3">
          <div className="bg-white border rounded jobs-side-shadow overflow-hidden">
            <div
              onClick={() => setOpenSidebar(!openSidebar)}
              className="p-3 d-flex justify-content-between align-items-center cursor"
            >
              <h1 className="fs-20 fs-md-16 m-0 roboto-bold">
                {lang?.medical_jobs}
              </h1>
              <span className="d-xl-none m-0">
                {openSidebar ? (
                  <CiCircleRemove size={22} />
                ) : (
                  <MdOutlineKeyboardArrowRight size={26} />
                )}
              </span>
            </div>
            <Collapse isOpen={openSidebar}>
              {isLoading ? (
                <div className="mt-3 p-2"></div>
              ) : (
                <div
                  className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                    tabs === 1 && "active"
                  }`}
                >
                  <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                    {lang?.details}
                  </h1>
                </div>
              )}

              {areIdsEqual ? (
                <>
                  {!isLoading && (
                    <>
                      <div
                        className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                          tabs === 2 && "active"
                        }`}
                        onClick={toggleEditModal}
                      >
                        <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                          {lang?.edit_job}
                        </h1>
                      </div>

                      <div
                        className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                          tabs === 3 && "active"
                        }`}
                        onClick={toggleDeleteModal}
                      >
                        <h1
                          className="fs-18 fs-md-16 m-0 roboto-medium"
                          style={{ color: "red" }}
                        >
                          {lang?.delete_job}
                        </h1>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div>
                  {!isLoading && (
                    <>
                      <div
                        className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                          tabs === 4 && "active"
                        }`}
                        onClick={handleToggleSaveJob}
                      >
                        <h1 className="fs-18 fs-md-16 m-0 roboto-medium">
                          {isJobSaved ? lang?.unsave_job : lang?.save_job}
                        </h1>
                      </div>

                      <div
                        className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                          tabs === 4 && "active"
                        }`}
                        onClick={toggleReportModal}
                      >
                        <h1
                          className="fs-18 fs-md-16 m-0 roboto-medium"
                          style={{ color: "red" }}
                        >
                          {lang?.report_job}
                        </h1>
                      </div>
                    </>
                  )}
                </div>
              )}
            </Collapse>
          </div>
        </Col>
        <Col
          xl={9}
          lg={12}
          md={12}
          sm={12}
          className="overflowY-auto vh-100 pb-15-rem mt-3 scroll-hide"
        >
          {tabs === 1 ? (
            <>
              <div>
                <h1 className="fs-20 fs-md-16 roboto-bold">
                  {lang?.job_detail}
                </h1>
              </div>
              <>
                {isLoading ? (
                  <div className="mt-3 p-2">
                    <SpecSpinner />
                  </div>
                ) : (
                  <>
                    <JobsPreview
                      id={jobData.id}
                      user_id={jobData.user_id}
                      job={jobData?.title}
                      hospital_name={jobData?.hospital_name}
                      location={jobData?.short_location}
                      date={jobData?.time}
                      publisher_name={jobData?.user?.name}
                      publisher_username={jobData?.user?.user_name}
                      publisher_img={jobData?.user?.image}
                    />

                    <div className="mt-3">
                      <h5 className="fs-18 fs-md-16 roboto-medium">
                        {lang?.job_description}:
                      </h5>
                      <p
                        className="fs-16 roboto-regular"
                        style={{ color: "#434343" }}
                      >
                        {jobData?.description}
                      </p>
                    </div>
                  </>
                )}
              </>
            </>
          ) : tabs === 2 ? (
            <>
              <div>
                <h1 className="fs-20 fs-md-16 roboto-bold">{lang?.my_jobs}</h1>
              </div>
            </>
          ) : tabs === 3 ? (
            <>
              <div>
                <h1 className="fs-20 fs-md-16 roboto-bold">
                  {lang?.saved_jobs}
                </h1>
              </div>
            </>
          ) : (
            tabs === 4 && (
              <>
                <div>
                  <CreateNewJob />
                </div>
              </>
            )
          )}
        </Col>
      </Row>
      {/* delete modal */}
      <Modal
        centered
        zIndex={9}
        isOpen={isDeleteModalOpen}
        toggle={toggleDeleteModal}
      >
        <ModalHeader toggle={toggleDeleteModal}>{lang?.delete_job}</ModalHeader>
        <ModalBody>
          {/* Add the content for your delete confirmation message here */}
          {lang?.delete_job_para}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleDeleteModal}>
            {lang?.cancel}
          </Button>

          <Button
            color="secondary"
            onClick={handleDeleteJob}
            type="button"
            className="btn-delete-red"
          >
            {isLoadingDelete ? <Spinner /> : lang?.confirm_delete}
          </Button>
        </ModalFooter>
      </Modal>

      {/* report Job modal */}
      <Modal
        centered
        zIndex={9}
        isOpen={isReportModalOpen}
        toggle={toggleReportModal}
      >
        <ModalHeader>{lang?.report_this_job}</ModalHeader>
        <ModalBody>
          <div>
            <h3 className="fs-16">{lang?.report_this_user_para}</h3>
            <form className="w-100">
              <div className="mt-3">
                <textarea
                  cols="30"
                  rows="7"
                  className="form-control fs-16 w-100"
                  placeholder={lang?.enter_your_report}
                  onChange={handleReportTextareaChange}
                ></textarea>
              </div>
              <div className="justify-content-end gap-3 d-flex mt-3">
                <div
                  className="btn manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
                  onClick={toggleReportModal}
                >
                  {lang?.cancel}
                </div>
                <button
                  type="button"
                  className="btn-blue border-0 rounded w-100 py-2 fs-md-14"
                  onClick={handleReport}
                  disabled={isReportButtonDisabled}
                >
                  {isreportLoading ? <Spinner /> : lang?.submit_report}
                </button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>

      {/* Edit Job modal */}
      <Modal
        size="lg"
        centered
        zIndex={9}
        isOpen={isEditModalOpen}
        toggle={toggleEditModal}
      >
        <ModalHeader toggle={toggleEditModal}>{lang?.edit_job}</ModalHeader>
        <ModalBody>
          <div className="edit-profile br-8 pxy-20 bg-white  m-xl-0 m-lg-auto m-md-auto">
            <form className="w-75 m-auto">
              <div className="d-flex flex-column gap-4">
                <div>
                  <h6 className="fs-16 fw-bold  opacity-75">
                    {" "}
                    <MdOutlineSubtitles color="#00C5DE" size={22} />{" "}
                    {lang?.title}
                  </h6>
                  <input
                    placeholder={lang?.job_title}
                    type="text"
                    value={updatedData.title}
                    onChange={(e) =>
                      setUpdatedData({ ...updatedData, title: e.target.value })
                    }
                  />
                </div>

                <div>
                  <h6 className="fs-16 fw-bold opacity-75 ">
                    {" "}
                    <FaHospitalAlt color="#00C5DE" size={20} />{" "}
                    {lang?.hospital_name}
                  </h6>
                  <input
                    placeholder={lang?.hospital_name}
                    type="text"
                    value={updatedData.hospital_name}
                    onChange={(e) =>
                      setUpdatedData({
                        ...updatedData,
                        hospital_name: e.target.value,
                      })
                    }
                  />
                </div>

                <div>
                  <h6 className="fs-16 fw-bold opacity-75 ">
                    <MdLocationOn color="#00C5DE" size={22} /> {lang?.location}
                  </h6>
                  {/* <input
                    type="text"
                    value={updatedData.location}
                    onChange={(e) =>
                      setUpdatedData({
                        ...updatedData,
                        location: e.target.value,
                      })
                    }
                  /> */}
                  <div className="position-relative">
                    <input
                      onClick={handleLocation}
                      type="text"
                      className="py-2 form-control"
                      placeholder={lang?.city_country}
                      value={
                        formData?.lat_lng && city && country
                          ? `${city} ${country}` //.
                          : updatedData.location
                      }
                    />
                    {city !== "" && country !== "" && (
                      <div
                        className="position-absolute end-0 top-0 me-2"
                        style={{ marginTop: "13px" }}
                      >
                        <h6
                          className="text-primary cursor"
                          onClick={() => {
                            setFormData({
                              ...formData,
                              lat_lng: "",
                            });
                            setCity("");
                            setCountry("");
                          }}
                        >
                          <TiCancel size={18} />
                          {lang?.remove}
                        </h6>
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <h6 className="fs-16 fw-bold opacity-75 ">
                    <MdLocationOn color="#00C5DE" size={22} />
                    {lang?.complete_adress}
                  </h6>
                  <input
                    type="text"
                    placeholder={lang?.complete_adress}
                    value={updatedData.short_location}
                    onChange={(e) =>
                      setUpdatedData({
                        ...updatedData,
                        short_location: e.target.value,
                      })
                    }
                  />
                </div>

                <div>
                  <h6 className="fs-16 fw-bold opacity-75">
                    <MdOutlineDescription color="#00C5DE" size={22} />{" "}
                    {lang?.job_description}
                  </h6>
                  <textarea
                    placeholder={lang?.description}
                    cols="30"
                    rows="10"
                    value={updatedData.description}
                    onChange={(e) =>
                      setUpdatedData({
                        ...updatedData,
                        description: e.target.value,
                      })
                    }
                  ></textarea>
                </div>

                <button
                  type="button"
                  className="bg-blue border-0 p-3 rounded fs-16 fw-bold text-white mb-4"
                  onClick={handleUpdatejob}
                >
                  {isLoadingUpdate ? <Spinner /> : lang?.update}
                </button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>

      {/* Google map modal */}
      <Modal isOpen={locationModal} centered zIndex={9} size="lg">
        <ModalHeader toggle={() => setLocationModal(false)}></ModalHeader>
        {loadError && "Error loading maps"}
        {!isLoaded && "Loading Maps"}
        <div style={{ height: "50vh" }}>
          <GoogleMap
            mapContainerClassName="w-100 h-100"
            center={mapCenter}
            zoom={15}
            onClick={handleMapClick}
          >
            {markerPosition && (
              <Marker
                position={markerPosition}
                draggable={true}
                title={JSON.stringify(markerPosition)}
                icon={{
                  url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                }}
                onDragEnd={handleMarkerDragEnd}
              />
            )}
          </GoogleMap>
        </div>
        <ModalFooter className="gap-4">
          <button
            className="btn btn-secondary py-2 px-4"
            onClick={() => {
              setLocationModal(false);
            }}
          >
            {lang?.cancel}
          </button>
          <button
            className="btn-blue border-0 px-4 py-2"
            onClick={() => {
              setFormData({ ...formData, lat_lng: markerPosition });
              setLocationModal(false);
              const lat = markerPosition.lat;
              const lng = markerPosition.lng;
              reverseGeocode(lat, lng);
            }}
          >
            {lang?.save}
          </button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}

export default JobDetails;
