import React, { Fragment, useContext, useEffect, useState } from "react";
import NavBar from "../NavBar";
import axios from "axios";
import { MyEventsApi } from "../../constants/API";
import Spinner from "../Spinner";
import EventPreview from "./EventPreview";
import { Language } from "../../App";

function ProfileEvents() {
  const { lang } = useContext(Language);
  const localData = JSON.parse(sessionStorage.getItem("profileData"));
  const user_id = localData?.user?.id;
  const [eventsData, setEventsData] = useState([]);
  const [spin, setSpin] = useState(false);
  const [status, setStatus] = useState("0");
  const handleEvents = () => {
    setSpin(true);
    axios
      .get(MyEventsApi + user_id + `/${status}`)
      .then((res) => {
        setEventsData(res.data.data);
        setSpin(false);
      })
      .catch((error) => {
        console.log(error);
        setSpin(false);
      });
  };
  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    handleEvents(formattedDate);
  }, [status]);
  return (
    <div >
      <div className="container-fluid ">
        <div className="row">
          <NavBar />
        </div>
        <div className="profilejobs">
          <div className="row  job-scroll  scroll-hide   ">
            <div className="col-md-6  mt-3 card">
              <>
                <div>
                  <h1 className="fs-20 fs-md-16 roboto-medium text-center my-3">{lang?.events}</h1>
                </div>
                <div className="d-flex mb-5">
                  <button
                    onClick={() => {
                      setStatus("0");
                    }}
                    className={`w-50 fs-20 fs-md-16 br-tl-12 br-bl-12 py-3 b-1-gray ${
                      status === "0"
                        ? "text-white bg-blue"
                        : "bg-white text-black"
                    }`}
                  >
                    {lang?.upcoming}
                  </button>
                  <button
                    onClick={() => {
                      setStatus("1");
                    }}
                    className={`w-50 fs-20 fs-md-16 br-tr-12 br-br-12 py-3 b-1-gray ${
                      status === "1"
                        ? "text-white bg-blue"
                        : "bg-white text-black"
                    }`}
                  >
                    {lang?.completed}
                  </button>
                </div>

                {spin ? (
                  <Spinner />
                ) : (
                  eventsData?.map((value, index) => {
                    return (
                      <>
                        <div key={index} className="my-4">
                          <h2 className="fs-20 fs-md-16 roboto-regular">
                            {formatDate(value.date)}
                          </h2>
                        </div>
                        <div
                          style={{ overflowX: "auto", overflowY: "hidden" }}
                          className="d-flex gap-2"
                        >
                          {value.data &&
                            value?.data.map((event) => (
                              <Fragment key={event.id}>
                                <EventPreview
                                  id={event.id}
                                  image={event.cover}
                                  title={event.title}
                                  location={event.short_location}
                                  time={event.start_timestamp}
                                  available={event.availability}
                                />
                              </Fragment>
                            ))}
                        </div>
                      </>
                    );
                  })
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileEvents;

function formatDate(dateString) {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
}
