import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Collapse } from "reactstrap";
import UploadPost from "../UploadPost";
import UploadPoll from "../UploadPoll";
import { useParams } from "react-router-dom";
import AddForm from "../../pages/clinical_studies/AddForm";
import AddClinicalSession from "../create-post/AddClinicalSession";
import AddWebinar from "../create-post/AddWebinar";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { CiCircleRemove } from "react-icons/ci";
import { Language } from "../../App";

const Side = () => {
  const { id } = useParams();
  const defaultTab = id ? parseInt(id, 10) : 1;
  const [tabs, setTabs] = useState(defaultTab);
  const screenWidth = window.innerWidth;
  const [openSidebar, setOpenSidebar] = useState(
    screenWidth < 1200 ? false : true
  );
  const { lang } = useContext(Language);

  const tabsInfo = [
    { id: 1, label: lang?.create_a_post, component: <UploadPost /> },
    { id: 2, label: lang?.create_a_poll, component: <UploadPoll /> },
    { id: 3, label: lang?.create_a_webinar, component: <AddWebinar /> },
    { id: 4, label: lang?.create_a_clinical_study, component: <AddForm /> },
    {
      id: 5,
      label: lang?.create_a_clinical_session,
      component: <AddClinicalSession />,
    },
  ];

  const selectedTabComponent =
    tabsInfo.find((tab) => tab.id === tabs)?.component || null;

  return (
    <>
      <div>
        <Row className="px-lg-5 px-md-4 px-sm-2 px-2">
          <Col xl={3} className="mt-3">
            <div className="bg-white border rounded jobs-side-shadow overflow-hidden">
              <div
                onClick={() => setOpenSidebar(!openSidebar)}
                className="p-3 d-flex justify-content-between align-items-center cursor"
              >
                <h1 className="fs-20 fs-md-16 m-0 roboto-bold">
                  {lang?.create_post}
                </h1>
                <span className="d-xl-none">
                  {openSidebar ? (
                    <CiCircleRemove size={22} />
                  ) : (
                    <MdOutlineKeyboardArrowRight size={26} />
                  )}
                </span>
              </div>
              <Collapse isOpen={openSidebar}>
                {tabsInfo.map(({ id, label }) => (
                  <div
                    key={id}
                    className={`p-3 ps-4 border-top cursor manue-hover hover-blue ${
                      tabs === id && "active"
                    }`}
                    onClick={() => {
                      if (screenWidth < 1200) {
                        setTabs(id);
                        setOpenSidebar(!openSidebar);
                      } else {
                        setTabs(id);
                      }
                    }}
                  >
                    <h1 className="fs-18 fs-sm-16 m-0 roboto-medium">
                      {label}
                    </h1>
                  </div>
                ))}
              </Collapse>
            </div>
          </Col>
          <Col
            xl={9}
            className="overflowY-auto scroll-hide vh-100 pb-15-rem pt-3"
          >
            {selectedTabComponent}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Side;
