import React from "react";
import { Col, Container, Row } from "reactstrap";
import Post from "../../components/posts-details/Post";

const PostsDetails = () => {
  return (
    <>
      <Container fluid>
        <div
          className="vh-100 bg-offwhite space-top overflowY-auto scroll-hide overflowX-hidden"
          style={{ paddingBottom: "3rem" }}
        >
          <Row className="justify-content-center mt-3 mb-5">
            <Col lg={6} style={{ maxWidth: "579px" }} className="p-0">
              <Post />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default PostsDetails;
