import React, { useContext } from "react";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { BaseURL } from "../../constants/API";
import { Link } from "react-router-dom";
import { JOBDETAIL, PROFILE } from "../../constants/RoutesUrl";
import { UnixToRelative } from "../notification/Notification";
import ImageComponent from "../common/ImageComponent";
import { Language } from "../../App";

const JobsPreview = (props) => {
  const { lang } = useContext(Language);
  const {
    user_id,
    job,
    hospital_name,
    location,
    date,
    description,
    publisher_name,
    publisher_username,
    publisher_img,
  } = props;

  const Apidate = new Date(date * 1000);
  const formattedTime = Apidate.toLocaleString();
  return (
    <>
      <Link to={`${JOBDETAIL}/${props.id}`}>
        <div
          className="bg-white rounded border jobs-preview-shadow mt-3 text-dark"
          key={props.id}
        >
          <div className="px-3 pt-3">
            <div>
              <h2 className="fs-20 fs-sm-16 roboto-medium">{job}</h2>
            </div>
            <div>
              <p className="fs-14 roboto-medium" style={{ color: "#2278EF" }}>
                {hospital_name}
              </p>
            </div>
            <div>
              <p className="fs-16 roboto-medium" style={{ color: "#434343" }}>
                {description}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <p className="fs-14 roboto-regular">{location}</p>
              </div>
              <div>
                <p className="fs-16 roboto-regular text-gray">
                  <UnixToRelative unixTimestamp={date} />
                </p>
              </div>
            </div>
          </div>
          <hr className="m-0" />
          <div className="p-3">
            <div>
              <h3 className="fs-16 roboto-medium">{lang?.published_by}:</h3>
            </div>
            <Link
              to={PROFILE + "/" + user_id}
              style={{ width: "fit-content" }}
              className="gap-3 d-flex py-2 hover-blue"
            >
              <div>
                <div
                  className="bg-white dropdown-avatar-shadow rounded-3 align-self-center"
                  style={{
                    padding: "2px",
                    height: "60px",
                    width: "60px",
                  }}
                >
                  <ImageComponent
                    src={publisher_img ? BaseURL + publisher_img : Avatar}
                    img_height="100%"
                    img_width="100%"
                    loader_height="100%"
                    loader_width="100%"
                    compClassName="rounded-3"
                  />
                </div>
              </div>
              <div className="align-self-center">
                <h4 className="fs-14 mb-0 roboto-medium">{publisher_name}</h4>
                <span className="fs-12 roboto-regular">
                  @{publisher_username}
                </span>
              </div>
            </Link>
          </div>
        </div>
      </Link>
    </>
  );
};

export default JobsPreview;
