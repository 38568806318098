import React from "react";
import { Col, Row } from "reactstrap";
import SpinnerGif from "../assets/images/Spinner.gif";

const Spinner = () => {
  return (
    <Row>
      <Col xl={5} className="text-end pe-5">
        <img src={SpinnerGif} alt="picture" width={70} height={70} />
      </Col>
    </Row>
  );
};

export default Spinner;
