import React, { useCallback, useContext, useState } from "react";
import { Col, Form, Modal, ModalFooter, ModalHeader, Row } from "reactstrap";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../hooks/useCropImage";
import { AddEventApi, AddEventQuestionApi, BaseURL } from "../../constants/API";
import UploadIcon from "../../assets/images/icon/upload.png";
import axios from "axios";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { GOOGLE_KEY } from "../../constants/Keys";
import { parse, getTime } from "date-fns";
import { TiCancel } from "react-icons/ti";
import { Language } from "../../App";
import { toast } from "react-toastify";

function AddEvent() {
  // Google Map
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_KEY,
  });

  // sessionStorage
  const SplashData = JSON.parse(sessionStorage.getItem("SplashData"));
  const user_id = SplashData?.user?.id;

  // States
  const [formData, setFormData] = useState({});
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [eventImagePreview, setEventImagePreview] = useState(null);
  const [eventInput, setEventInput] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageModal, setImageModal] = useState(false);
  const [imagePreviewModal, setImagePreviewModal] = useState(false);
  const [questionsModal, setQuestionsModal] = useState(false);
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [questionsData, setQuestionsData] = useState([
    { question: "", answers: [""] },
  ]);
  const [optionsModal, setOptionsModal] = useState(false);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [locationModal, setLocationModal] = useState(false);
  const { lang } = useContext(Language);
  const [timeInput, setTimeInput] = useState({
    hr_start: "",
    min_start: "",
    median_start: "",
    hr_end: "",
    min_end: "",
    median_end: "",
  });

  const [mapCenter, setMapCenter] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);

  const HH = [];
  for (let i = 1; i <= 12; i++) {
    const num = i > 9 ? `${i}` : `0${i}`;
    HH.push(num);
  }
  const MM = [];
  for (let i = 1; i <= 60; i++) {
    const num = i > 9 ? `${i}` : `0${i}`;
    MM.push(num);
  }

  // Render the options input fields for the selected question
  const renderOptionsInputFields = () => {
    if (selectedQuestionIndex !== null) {
      const options = questionsData[selectedQuestionIndex].answers;
      return (
        options &&
        options.map((option, optionIndex) => (
          <Row key={optionIndex}>
            <Col>
              <Row>
                <Col>
                  <label className="pb-1 fs-15 roboto-medium">
                    {lang?.option}: {optionIndex + 1}
                  </label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <input
                    type="text"
                    className="py-2 form-control"
                    placeholder="Option"
                    value={option}
                    onChange={(e) => updateOption(optionIndex, e.target.value)}
                  />
                </Col>
                <Col md={1} className="align-self-center">
                  <button
                    className="rounded border-0 fw-bold px-2"
                    onClick={() =>
                      removeOption(selectedQuestionIndex, optionIndex)
                    }
                  >
                    x
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        ))
      );
    }
  };
  // Function to add an option to the selected question's options array
  const addOption = () => {
    if (selectedQuestionIndex !== null) {
      const updatedQuestionsData = [...questionsData];
      updatedQuestionsData[selectedQuestionIndex].answers.push("");
      setQuestionsData(updatedQuestionsData);
    }
  };
  // Function to remove a question by index
  const removeQuestion = (questionIndex) => {
    if (questionsData && questionsData.length === 1 && questionIndex === 0) {
      const updatedQuestionsData = [...questionsData];
      updatedQuestionsData.splice(questionIndex, 1);
      setQuestionsData(updatedQuestionsData);
      const newQuestion = { question: "", answers: [""] };
      updatedQuestionsData.push(newQuestion);
      setQuestionsData(updatedQuestionsData);
      return;
    }
    const updatedQuestionsData = [...questionsData];
    updatedQuestionsData.splice(questionIndex, 1);
    setQuestionsData(updatedQuestionsData);
  };
  // Function to remove an option by question index and option index
  const removeOption = (questionIndex, optionIndex) => {
    if (
      questionsData.answers &&
      questionsData.answers.length === 1 &&
      optionIndex === 0
    ) {
      const updatedQuestionsData = [...questionsData];
      updatedQuestionsData[questionIndex].answers.splice(optionIndex, 1);
      setQuestionsData(updatedQuestionsData);
      addOption();
      return;
    }
    const updatedQuestionsData = [...questionsData];
    updatedQuestionsData[questionIndex].answers.splice(optionIndex, 1);
    setQuestionsData(updatedQuestionsData);
  };
  // Function to update an option in the selected question's options array
  const updateOption = (optionIndex, optionText) => {
    if (selectedQuestionIndex !== null) {
      const updatedQuestionsData = [...questionsData];
      updatedQuestionsData[selectedQuestionIndex].answers[optionIndex] =
        optionText;
      setQuestionsData(updatedQuestionsData);
    }
  };
  // Functions that the cropper is using
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  // Funtion to crop cover image
  const handleCropper = async () => {
    try {
      const croppedImage = await getCroppedImg(
        eventImagePreview,
        croppedAreaPixels
      );
      // Replace the Blob URL with your actual Blob hanURL
      const blobUrl = croppedImage;
      // Fetch the Blob content
      fetch(blobUrl)
        .then((response) => response.blob())
        .then((blob) => {
          // Create a FileReader to read the Blob as base64
          const reader = new FileReader();
          reader.onload = () => {
            // The result will be the Blob content as base64
            const base64Data = reader.result;
            setCroppedImage(base64Data);
          };
          // Read the Blob as data URL
          reader.readAsDataURL(blob);
        })
        .catch((error) => console.error(error));
    } catch (e) {
      console.error(e);
    }
  };
  // Function to hit submit api
  const handleSubmit = () => {
    if (timeInput.hr_start || timeInput.min_start || timeInput.median_start) {
      if (timeInput.hr_start && timeInput.min_start && timeInput.median_start) {
        var StartTime = `${timeInput.hr_start}:${timeInput.min_start} ${timeInput.median_start}`;
      } else {
        toast.error(lang?.please_enter_event_start_time);
        return;
      }
    }

    if (timeInput.hr_end || timeInput.min_end || timeInput.median_end) {
      if (timeInput.hr_end && timeInput.min_end && timeInput.median_end) {
        var EndTime = `${timeInput.hr_end}:${timeInput.min_end} ${timeInput.median_end}`;
      } else {
        toast.error(lang?.please_enter_event_time);
        return;
      }
    }

    const numericRegex = /^[0-9]+$/;

    if (
      typeof formData.audience_limit !== "undefined" &&
      formData?.audience_limit !== "" &&
      !numericRegex.test(formData?.audience_limit.toString())
    ) {
      toast.error(lang?.audience_limit_should_numeric);
      return;
    }

    if (
      typeof formData.price !== "undefined" &&
      formData?.price !== "" &&
      !numericRegex.test(formData?.price.toString())
    ) {
      toast.error(lang?.price_should_numeric);
      return;
    }

    // creating UnixTimeStamp using Start Time & Start Date
    const StartTimeDate = `${formData?.start_date} ${
      StartTime ? StartTime : "12:01 AM"
    }`;
    const dateObject = parse(StartTimeDate, "yyyy-MM-dd hh:mm a", new Date());
    const StartunixTimestamp = getTime(dateObject) / 1000;

    // creating UnixTimeStamp using End Time & End Date
    const EndTimeDate = `${formData?.end_date} ${
      EndTime ? EndTime : "11:59 PM"
    }`;
    const enddateObject = parse(EndTimeDate, "yyyy-MM-dd hh:mm a", new Date());
    const EndunixTimestamp = getTime(enddateObject) / 1000;

    const currentDate = new Date();

    // Get the current date in YYYY-MM-DD format
    const currentYear = currentDate.getFullYear();
    const currentMonth = (currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0"); // Ensure 2-digit month
    const currentDay = currentDate.getDate().toString().padStart(2, "0"); // Ensure 2-digit day
    const currentDateString = `${currentYear}-${currentMonth}-${currentDay}`;

    if (formData?.start_date > formData?.end_date) {
      toast.error(lang?.start_date_should_greater_end_date);
      return;
    }

    if (formData?.start_date < currentDateString) {
      toast.error(lang?.start_date_cannot_less_today_date);
      return;
    }

    if (formData?.end_date < currentDateString) {
      toast.error(lang?.end_date_cannot_less_today_date);
      return;
    }

    const timeDifferenceInSeconds = EndunixTimestamp - StartunixTimestamp;
    const tenMinutesInSeconds = 10 * 60; // 10 minutes in seconds

    if (timeDifferenceInSeconds < tenMinutesInSeconds) {
      toast.error(lang?.there_should_least_minute_betwen_s_time_e_time);
      return;
    }

    const urlRegex = /^(http|https):\/\/[^ "]+$/;

    if (formData?.website && !urlRegex.test(formData?.website)) {
      toast.error(lang?.website_url_note_valid);
      return;
    }

    if (
      formData?.registration_link &&
      !urlRegex.test(formData?.registration_link)
    ) {
      toast.error(lang?.registration_link_url_not_valid);
      return;
    }

    if (formData?.password && formData?.password.length < 6) {
      toast.error(lang?.password_should_min_characters);
      return;
    }

    const data = {
      cover_base64: croppedImage,
      start_timestamp: StartunixTimestamp,
      end_timestamp: EndunixTimestamp,
      user_id,
      title: formData?.title,
      organizer: formData?.organizer_name,
      description: formData?.description,
      date: formData?.start_date,
      time: StartTime ? StartTime : "",
      availability: formData?.availability,
      location: formData?.lat_lng ? `${city}, ${country}` : "",
      short_location: formData?.address ? formData?.address : "",
      lat_lng: formData?.lat_lng
        ? `${formData?.lat_lng.lat},${formData?.lat_lng.lng}`
        : "",
      website: formData?.website ? formData?.website : "",
      link: formData?.registration_link ? formData?.registration_link : "",
      end_date: formData?.end_date,
      end_time: EndTime ? EndTime : "",
      password: formData?.password ? formData?.password : "",
      limit: formData?.audience_limit ? formData?.audience_limit : "",
      price: formData?.price ? formData?.price : "",
      tags: "@nnn",
      category_id: "3",
      subcategory_id: formData?.sub_category,
    };

    setIsLoading(true);
    axios
      .post(AddEventApi, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (questionsData && questionsData[0].question) {
          const questionData = {
            event_id: res.data.event_id,
            questions: questionsData,
          };
          axios
            .post(AddEventQuestionApi, questionData)
            .then((res) => {
              if (res.data.status) {
                setIsLoading(false);
                window.location.reload();
              } else {
                setIsLoading(false);
                toast.error("Error", res.data.action);
              }
              return;
            })
            .catch((error) => {
              toast.error("Error", error);
            });
        } else {
          setIsLoading(false);
          window.location.reload();
        }
      })
      .catch((error) => {
        toast.error(error);
        setIsLoading(false);
      });
  };
  // Location Lat_Lng
  const handleLocation = () => {
    if (formData?.lat_lng || markerPosition) {
      setLocationModal(true);
      return;
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          console.log({ latitude, longitude });
          setMapCenter({ lat: latitude, lng: longitude });
          setMarkerPosition({ lat: latitude, lng: longitude });
        },
        () => {}
      );
      setTimeout(() => {
        setLocationModal(true);
      }, 500);
    } else {
      toast.error(lang?.geolocation_not_suported);
    }
  };
  // Marker drag
  const handleMarkerDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });
    reverseGeocode(lat, lng);
  };
  // Map click
  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });
    reverseGeocode(lat, lng);
  };
  // To get city and country using lat lng
  const reverseGeocode = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = new window.google.maps.LatLng(lat, lng);

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results[0]) {
        const addressComponents = results[0].address_components;
        let cityName = "";
        let countryName = "";

        for (const component of addressComponents) {
          if (component.types.includes("locality")) {
            cityName = component.long_name;
          } else if (component.types.includes("country")) {
            countryName = component.long_name;
          }
        }

        setCity(cityName);
        setCountry(countryName);
      } else {
        setCity("");
        setCountry("");
      }
    });
  };
  return (
    <>
      <Row>
        <Col lg={7} className="bg-white p-4 ms-lg-5 ms-md-0 ms-sm-0 ms-0 br-12">
          <Row className="pb-3">
            <Col className="text-center">
              <h3 className="fs-20 fs-md-16 roboto-bold">{lang?.add_event}</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              {croppedImage ? (
                <div
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    minHeight: "15rem",
                    borderRadius: "6px",
                    backgroundColor: "rgba(0,0,0,0.70)",
                  }}
                >
                  <button
                    className="bg-white position-absolute end-0 me-3 mt-3 br-6 bg-white border-0 px-2 py-0 fs-16 roboto-bold"
                    style={{ zIndex: "1" }}
                    onClick={() => {
                      const update = { ...data, cover: "" };
                      setData(update);
                      setCroppedImage("");
                    }}
                  >
                    x
                  </button>
                  <img
                    src={croppedImage}
                    className="w-100 br-16"
                    style={{ objectFit: "cover", height: "15rem" }}
                    onClick={() => setImagePreviewModal(true)}
                    alt="picture"
                  />
                </div>
              ) : (
                <div
                  style={{
                    cursor: "pointer",
                    border: "1px dashed #A2A2A2",
                    backgroundColor: "#F2FCFE",
                    minHeight: "12rem",
                    borderRadius: "6px",
                  }}
                  onClick={() => {
                    document.getElementById("fileInput").click();
                  }}
                >
                  <div className="text-center mt-5">
                    <img src={UploadIcon} width={40} height={40} alt="picture" />
                    <h4
                      className="fs-14 roboto-medium pt-3"
                      style={{ color: "#00c5de" }}
                    >
                      {lang?.add_event_cover}
                    </h4>
                  </div>
                </div>
              )}
              <input
                type="file"
                id="fileInput"
                className="d-none"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  setEventInput(file);
                  // Preview the image
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    const update = { ...data, cover: reader.result };
                    setData(update);
                    setEventImagePreview(reader.result);
                    setImageModal(true);
                  };
                  reader.readAsDataURL(file);
                }}
              />
            </Col>
          </Row>
          <Form className="mt-3">
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.title}
                </label>
                <input
                  type="text"
                  className="py-2 form-control"
                  required
                  placeholder={lang?.event_title}
                  onChange={(e) => {
                    const update = { ...formData, title: e.target.value };
                    setFormData(update);
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.organizer_name}
                </label>
                <input
                  type="text"
                  className="py-2 form-control"
                  required
                  placeholder={lang?.organizer_name}
                  onChange={(e) => {
                    const update = {
                      ...formData,
                      organizer_name: e.target.value,
                    };
                    setFormData(update);
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.category}
                </label>
                <select
                  className="form-select py-2"
                  required
                  onChange={(e) => {
                    const update = {
                      ...formData,
                      sub_category: e.target.value,
                    };
                    setFormData(update);
                  }}
                >
                  <option disabled selected hidden>
                    {lang?.event_category}
                  </option>

                  {SplashData &&
                    SplashData.home &&
                    SplashData.home.subcategories &&
                    SplashData.home.subcategories.map((value, index) => (
                      <option value={value.id} key={index}>
                        {value.name}
                      </option>
                    ))}
                </select>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.start_date}
                </label>
                <input
                  type="date"
                  className="form-control py-2"
                  required
                  onChange={(e) => {
                    const update = { ...formData, start_date: e.target.value };
                    setFormData(update);
                  }}
                />
              </Col>
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.start_time}{" "}
                  <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                </label>
                <div className="d-flex">
                  <select
                    className="form-control rounded-0"
                    onChange={(e) => {
                      setTimeInput({ ...timeInput, hr_start: e.target.value });
                    }}
                  >
                    <option value="" selected>
                      --
                    </option>
                    {HH.map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                  <select
                    className="form-control rounded-0"
                    onChange={(e) => {
                      setTimeInput({ ...timeInput, min_start: e.target.value });
                    }}
                  >
                    <option value="" selected>
                      --
                    </option>
                    {MM.map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                  <select
                    className="form-control rounded-0"
                    onChange={(e) => {
                      setTimeInput({
                        ...timeInput,
                        median_start: e.target.value,
                      });
                    }}
                  >
                    <option value="" selected>
                      --
                    </option>
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.end_date}
                </label>
                <input
                  type="date"
                  required
                  className="form-control py-2"
                  onChange={(e) => {
                    const update = { ...formData, end_date: e.target.value };
                    setFormData(update);
                  }}
                />
              </Col>
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.end_time}{" "}
                  <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                </label>
                <div className="d-flex">
                  <select
                    className="form-control rounded-0"
                    onChange={(e) => {
                      setTimeInput({ ...timeInput, hr_end: e.target.value });
                    }}
                  >
                    <option value="" selected>
                      --
                    </option>
                    {HH.map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                  <select
                    className="form-control rounded-0"
                    onChange={(e) => {
                      setTimeInput({ ...timeInput, min_end: e.target.value });
                    }}
                  >
                    <option value="" selected>
                      --
                    </option>
                    {MM.map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                  <select
                    className="form-control rounded-0"
                    onChange={(e) => {
                      setTimeInput({
                        ...timeInput,
                        median_end: e.target.value,
                      });
                    }}
                  >
                    <option value="" selected>
                      --
                    </option>
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.location}{" "}
                  <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                </label>
                <div className="position-relative">
                  <input
                    onClick={handleLocation}
                    type="text"
                    className="py-2 form-control"
                    placeholder="City, Country"
                    value={
                      formData?.lat_lng && city && country
                        ? `${city}, ${country}`
                        : ""
                    }
                  />
                  {city !== "" && country !== "" && (
                    <div
                      className="position-absolute end-0 top-0 me-2"
                      style={{ marginTop: "13px" }}
                    >
                      <h6
                        className="text-primary cursor"
                        onClick={() => {
                          setFormData({
                            ...formData,
                            lat_lng: "",
                          });
                          setCity("");
                          setCountry("");
                        }}
                      >
                        <TiCancel size={18} />
                        {lang?.remove}
                      </h6>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.address}{" "}
                  <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                </label>
                <input
                  type="text"
                  className="py-2 form-control"
                  placeholder="Address"
                  onChange={(e) => {
                    const update = { ...formData, address: e.target.value };
                    setFormData(update);
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.website}{" "}
                  <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                </label>
                <input
                  type="url"
                  className="py-2 form-control"
                  onChange={(e) => {
                    const update = { ...formData, website: e.target.value };
                    setFormData(update);
                  }}
                  placeholder={lang?.website}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.registration_link}{" "}
                  <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                </label>
                <input
                  type="url"
                  className="py-2 form-control"
                  placeholder={lang?.registration_link}
                  onChange={(e) => {
                    const update = {
                      ...formData,
                      registration_link: e.target.value,
                    };
                    setFormData(update);
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.availablity}
                </label>
                <select
                  required
                  className="form-select py-2"
                  onChange={(e) => {
                    const update = {
                      ...formData,
                      availability: e.target.value,
                    };
                    setFormData(update);
                  }}
                >
                  <option selected hidden value="">
                    {lang?.choose_availablity}
                  </option>
                  <option value="online">{lang?.online}</option>
                  <option value="offline">{lang?.offline}</option>
                  <option value="online-offline">{lang?.online_offline}</option>
                </select>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.audience_limit}{" "}
                  <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="py-2 form-control"
                  placeholder={lang?.audience_limit}
                  value={formData?.audience_limit}
                  onChange={(e) => {
                    const update = {
                      ...formData,
                      audience_limit: e.target.value,
                    };
                    setFormData(update);
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.set_password}{" "}
                  <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                </label>
                <input
                  autoComplete="off"
                  type="password"
                  value={formData?.password}
                  className="py-2 form-control"
                  placeholder={lang?.set_password}
                  onChange={(e) => {
                    const update = { ...formData, password: e.target.value };
                    setFormData(update);
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.event_price}{" "}
                  <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                </label>
                <input
                  type="text"
                  className="py-2 form-control"
                  placeholder={lang?.event_price}
                  onChange={(e) => {
                    const update = { ...formData, price: e.target.value };
                    setFormData(update);
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.description}
                </label>
                <textarea
                  className="form-control resize"
                  required
                  rows="5"
                  placeholder={lang?.description}
                  onChange={(e) => {
                    const update = { ...formData, description: e.target.value };
                    setFormData(update);
                  }}
                ></textarea>
              </Col>
            </Row>

            <hr style={{ opacity: "0.2" }} />
            <Row className="mb-3 py-3 justify-content-between">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.create_form}{" "}
                  <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                </label>
                <div>
                  <button
                    type="button"
                    className="btn bg-gray text-black roboto-bold py-2 px-4 border border-2 my-2"
                    onClick={() => setQuestionsModal(true)}
                  >
                    <b>{lang?.add_question}</b>
                  </button>
                </div>
              </Col>
              {questionsData &&
                questionsData[0].question !== "" &&
                questionsData.length > 0 && (
                  <Col md={1} className="align-self-center mt-4">
                    <div className="bg-blue text-center rounded-circle p-2">
                      <h5 className="text-white mb-0">
                        {questionsData.length - 1}
                      </h5>
                    </div>
                  </Col>
                )}
            </Row>
            <hr style={{ opacity: "0.2" }} />

            <Row>
              <Col>
                <button
                  onClick={() => handleSubmit()}
                  disabled={
                    isLoading ||
                    !eventInput ||
                    !formData?.title ||
                    !formData?.organizer_name ||
                    !formData?.description ||
                    !formData?.start_date ||
                    !formData?.end_date ||
                    !formData?.sub_category ||
                    !formData?.availability
                  }
                  type="button"
                  className="btn-blue border-0 w-100 my-4 py-3"
                >
                  {isLoading ? <div className="loader"></div> : lang?.add_event}
                </button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      {/* image cropping  */}
      <Modal isOpen={imageModal} centered zIndex={9}>
        <div className="bg-white px-3 br-16">
          <ModalHeader toggle={() => setImageModal(!imageModal)}>
            {lang?.image_croper}
          </ModalHeader>
          <div className="position-relative" style={{ minHeight: "40rem" }}>
            <Cropper
              image={
                eventImagePreview ? eventImagePreview : BaseURL + data.cover
              }
              crop={crop}
              zoom={zoom}
              aspect={1.91}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="d-none">
            <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => {
                setZoom(e.target.value);
              }}
              className="zoom-range"
            />
          </div>
          <ModalFooter className="gap-3">
            <button
              className="btn btn-secondary border-0 px-4 py-2"
              onClick={() => {
                setImageModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-blue border-0 px-4 py-2"
              onClick={() => {
                handleCropper();
                setImageModal(false);
              }}
            >
              {lang?.save}
            </button>
          </ModalFooter>
        </div>
      </Modal>

      {/* cropped image preview */}
      <Modal
        isOpen={imagePreviewModal}
        centered
        zIndex={9}
        toggle={() => {
          setImagePreviewModal(!imagePreviewModal);
        }}
      >
        <img src={croppedImage} alt="picture" className="img-fluid" />
      </Modal>

      {/* Add Question Modal */}
      <Modal isOpen={questionsModal} centered zIndex={9} size="lg">
        <ModalHeader
          toggle={() => {
            setQuestionsModal(false);
          }}
        ></ModalHeader>
        <div className="p-3">
          {questionsData &&
            questionsData.map((value, index) => (
              <Row key={index}>
                <Col>
                  <Row>
                    <Col>
                      <label className="pb-1 fs-15 roboto-medium">
                        {lang?.question}: {index + 1}
                      </label>
                    </Col>
                    <Col className="text-end">
                      <label
                        className="pb-1 fs-15 roboto-medium text-blue cursor"
                        onClick={() => {
                          setSelectedQuestionIndex(index);
                          setOptionsModal(true);
                        }}
                      >
                        {value.answers &&
                        value.answers[0] !== "" &&
                        value.answers.length > 0
                          ? value.answers.length - 1
                          : ""}{" "}
                        {lang?.add_multiple_option}{" "}
                        <span style={{ opacity: "0.3" }} className="text-dark">
                          {"(Optional)"}
                        </span>
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <input
                        type="text"
                        className="py-2 form-control"
                        placeholder="Question"
                        value={value.question}
                        onChange={(event) => {
                          const updatedQuestionsData = [...questionsData];
                          updatedQuestionsData[index] = {
                            question: event.target.value,
                            answers: questionsData[index].answers,
                          };
                          setQuestionsData(updatedQuestionsData);
                        }}
                      />
                    </Col>

                    <Col md={1} className="align-self-center">
                      <button
                        className="rounded border-0 fw-bold px-2"
                        onClick={() => removeQuestion(index)}
                      >
                        x
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
          <Row className="mt-3">
            <Col className="text-end">
              <button
                className="btn-blue border-0 px-4 py-2"
                onClick={() => {
                  var disable = false;
                  questionsData.map((value) => {
                    if (value.question === "") {
                      disable = true;
                      toast.error(lang?.please_fil_input_field);
                    }
                  });
                  if (!disable) {
                    const newQuestion = { question: "", answers: [""] };
                    const updatedQuestionsData = [...questionsData];
                    updatedQuestionsData.push(newQuestion);
                    setQuestionsData(updatedQuestionsData);
                  }
                }}
              >
                {lang?.add_question}
              </button>
            </Col>
          </Row>
        </div>
        <ModalFooter className="gap-3">
          <button
            className="btn btn-secondary px-5"
            onClick={() => {
              setQuestionsModal(false);
            }}
          >
            {lang?.cancel}
          </button>
          <button
            className="btn-blue border-0 py-2 px-5"
            onClick={() => {
              setQuestionsModal(false);
            }}
          >
            {lang?.save}
          </button>
        </ModalFooter>
      </Modal>

      {/* Add Questions Options Modal */}
      <Modal isOpen={optionsModal} centered zIndex={9} size="lg">
        <ModalHeader
          toggle={() => {
            setOptionsModal(false);
          }}
        ></ModalHeader>
        <div className="p-3">
          {renderOptionsInputFields()}
          <Row className="mt-3">
            <Col className="text-end">
              <button
                className="btn-blue border-0 px-4 py-2"
                onClick={addOption}
              >
                {lang?.add_option}
              </button>
            </Col>
          </Row>
        </div>
        <ModalFooter className="gap-3">
          <button
            className="btn btn-secondary px-5"
            onClick={() => {
              setOptionsModal(false);
            }}
          >
            {lang?.cancel}
          </button>
          <button
            className="btn-blue border-0 py-2 px-5"
            onClick={() => {
              setOptionsModal(false);
            }}
          >
            {lang?.save}
          </button>
        </ModalFooter>
      </Modal>

      {/* Google map modal */}
      <Modal isOpen={locationModal} centered zIndex={9} size="lg">
        <ModalHeader toggle={() => setLocationModal(false)}></ModalHeader>
        {loadError && "Error loading maps"}
        {!isLoaded && "Loading Maps"}
        <div style={{ height: "50vh" }}>
          <GoogleMap
            mapContainerClassName="w-100 h-100"
            center={mapCenter}
            zoom={15}
            onClick={handleMapClick}
          >
            {markerPosition && (
              <Marker
                position={markerPosition}
                draggable={true}
                title={JSON.stringify(markerPosition)}
                icon={{
                  url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                }}
                onDragEnd={handleMarkerDragEnd}
              />
            )}
          </GoogleMap>
        </div>
        <ModalFooter className="gap-4">
          <button
            className="btn btn-secondary py-2 px-4"
            onClick={() => {
              setLocationModal(false);
            }}
          >
            {lang?.cancel}
          </button>
          <button
            className="btn-blue border-0 px-4 py-2"
            onClick={() => {
              setFormData({ ...formData, lat_lng: markerPosition });
              setLocationModal(false);
              const lat = markerPosition.lat;
              const lng = markerPosition.lng;
              reverseGeocode(lat, lng);
            }}
          >
            {lang?.save}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default AddEvent;
