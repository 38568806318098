import React from "react";
import ImageComponent from "./ImageComponent.jsx";

interface ImageWithAspectRatioProps {
  aspectRatio?: number;
  src: string;
}

const ImageWithAspectRatio: React.FC<ImageWithAspectRatioProps> = ({
  aspectRatio = 1.24,
  src,
}) => {
  return (
    <div className="bg-white" style={{ height: "auto" }}>
      <ImageComponent
        src={src}
        img_height={"auto"}
        img_width="100%"
        loader_height={JSON.stringify(555 / aspectRatio) + "px"}
        loader_width=""
        compClassName="object-cover"
      />
    </div>
  );
};

export default ImageWithAspectRatio;
