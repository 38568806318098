import React, { useContext, useEffect, useRef, useState } from "react";
import imageloader from "../../assets/images/image-loader.gif";
import axios from "axios";
import { VscSend } from "react-icons/vsc";
import spinnergif from "../../assets/images/Spinner.gif";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import GroupAvatar from "../../assets/images/icon/group_avatar.png";
import { IoIosCreate } from "react-icons/io";
import { BiSolidContact } from "react-icons/bi";
import { FaArrowLeftLong } from "react-icons/fa6";
import { toast } from "react-toastify";
import {
  AddGroupParticipantsApi,
  AllInboxConversation,
  BaseURL,
  CreateGroupApi,
  GetAllMessages,
  GetGroupParticipants,
  GroupAllMessages,
  GroupGlobalSearchApi,
  GroupImageApi,
  GroupMediaApi,
  GroupNotificationStatusApi,
  GroupParticipantsApi,
  GroupSearchParticipantsApi,
  GroupUnreadCounts,
  InviteGroupMemebr,
  SendGroupchats,
  Sendindiviualchats,
  UserUnreadCounts,
} from "../../constants/API";
import Photo from "../../assets/images/icon/picture.svg";
import { Link, useParams } from "react-router-dom";
import SpinnerGif from "../../assets/images/Spinner.gif";
import Search from "../../assets/images/icon/search.png";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { HiPlus } from "react-icons/hi";
import { PROFILE } from "../../constants/RoutesUrl";
import { HiDotsVertical } from "react-icons/hi";
import SpecSpinner from "../../components/manage_account/changeInterest/SpecSpinner";
import { IoIosArrowForward } from "react-icons/io";
import InfiniteScroll from "react-infinite-scroller";
import { MdBlock } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { RxExit } from "react-icons/rx";
import { IoSettingsOutline } from "react-icons/io5";
import { FaRegImage } from "react-icons/fa";
import { IoNotificationsOutline } from "react-icons/io5";
import { IoMdPersonAdd } from "react-icons/io";
import { Language } from "../../App";
import ImageComponent from "../../components/common/ImageComponent";

// import Pusher from "pusher-js";

function UserSidebarChat() {
  const [chat, setChat] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [message, setMessage] = useState("");
  const [userMessage, setUserMessage] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [groupImage, setGroupImage] = useState(GroupAvatar);
  const [groupimageUpdate, setGroupImageUpdate] = useState();
  const [isSend, setIsSend] = useState(false);
  const messageContainerRef = useRef();
  const [isExpanded, setIsExpanded] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedImageUrl, setClickedImageUrl] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const localData = JSON.parse(sessionStorage.getItem("SplashData"));
  const user_id = localData?.user?.id;

  //create group states
  const [groupParticipants, setGroupParticipants] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isAddParticipantsModalOpen, setIsAddParticipantsModalOpen] = useState(
    false
  );
  const [isglobalsearchModalOpen, setIsGlobalSearchModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isgroupimagemodalOpen, setIsGroupImageModalOpen] = useState(null);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupnameUpdate, setGroupNameUpdate] = useState("");
  const [selectedUserNames, setSelectedUserNames] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [isLoadingcreategroup, setIsLoadingCreateGroup] = useState(false);

  // group info states
  const [groupInfoModalOpen, setGroupInfoModalOpen] = useState(false);
  const [notificationsModalOpen, setNotificationsModalOpen] = useState(false);
  const [groupmediamodalOpen, setGroupMediaModalOpen] = useState(false);
  const [editgroupmodalOpen, setEditGroupModalOpen] = useState(false);
  const [
    groupaddparticipantsmodalOpen,
    setGroupAddParticipantsModalOpen,
  ] = useState(false);
  const [groupparticipantsmodalOpen, setGroupParticipantsModalOpen] = useState(
    false
  );
  const [groupPhoto, setGroupPhoto] = useState([]);
  const [groupparticipantsCounts, setGroupPartcipantsCounts] = useState([]);
  const [addgroupmembers, setAddGroupMembers] = useState([]);
  const [loadingButtons, setLoadingButtons] = useState([]);
  const [groupID, setGroupID] = useState(
    selectedUser ? selectedUser.group_id : null
  );
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [notificationStatus, setNotificationStatus] = useState(0);
  const [isloadinggroupUpdate, setIsLoadingGroupUpdate] = useState(false);
  const [searchgroupMembers, setSearchGroupMembers] = useState([]);
  const [searchglobalMembers, setSearchGlobalMembers] = useState([]);
  const [createdBy, setCreatedBy] = useState(null);
  const [groupParticipant, setGroupParticipant] = useState(null);
  const [globalSearchModal, setGlobalSearchModal] = useState(false);
  const [hasMoreFollowing, setHasMoreFollowing] = useState(true);

  const [pagefollowingMembers, setPageFollowingMembers] = useState(1);
  const [checkedItems, setCheckedItems] = useState({});

  const [groupphotoLoading, setGroupPhotoLoading] = useState(true);
  const [isLoadingParticipants, setIsLoadingParticipants] = useState(false);
  const [openContact, setOpenContact] = useState(true);
  const screenWidth = window.innerWidth;
  const { lang } = useContext(Language);

  const handleGlobalSearch = (event) => {
    const enteredAlpha = event.target.value;
    axios
      .post(GroupGlobalSearchApi, {
        user_id: user_id,
        keyword: enteredAlpha,
        type: "user",
      })
      .then((res) => {
        setSearchGlobalMembers(res.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGroupSearch = (event) => {
    const enteredValue = event.target.value;

    axios
      .post(GroupSearchParticipantsApi, {
        keyword: enteredValue,
        user_id: user_id,
        group_id: selectedUser.group_id,
      })
      .then((res) => {
        setSearchGroupMembers(res.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEditGroup = () => {
    setIsLoadingGroupUpdate(true);
    const formData = {
      groupId: groupId,
      group: groupnameUpdate,
      image: groupimageUpdate,
    };

    axios
      .post(GroupImageApi + groupId, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.action);
          window.location.reload();
          setEditGroupModalOpen(false);
          setIsLoadingGroupUpdate(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingGroupUpdate(false);
      });
  };

  const handleInviteMember = (userId) => {
    // Show loader for the specific button
    setLoadingButtons((prevButtons) => [...prevButtons, userId]);

    axios
      .get(InviteGroupMemebr + selectedUser.group_id + "/" + userId)
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.action);
          // Close modal
          setGroupAddParticipantsModalOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // Hide loader for the specific button
        setLoadingButtons((prevButtons) =>
          prevButtons.filter((btnId) => btnId !== userId)
        );
      });
  };

  const handleNewPageData = () => {
    // Increment the page number when loading more data
    setPage(page + 1);
  };

  const toggleGroupMediaModal = () => {
    setGroupMediaModalOpen(!groupmediamodalOpen);
    setGroupPhotoLoading(true);
    axios
      .get(GroupMediaApi + selectedUser.group_id)
      .then((res) => {
        setGroupPhoto(res.data.data.data);
        setGroupPhotoLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setGroupPhotoLoading(false);
      });
  };
  const toggleEditGroupModal = () => {
    if (user_id === createdBy) {
      setGroupImageUpdate(null);
      setEditGroupModalOpen(!editgroupmodalOpen);
    } else {
      // Alert or handle unauthorized access here
      toast.error(lang?.you_not_permission_edit_group);
    }
  };

  const toggleGroupAddParticipantsModal = () => {
    setGroupAddParticipantsModalOpen(!groupaddparticipantsmodalOpen);
    axios
      .get(AddGroupParticipantsApi + selectedUser.group_id + "/" + user_id)
      .then((res) => {
        setAddGroupMembers(res.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleGroupParticipantsModal = () => {
    setGroupParticipantsModalOpen(!groupparticipantsmodalOpen);
  };

  useEffect(() => {
    if (selectedUser && selectedUser.group_id) {
      axios
        .get(GroupParticipantsApi + selectedUser.group_id + "/" + user_id, {
          params: { page: page }, // Use the current page variable here
        })
        .then((res) => {
          setGroupPartcipantsCounts((prevData) => [
            ...prevData,
            ...res?.data?.data?.data,
          ]); // Append new data to existing data
          setTotalPages(res.data.data.last_page);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedUser, user_id, page]);

  // mute or unmute notfication function

  const handleMuteNotifications = () => {
    axios
      .get(
        GroupNotificationStatusApi +
          selectedUser.group_id +
          "/" +
          user_id +
          "/" +
          (notificationStatus ? "0" : "1")
      )
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.action);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // notification status check funcation
  const toggleNotificationsModal = () => {
    setNotificationsModalOpen(!notificationsModalOpen);

    axios
      .get(GroupNotificationStatusApi + selectedUser.group_id + "/" + user_id)
      .then((res) => {
        if (res.data.status) {
          const notificationData = res.data.data;
          setNotificationStatus(notificationData === "1");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const toggleGroupInfoModal = () => {
    setGroupInfoModalOpen(!groupInfoModalOpen);
  };

  const handleGroupImage = (id) => {
    const formData = {
      groupId: id,
      group: groupName,
      image: groupImage,
    };

    // setGroupPhotoLoading(true);

    axios
      .post(GroupImageApi + id, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res);
        // setGroupPhotoLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setGroupPhotoLoading(false);
      });
  };

  const handleLeaveGroupClick = () => {
    toast.success(lang?.please_wait_working);
  };

  const handleReportGroupClick = () => {
    toast.success(lang?.please_wait_working);
  };

  const handleCreateGroup = () => {
    setIsLoadingCreateGroup(true);
    axios
      .post(CreateGroupApi, {
        group: groupName,
        user: user_id,
        users: selectedUserIds,
      })
      .then((res) => {
        if (res.data.status) {
          setIsLoadingCreateGroup(false);
          const id = res.data.data.id;

          handleGroupImage(id);
          toast.success(res.data.action);
          window.location.reload();
          setIsAddParticipantsModalOpen(false);
        }
      })
      .catch((err) => {
        toast.success(err.data.status);
        setIsLoadingCreateGroup(false);
      });
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (item) => {
    const userId = item.id; // Assuming 'id' is the unique identifier for each user

    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [item.id]: !prevCheckedItems[item.id],
    }));

    // Check if the user is already selected
    if (selectedUserIds.includes(userId)) {
      // If selected, remove from the list
      setSelectedUserIds((prevIds) => prevIds.filter((id) => id !== userId));
    } else {
      // If not selected, add to the list
      setSelectedUserIds((prevIds) => [...prevIds, userId]);
    }

    // Check if the item is already selected
    if (selectedUserNames.includes(item.user_name)) {
      // If selected, remove the name
      setSelectedUserNames(
        selectedUserNames.filter((user_name) => user_name !== item.user_name)
      );
    } else {
      // If not selected, add the name
      setSelectedUserNames([...selectedUserNames, item.user_name]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await axios.get(
          `${GetGroupParticipants}${user_id}?page=${pagefollowingMembers}`
        );
        const updatedValue = res.data.data.data;
        setGroupParticipants((prevData) => [...prevData, ...updatedValue]);
        setPageFollowingMembers((prevPage) => prevPage + 1);

        if (res.data.data.current_page >= res.data.data.last_page) {
          setHasMoreFollowing(false);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoadingParticipants(false);
      }
    };

    if (hasMoreFollowing && !isLoadingParticipants) {
      fetchData();
    }
  }, [pagefollowingMembers, hasMoreFollowing, isLoading, user_id]);

  const handleInputChange = () => {
    setGlobalSearchModal(true);
  };

  const handleCloseModal = () => {
    setGlobalSearchModal(false);
  };

  // Inside your component
  const handleRemovePicture = () => {
    setGroupImage(Avatar);
    closeRemoveModal();
  };

  // Function to close the Remove modal
  const closeRemoveModal = () => {
    setRemoveModalOpen(false);
  };

  // Function to open the image modal
  const openImageModal = () => {
    setIsImageModalOpen(true);
  };

  // Function to open the Remove modal
  const openRemoveModal = () => {
    setRemoveModalOpen(true);
  };

  // Function to close the image modal
  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  // const pusher = new Pusher("edca05914bdd6a93ff74", {
  //   cluster: "ap2",
  //   encrypted: true,
  // });

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  // const calculateChannelId = () => {
  //   let channelId = "";
  //   if (chat.length === 0) {
  //     channelId = `${user_id}`;
  //   } else {
  // channelId = `${chat[0].from_to ?? `${user_id}`}`;
  //   }
  //   return channelId;
  // };

  // const calculatechannelid =() =>{
  //   let channelId= "";
  //   if(chat.length ===0){
  //     channelId = `${user_id}`;
  //   }
  // }

  //image send in chat
  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setSelectedImageName(file.name);
  };
  //for inbox chat
  useEffect(() => {
    axios
      .get(AllInboxConversation + "/" + user_id)
      .then((response) => {
        setChat(response.data["data"]);
        setLoading(false);
        const groupid = response.data.data.id;
        handleEditGroup(groupid);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);
  //1 to 1 message
  const sendMessage = () => {
    if (message.trim() !== "" || selectedImage) {
      setIsSend(true);

      const formData = new FormData();
      formData.append("from", user_id);
      formData.append("to", selectedUser.id);
      formData.append("message", message);
      formData.append("type", "text");
      formData.append("timestamp", new Date().getTime());
      formData.append("attachment_type", "image");

      if (selectedImage) {
        formData.append("attachment", selectedImage);
      }
      //1 to 1 chats
      fetch(Sendindiviualchats, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          const updatedMessages = [data.data, ...userMessage];
          setUserMessage(updatedMessages);
          setIsSend(false);
          setMessage("");
          setSelectedImage(null);
          setSelectedImageName(""); // Clear selected image name
          // channel.trigger("new-message", data.data);
        })
        .catch((error) => {
          console.error("Error sending message:", error);
          setIsSending(false);
        });
    }
  };
  // const channelId = calculateChannelId();

  // useEffect(() => {
  //   if (selectedUser) {
  //     const channel = pusher.subscribe(channelId);

  //     // Bind to the "new-message" event
  //     channel.bind("new-message", (data) => {
  //       // Handle the new message data here
  //       console.log("New message received:", data);

  //       // Update your chat state or take any other necessary action
  //       const updatedMessages = [data, ...userMessage];
  //       setUserMessage(updatedMessages);
  //     });

  //     return () => {
  //       // Unsubscribe from the Pusher channel when the component unmounts
  //       channel.unbind("new-message");
  //       pusher.unsubscribe(channelId);
  //     };
  //   }
  // }, [selectedUser, channelId, userMessage]);

  // group messages function
  const hanldeGroupmessage = () => {
    if (message.trim() !== "" || selectedImage) {
      setIsSend(true);

      const formData = new FormData();
      formData.append("user", user_id);
      formData.append("group", groupId);
      formData.append("message", message);
      formData.append("type", "text");
      formData.append("timestamp", new Date().getTime());
      formData.append("attachment_type", "image");

      if (selectedImage) {
        formData.append("attachment", selectedImage);
      }
      //group message
      fetch(SendGroupchats, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          const updatedMessages = [data.data, ...userMessage];
          setUserMessage(updatedMessages);
          setIsSend(false);
          setMessage("");
          setSelectedImage(null); // Clear selected image
          setSelectedImageName(""); // Clear selected image name
        })
        .catch((error) => {
          console.error("Error sending message:", error);
          setIsSending(false);
        });
    }
  };
  //enter + shift key function
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setIsExpanded(true); // Expand the textarea
      setMessage((prevMessage) => prevMessage + "\n"); // Insert a newline character
    } else if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent the default Enter key behavior (e.g., form submission)
      // Call your send message logic here
      if (selectedUser.group_id) {
        hanldeGroupmessage();
      } else {
        sendMessage();
      } // Replace with your actual send message function
      setMessage(""); // Clear the message input
      setIsExpanded(false); // Reset the textarea height
    } else if (e.key === "Backspace" && !message) {
      setIsExpanded(false); // Collapse the textarea if backspace is pressed and message is empty
    }
  };

  useEffect(() => {
    messageContainerRef.current.scrollTop =
      messageContainerRef.current.scrollHeight;
  }, [userMessage]);
  //date and time
  const formatDateTime = (unixTime) => {
    const now = new Date();
    const date = new Date(unixTime * 1000);
    const diffTime = Math.abs(now - date);
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const isToday =
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear();
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    if (isToday && diffHours < 24) {
      return date.toLocaleTimeString(undefined, options);
    } else if (diffHours >= 24 && diffHours < 48) {
      return "Yesterday";
    } else if (diffDays >= 2 && diffDays <= 7) {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return daysOfWeek[date.getDay()];
    } else {
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      return date.toLocaleDateString(undefined, options);
    }
  };
  //time
  const formatTime = (unixTime) => {
    const date = new Date(unixTime * 1000);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return date.toLocaleTimeString(undefined, options);
  };
  //status of date with messages
  const formatDateWithDay = (date) => {
    const options = {
      weekday: "long", // Add the weekday option
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    const formattedDate = date.toLocaleDateString(undefined, options);

    return `${formattedDate}`;
  };

  const groupedMessages = {};

  userMessage.forEach((message) => {
    const messageDate = new Date(message.time * 1000);
    const currentDate = new Date();

    if (messageDate.toDateString() === currentDate.toDateString()) {
      // Today's message
      const key = "Today";
      if (!groupedMessages[key]) {
        groupedMessages[key] = [];
      }
      groupedMessages[key].push(message);
    } else if (
      messageDate.toDateString() ===
      new Date(currentDate - 1 * 24 * 60 * 60 * 1000).toDateString()
    ) {
      // Yesterday's message
      const key = "Yesterday";
      if (!groupedMessages[key]) {
        groupedMessages[key] = [];
      }
      groupedMessages[key].push(message);
    } else {
      // Messages from other dates
      const key = formatDateWithDay(messageDate);
      if (!groupedMessages[key]) {
        groupedMessages[key] = [];
      }
      groupedMessages[key].push(message);
    }
  });
  //when someoneclick on user to open chat
  const handleClick = (user, item) => {
    if (item.group_id === 0) {
      setSelectedUser(user);
    } else if (item.group_id != 0) {
      setSelectedUser(item);
    }
    setSelectedUserId(user);
    setGroupId(item.group_id);

    setIsSending(true);
    if (item.group_id === 0) {
      const newMessage = {
        from: user_id,
        to: user.id,
        message: message,
        type: "text",
        attachment_type: "image",
      };

      fetch(GetAllMessages, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newMessage),
      })
        .then((response) => response.json())
        .then((data) => {
          setUserMessage(data.data.data);
          setIsSending(false);
          setMessage("");

          // Reset unread_count to 0 for the specific chat
          const updatedChat = chat.map((item) => {
            if (item.from_to === `${user_id}-${user.id}`) {
              return { ...item, unread_count: 0 };
            }
            return item;
          });

          setChat(updatedChat);
        })
        .catch((error) => {
          console.error("Error sending message:", error);
          setIsSending(false);
        });
    } else {
      const groupMessage = {
        user: user_id,
        group: item.group_id,
        message: message,
        type: "text",
        attachment_type: "image",
      };

      //group messages all
      fetch(GroupAllMessages, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(groupMessage),
      })
        .then((response) => response.json())
        .then((data) => {
          const createdByData = data?.data.created_by;
          const GroupParticipant = data.data.participants;
          setCreatedBy(createdByData);
          setGroupParticipant(GroupParticipant);

          data.data.messages.data.forEach((item) => {
            const userdata = item.user.name;
          });
          setUserMessage(data.data.messages.data);
          setIsSending(false);
          setMessage("");

          const updatedChat = chat.map((item) => {
            if (item.group_id === groupMessage.group) {
              return { ...item, unread_count: 0 };
            }
            return item;
          });

          setChat(updatedChat);
        })
        .catch((error) => {
          console.error("Error sending message:", error);
          setIsSending(false);
        });

      const currentTime = new Date().getTime();
      let lastMessageTime = 0;
      let groupedMessages = [];

      userMessage.forEach((message) => {
        const messageTime = message.time * 1000;
        if (currentTime - messageTime <= 60000) {
          // Messages sent within 60 seconds
          if (lastMessageTime === 0 || messageTime - lastMessageTime > 60000) {
            // Start a new group if it's the first message or messages are not consecutive
            groupedMessages.push({ type: "group", messages: [message] });
          } else {
            // Add to the current group
            groupedMessages[groupedMessages.length - 1].messages.push(message);
          }
        } else {
          // Messages sent after 60 seconds
          groupedMessages.push({ type: "single", message });
        }
        lastMessageTime = messageTime;
      });

      // Update your state with groupedMessages
      setUserMessage(groupedMessages);
    }

    axios
      .get(UserUnreadCounts + "/" + user_id + "/" + user_id + "-" + user.id)
      .then((res) => {})
      .catch((err) => {
        toast.error(err);
      });

    axios
      .get(GroupUnreadCounts + "/" + user_id + "/" + item.group_id)
      .then((res) => {})
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <div className="row chat-res">
      <div
        className={`p-0 ${openContact ? "col1" : "col1-w-0 overflow-hidden"}`}
      >
        <div className="HeaderSidebar">
          <div className="container-fluid d-flex justify-content-between align-items-center">
            <p className="text roboto-bold m-0 fw-md-700">
              <span
                className="d-lg-none py-1 px-2 manue-hover rounded cursor"
                onClick={() => setOpenContact(!openContact)}
              >
                <FaArrowLeftLong size={22} color="#00c5de" />
              </span>
              {lang?.all_messages}
            </p>

            <p
              className="mt-3 text-dark manue-hover rounded cursor p-1 d-flex justify-content-center align-items-center"
              onClick={() => setIsCreateModalOpen(true)}
            >
              <IoIosCreate size={25} />
            </p>
          </div>
        </div>
        <div className="container-fluid">
          <div className="scroll pb-5 scroll-hide vh-100 pb-13rem">
            {loading ? (
              <div className="spinner">
                <SpecSpinner />
              </div>
            ) : (
              chat.map((item) => (
                <div key={item.id}>
                  <div
                    className={`usersidebarchat rounded-3 py-2 mt-2 manue-hover ${
                      selectedUserId === item.user || selectedUserId === item
                        ? "clicked"
                        : ""
                    } ${item.unread_count >= 1 ? "unread" : ""}`}
                    onClick={() => {
                      if (
                        selectedUserId === item.user ||
                        selectedUserId === item
                      ) {
                        return;
                      } else {
                        if (screenWidth < 991) {
                          handleClick(item.user, item);
                          setOpenContact(!openContact);
                        } else {
                          handleClick(item.user, item);
                        }
                      }
                    }}
                  >
                    <div className="container-fluid">
                      <div className="d-flex justify-content-between flex-column-480">
                        <div className="d-flex gap-2">
                          <div>
                            <div className="br-24 sidebarimg dropdown-avatar-shadow bg-whit d-flex justify-content-center align-items-center">
                              {item.group_id ? (
                                <ImageComponent
                                  src={
                                    item.image
                                      ? BaseURL + item.image
                                      : GroupAvatar
                                  }
                                  compClassName="br-24 img"
                                  loader_height="100%"
                                  loader_width="100%"
                                />
                              ) : item.user.image ? (
                                <ImageComponent
                                  src={BaseURL + item.user.image}
                                  compClassName="br-24 img"
                                  loader_height="100%"
                                  loader_width="100%"
                                />
                              ) : (
                                <div className="avatar-placeholder">
                                  <span>
                                    <ImageComponent
                                      src={Avatar}
                                      compClassName="img p-1 br-24"
                                      loader_height="100%"
                                      loader_width="100%"
                                    />
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            <div className="userchatinfo mt-2">
                              <p className="username roboto-bold m-0">
                                {item.group_id
                                  ? item.group_name
                                  : item.user.name}
                              </p>
                              <p className="message-text d-flex">
                                {item.attachment ? (
                                  item.from === user_id ? (
                                    <>
                                      <img
                                        src={Photo}
                                        width={22}
                                        alt="picture"
                                      />{" "}
                                      {lang?.send_a_photo}
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src={Photo}
                                        width={22}
                                        alt="picture"
                                      />
                                      {lang?.picture_photo}
                                    </>
                                  )
                                ) : (
                                  <>
                                    {item.from === user_id
                                      ? `You: ${
                                          item.message.length > 16
                                            ? `${item.message.substring(
                                                0,
                                                16
                                              )}...`
                                            : item.message
                                        }`
                                      : item.to === user_id
                                      ? item.message.length > 16
                                        ? `${item.message.substring(0, 16)}...`
                                        : item.message
                                      : `${item.user.name}: ${item.message}`}
                                  </>
                                )}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="date-time mt-2">
                          <p className="date roboto-regular">
                            {formatDateTime(item.time).split(",")[0]}
                          </p>
                          {item.unread_count ? (
                            <div className="unread-count">
                              <p className="counter-text m-0 d-flex justify-content-center align-items-center">
                                {item.unread_count}
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      <div className="col2">
        <div className="Headerchat">
          <div className="d-flex align-items-center">
            <div
              onClick={() => setOpenContact(!openContact)}
              className="ms-2 d-lg-none manue-hover rounded cursor p-1 d-flex justify-content-center align-items-center"
            >
              <BiSolidContact size={22} />
            </div>
            {selectedUser ? (
              <div className="d-flex justify-content-between w-100">
                <div className="Chatinfo px-3 p-2 d-flex align-items-center gap-3">
                  <div>
                    {selectedUser.image ? (
                      <img
                        src={BaseURL + selectedUser.image}
                        alt="picture"
                        className="user-image selected-img br-30  dropdown-avatar-shadow bg-white"
                      />
                    ) : (
                      <img
                        src={Avatar}
                        alt="picture"
                        className="avatar-image selected-img br-30  dropdown-avatar-shadow bg-white"
                      />
                    )}
                  </div>
                  <div className="chatheadertext roboto-bold">
                    {selectedUser.name || selectedUser.group_name}
                  </div>
                </div>

                <div className="mt-2">
                  {selectedUser.group_name ? (
                    <div
                      className="mt-2 text-dark manue-hover rounded cursor p-1 d-flex justify-content-center align-items-center"
                      onClick={toggleGroupInfoModal}
                    >
                      <HiDotsVertical size={20} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              <div className="Chatinfo px-3 p-2">
                &nbsp;&nbsp;
                <span className="chatheadertext"></span>
              </div>
            )}
          </div>

          <div className="Message owner" ref={messageContainerRef}>
            <div className="messagecontent">
              {isSending ? (
                <div className="spinner">
                  <SpecSpinner />
                </div>
              ) : userMessage.length === 0 ? (
                <div className="centered-text text-center">
                  <p className="click-text">
                    {lang?.select_conversation_display_messages}
                  </p>
                </div>
              ) : (
                Object.keys(groupedMessages)
                  .slice()
                  .reverse()
                  .map((date) => (
                    <div key={date}>
                      <h4 className="date-header">{date}</h4>
                      {groupedMessages[date]
                        .slice()
                        .reverse()
                        .map((message) => (
                          <div
                            key={message.id}
                            className={`msgitem ${
                              message.user_id === user_id ||
                              message.from === user_id
                                ? "message sender"
                                : "message receiver"
                            }`}
                          >
                            <div className="message-text">
                              {message.attachment ? (
                                <div className="img-container">
                                  <div>
                                    {isLoading && (
                                      <img
                                        src={imageloader}
                                        width={90}
                                        alt="picture"
                                      />
                                    )}
                                    <img
                                      className={`attachment-img img-fluid ${
                                        message.from === user_id
                                          ? "sender"
                                          : "receiver"
                                      }`}
                                      src={BaseURL + message.attachment}
                                      alt="picture"
                                      onLoad={handleImageLoad}
                                      onClick={() => {
                                        setClickedImageUrl(
                                          BaseURL + message.attachment
                                        );
                                        toggleModal();
                                      }}
                                      style={{
                                        display: isLoading ? "none" : "block",
                                      }}
                                    />
                                  </div>

                                  {message.message && (
                                    <>
                                      {message.from === user_id ? (
                                        <p className="msgtextsender roboto-bold">
                                          {message.message}
                                        </p>
                                      ) : (
                                        <p className="msgtexts roboto-bold">
                                          {message.message}
                                        </p>
                                      )}
                                    </>
                                  )}
                                </div>
                              ) : (
                                <p className="msgtext roboto-bold">
                                  {message.message}
                                </p>
                              )}

                              <div className="message-with-sender">
                                {message.user && (
                                  <p className="msgtime">
                                    {message.user.name} -{" "}
                                    {formatTime(message.time)}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ))
              )}
            </div>
          </div>

          <div
            className={`Input ${!selectedUser ? "hidden" : ""}`}
            style={{ position: "absolute", bottom: "-3px" }}
          >
            {selectedUser ? (
              <>
                <div className="d-flex justify-content-between gap-1 w-100">
                  <div>
                    <input
                      type="file"
                      id="media"
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                      accept="image/*" // Allow only image files
                    />
                    <label
                      className="plus manue-hover rounded cursor p-1 d-flex justify-content-center align-items-center"
                      htmlFor="media"
                    >
                      <HiPlus color="black" size={30} />
                    </label>
                  </div>
                  <input
                    className={`writemsg w-100 form-control ${
                      isExpanded ? "expanded" : ""
                    }`}
                    type="text"
                    placeholder={lang?.write_your_message}
                    value={
                      message +
                      (selectedImageName ? ` ${selectedImageName}` : "")
                    } // Append selected image's name to the message
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e)}
                  />

                  <div>
                    <button
                      className="send"
                      onClick={() => {
                        if (selectedUser.group_id) {
                          hanldeGroupmessage();
                        } else {
                          sendMessage();
                        }
                      }}
                      disabled={isSending}
                    >
                      {isSend ? (
                        <div>
                          <img
                            className="spiner"
                            src={spinnergif}
                            alt="picture"
                          />
                        </div>
                      ) : (
                        <VscSend color="white" size={30} />
                      )}
                    </button>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <Modal
        centered
        isOpen={isModalOpen}
        toggle={toggleModal}
        contentLabel="Image Modal"
      >
        <div className="vh-100">
          <img
            src={clickedImageUrl}
            alt="..."
            className="img-fluid w-100 h-100 object-fit-contain"
          />
        </div>
      </Modal>

      {/* create group modal */}
      <Modal
        centered
        zIndex={9}
        isOpen={isCreateModalOpen}
        toggle={() => setIsCreateModalOpen(!isCreateModalOpen)}
      >
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12 mt-4  ">
              <div className="edit-profile br-8 pxy-20 bg-white  m-xl-0 m-lg-auto m-md-auto ">
                <h3 className="d-flex justify-content-center mt--12 fs-20 fs-md-16 fw-bold">
                  {lang?.new_group}
                </h3>

                <div className="d-flex justify-content-center mt-4">
                  <div className="profile_img position-relative">
                    <img
                      src={
                        groupImage instanceof Blob
                          ? URL.createObjectURL(groupImage)
                          : GroupAvatar
                      }
                      alt="picture"
                      id="profileimage"
                      onClick={openImageModal}
                    />

                    <Modal
                      isOpen={isImageModalOpen}
                      centered
                      zIndex={9}
                      toggle={closeImageModal}
                    >
                      <img
                        src={
                          groupImage instanceof Blob
                            ? URL.createObjectURL(groupImage)
                            : GroupAvatar
                        }
                        alt="Profile Image"
                        className="img-fluid rounded"
                      />
                    </Modal>
                    <input
                      type="file"
                      id="media"
                      accept="image/*"
                      className="d-none"
                      onChange={(e) => setGroupImage(e.target.files[0])}
                    />
                    <label className="plus manue-hover br-8" htmlFor="media">
                      <HiPlus color="black" size={25} />
                    </label>
                  </div>
                </div>

                <div className="d-flex flex-column gap-4 mt-4">
                  <div>
                    <h6 className="fs-16 fw-bold opacity-75">
                      {lang?.group_name}
                    </h6>
                    <input
                      type="text"
                      placeholder={lang?.type_group_name_here}
                      value={groupName}
                      onChange={(e) => setGroupName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column gap-4 mt-3 my-5">
                  <button
                    className="btn-blue border-0 px-4 py-2"
                    disabled={!groupName}
                    onClick={() => {
                      setIsCreateModalOpen(false);
                      setIsAddParticipantsModalOpen(true);
                    }}
                  >
                    {lang?.next}
                  </button>
                  {/* // onChange={(e) => handleGlobalSearch(e)} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* modal or add participants */}
      <Modal
        scrollable
        zIndex={9}
        size="lg"
        isOpen={isAddParticipantsModalOpen}
        toggle={() =>
          setIsAddParticipantsModalOpen(!isAddParticipantsModalOpen)
        }
      >
        <ModalHeader>
          <div className="py-3" onClick={setIsGlobalSearchModalOpen}>
            <div className="position-relative">
              <img
                src={Search}
                className="position-absolute start-0 ms-3 mt-11"
                width={20}
                height={20}
                alt="..."
              />
              <input
                type="text"
                className="form-control searchgroupmembers py-2 ps-5"
                placeholder={lang?.search}
                autoFocus
              />
            </div>
          </div>
        </ModalHeader>
        {/* Add Participants Modal Content */}
        <div className="container-fluid">
          <div className="row justify-content-between">
            <h4 className="text-center mt-3">{lang?.add_participants}</h4>
            {/* <h6 className="text-center">{lang?.add_participants}</h6> */}
          </div>
          <div className="row">
            <div className="col">
              <h4>{lang?.friends}</h4>
              {selectedUserNames.map((user_name, index) => (
                <React.Fragment key={index}>
                  <button className="selectedusersgroup p-1">
                    {user_name}
                  </button>
                  {index < selectedUserNames.length - 1 && " "}{" "}
                  {/* Add space if not the last element */}
                </React.Fragment>
              ))}
            </div>
          </div>

          <InfiniteScroll
            pageStart={0}
            loadMore={setIsAddParticipantsModalOpen}
            hasMore={hasMoreFollowing}
            loader={
              <div className="w-100 text-center" key={0}>
                <img src={SpinnerGif} alt="picture" width={70} />
              </div>
            }
            useWindow={false}
          >
            <div className="row">
              <div className="block-account d-flex flex-column gap-4 mb-5 mt-4 creategroupheight scroll-hide ">
                {groupParticipants.map((item) => (
                  <div
                    className="box-shadow1 d-flex justify-content-between align-items-center p-16 br-10"
                    key={item}
                  >
                    <div className="d-flex align-items-center gap-md-4 gap-sm-3 gap-2 ">
                      <span>
                        <img
                          src={item.image ? BaseURL + item.image : Avatar}
                          alt="picture"
                        />
                      </span>
                      <div>
                        <h3 className="fs-18 fs-md-15 fw-bold m-0 text-ellips-110px">
                          {item.name}
                        </h3>
                        <h6 className="fs-18 fs-md-16 text-gray m-0 text-ellips-110px">
                          {item.user_name}
                        </h6>
                      </div>
                    </div>
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(item)}
                      className="bg-blue border-0 pxy-17 br-6 text-white  fs-18 fs-md-16"
                      style={{ transform: "scale(1.5)" }}
                    />
                  </div>
                ))}

                <div className="row">
                  <div className="d-flex position-absolute bottom-0 bg-white justify-content-center">
                    <button
                      className=" bg-blue border-0 pxy-17 br-6 text-white  fs-18 fs-md-16 creategroupbtn my-2"
                      onClick={handleCreateGroup}
                    >
                      {isLoadingcreategroup ? (
                        <div>
                          <Spinner size={"sm"} />
                        </div>
                      ) : (
                        lang?.create_group
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </InfiniteScroll>
        </div>
      </Modal>

      {/* group info modal */}
      <Modal
        isOpen={groupInfoModalOpen}
        centered
        toggle={toggleGroupInfoModal}
        size="lg"
      >
        {/* <ModalHeader toggle={toggleGroupInfoModal}>Group Info</ModalHeader> */}
        <ModalBody>
          <div className="container-fluid p-0">
            <div>
              <div className="edit-profile br-8 px-3 py-4 bg-white box-shadow1 m-xl-0 m-lg-auto m-md-auto ">
                <h3 className="d-flex justify-content-center mt--12 fs-20 fs-md-16 fw-bold">
                  {lang?.group_info}
                </h3>

                <div>
                  {selectedUser ? (
                    <div>
                      <div className="d-flex justify-content-center mt-4">
                        <div className="profile_img position-relative">
                          <div onClick={openImageModal} className="cursor">
                            <ImageComponent
                              src={
                                selectedUser.image
                                  ? BaseURL + selectedUser.image
                                  : GroupAvatar
                              }
                              compClassName=""
                              loader_height="100%"
                              loader_width="100%"
                            />
                          </div>

                          <Modal
                            isOpen={isImageModalOpen}
                            centered
                            toggle={closeImageModal}
                          >
                            <img
                              src={
                                selectedUser.image
                                  ? BaseURL + selectedUser.image
                                  : GroupAvatar
                              }
                              alt="Profile Image"
                              className="img-fluid rounded"
                            />
                          </Modal>
                        </div>
                      </div>

                      <div className="text-center">
                        <h4 className="mt-2"> {selectedUser.group_name}</h4>
                        <h6>
                          {" "}
                          {selectedUser.name || selectedUser.user.name}{" "}
                          {lang?.created_this_group}
                        </h6>
                        <h6>
                          {lang?.created} {selectedUser?.user?.created_at || ""}
                        </h6>
                        <h6>
                          . {groupParticipant}
                          {lang?.participants}
                        </h6>

                        {/* <h6>{selectedUser.group_id}</h6> */}
                      </div>
                    </div>
                  ) : (
                    <div className="Chatinfo px-3 p-2">
                      &nbsp;&nbsp;
                      <span className="chatheadertext"></span>
                    </div>
                  )}
                </div>

                <hr />

                <div
                  className="box-shadow1 d-flex justify-content-between align-items-center p-16 br-10 cursor manue-hover hover-blue"
                  onClick={toggleEditGroupModal}
                >
                  <div className="d-flex gap-2 align-items-center">
                    <div>
                      <IoSettingsOutline size={24} />
                    </div>
                    <h3 className="fs-18 fs-md-16 m-0 text-ellips-149px">
                      {lang?.edit_group_info}
                    </h3>
                  </div>
                  <IoIosArrowForward size={20} />
                </div>
                <div
                  className="box-shadow1 d-flex justify-content-between align-items-center p-16 br-10 cursor manue-hover hover-blue"
                  onClick={toggleGroupMediaModal}
                >
                  <div className="d-flex align-items-center gap-1">
                    <div className="d-flex gap-2 align-items-center">
                      <div>
                        <FaRegImage size={24} />
                      </div>
                      <h3 className="fs-18 fs-md-15 text-center m-0 text-ellips-149px">
                        {lang?.media_photo_videos}
                      </h3>
                    </div>
                  </div>
                  <IoIosArrowForward size={20} />
                </div>

                <div
                  className="box-shadow1 d-flex justify-content-between align-items-center p-16 br-10 mt-3 cursor manue-hover hover-blue"
                  onClick={toggleNotificationsModal}
                >
                  <div className="d-flex gap-2 align-items-center">
                    <div>
                      <IoNotificationsOutline size={24} />
                    </div>
                    <h3 className="fs-18 fs-md-16 m-0 text-ellips-149px">
                      {lang?.manage_notifications}
                    </h3>
                  </div>
                  <IoIosArrowForward size={20} />
                </div>

                <div
                  className="box-shadow1 d-flex justify-content-between align-items-center p-16 br-10 mt-3 cursor manue-hover hover-blue"
                  onClick={toggleGroupAddParticipantsModal}
                >
                  <div className="d-flex align-items-center gap-1">
                    <div className="d-flex gap-2 align-items-center">
                      <div>
                        <FaPlus size={24} />
                      </div>
                      <h3 className="fs-18 fs-md-16 m-0 text-ellips-149px">
                        {lang?.add_participants}
                      </h3>
                    </div>
                  </div>
                  <IoIosArrowForward size={20} />
                </div>
                <div
                  className="box-shadow1 d-flex justify-content-between align-items-center p-16 br-10 cursor manue-hover hover-blue"
                  onClick={toggleGroupParticipantsModal}
                >
                  <div className="d-flex gap-2 align-items-center">
                    <div>
                      <IoMdPersonAdd size={24} />
                    </div>
                    <h3 className="fs-18 fs-md-16 m-0 text-ellips-149px">
                      {groupParticipant}
                      {lang?.participants}
                    </h3>
                  </div>
                  <IoIosArrowForward size={20} />
                </div>

                <div
                  className="box-shadow1 d-flex justify-content-between align-items-center p-16 br-10 mt-3 cursor manue-hover hover-blue"
                  onClick={handleLeaveGroupClick}
                >
                  <div className="d-flex gap-2 align-items-center">
                    <div>
                      <RxExit size={24} />
                    </div>
                    <h3
                      className="fs-18 fs-md-16 m-0 text-ellips-149px"
                      style={{ color: "red" }}
                    >
                      {lang?.leave_group}
                    </h3>
                  </div>
                </div>

                <div
                  className="box-shadow1 d-flex justify-content-between align-items-center p-16 br-10 cursor manue-hover hover-blue"
                  onClick={handleReportGroupClick}
                >
                  <div className="d-flex gap-2 align-items-center">
                    <div>
                      <MdBlock size={24} />
                    </div>
                    <div>
                      <h3
                        className="fs-18 fs-md-16 m-0 text-ellips-149px"
                        style={{ color: "red" }}
                      >
                        {lang?.report_group}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* manage notification modal */}
      <Modal
        isOpen={notificationsModalOpen}
        centered
        toggle={toggleNotificationsModal}
        size="lg"
      >
        <ModalHeader toggle={toggleNotificationsModal}>
          {lang?.notifications}
        </ModalHeader>
        <ModalBody>
          <div className="job-scroll">
            <div className="box-shadow1 d-flex justify-content-between align-items-center p-16 br-10 manue-hover">
              <h3 className="fs-18 fs-md-16 m-0 text-ellips-149px">
                {lang?.manage_notifications}
              </h3>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={notificationStatus}
                  onChange={() => {
                    handleMuteNotifications();
                    setNotificationStatus(!notificationStatus);
                  }}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* group media modal */}
      <Modal
        isOpen={groupmediamodalOpen}
        centered
        toggle={toggleGroupMediaModal}
        size="lg"
      >
        <ModalHeader toggle={toggleGroupMediaModal}>
          <h3 className="fs-18 fs-md-15 text-center m-0 text-ellips-149px">
            {lang?.media_photo_videos}
          </h3>
        </ModalHeader>
        <ModalBody>
          <div className="job-scroll">
            <div className="container-fluid">
              <div className="row text-center ">
                {groupphotoLoading ? (
                  <div className="vh-100 d-flex justify-content-center align-items-center">
                    <SpecSpinner />
                  </div>
                ) : groupPhoto.length > 0 ? (
                  groupPhoto.map((item) => (
                    <>
                      <div
                        key={item.id}
                        onClick={() =>
                          setIsGroupImageModalOpen(BaseURL + item.attachment)
                        }
                        className="col-md-3 my-1 p-1 text-center"
                      >
                        <div className="card p-1">
                          <ImageComponent
                            src={BaseURL + item.attachment}
                            compClassName="groupimageshadow rounded object-fit-cover cursor"
                            img_height={250}
                            loader_height="100%"
                            loader_width="100%"
                          />
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  <div className="col-md-12 text-center">
                    <p>{lang?.no_photos_available}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        toggle={() => {
          setIsGroupImageModalOpen(null);
        }}
        isOpen={isgroupimagemodalOpen}
        centered
      >
        <div className="vh-100">
          <ImageComponent
            src={isgroupimagemodalOpen}
            compClassName="img-fluid h-100 w-100 object-fit-contain"
            loader_height="100%"
            loader_width="100%"
          />
        </div>
      </Modal>

      {/* get group participants modal */}
      <Modal
        isOpen={groupparticipantsmodalOpen}
        centered
        toggle={toggleGroupParticipantsModal}
        size="lg"
      >
        <ModalHeader toggle={toggleGroupParticipantsModal}>
          {lang?.participants_list}
        </ModalHeader>
        <ModalBody>
          <InfiniteScroll
            pageStart={0}
            loadMore={handleNewPageData}
            hasMore={page < totalPages}
            loader={
              <div className="w-100 text-center" key={0}>
                <img src={SpinnerGif} alt="picture" width={70} />
              </div>
            }
            useWindow={false}
          >
            <div>
              <div className="container-fluid job-scroll scroll-hide">
                <div className="row">
                  {groupparticipantsCounts.map((item) => (
                    <Link to={PROFILE + "/" + item.user_id}>
                      <div className="block-account d-flex flex-column gap-4 mb-2 mt-2 text-dark ">
                        <div
                          className="box-shadow1 d-flex justify-content-between align-items-center px-3 py-2 manue-hover rounded"
                          key={item}
                        >
                          <div className="d-flex align-items-center gap-md-4 gap-sm-3 gap-2 ">
                            <span>
                              <ImageComponent
                                src={
                                  item.user.image
                                    ? BaseURL + item.user.image
                                    : Avatar
                                }
                                loader_height="100%"
                                loader_width="100%"
                              />
                            </span>
                            <div>
                              <h3 className="fs-18 fs-md-15 fw-bold m-0 text-ellips-110px">
                                {item.user.name}
                              </h3>
                              <h6 className="fs-18 fs-md-16 text-gray m-0 text-ellips-110px">
                                {item.user.name}
                              </h6>
                              <h6
                                className="fs-18 fs-md-16 text-gray m-0 text-ellips-110px "
                                style={{ color: "blue" }}
                              >
                                {item.admin === 1
                                  ? "Admin"
                                  : item.admin === 0
                                  ? ""
                                  : null}
                              </h6>
                            </div>
                          </div>
                          <IoIosArrowForward size={20} />
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </InfiniteScroll>
        </ModalBody>
      </Modal>

      {/* add participants modal */}
      <Modal
        isOpen={groupaddparticipantsmodalOpen}
        centered
        toggle={toggleGroupAddParticipantsModal}
        size="lg"
      >
        <ModalHeader toggle={toggleGroupAddParticipantsModal}>
          <div className="py-3">
            <div className="position-relative">
              <img
                src={Search}
                className="position-absolute start-0 ms-3 mt-11"
                width={20}
                height={20}
                alt="picture"
              />
              <input
                type="text"
                className="form-control py-2 ps-5"
                placeholder={lang?.search}
                autoFocus
                onChange={(e) => handleGroupSearch(e)}
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="p-0">
          <div>
            <div className="container-fluid job-scroll scroll-hide">
              <div className="row">
                {searchgroupMembers.map((value) => (
                  <div
                    className="block-account d-flex flex-column gap-4 mb-2 mt-2 text-dark "
                    key={value}
                  >
                    <div className="box-shadow1 d-flex justify-content-between align-items-center px-3 py-2 manue-hover rounded">
                      <div className="d-flex align-items-center gap-md-4 gap-sm-3 gap-2">
                        <span>
                          <ImageComponent
                            src={value.image ? BaseURL + value.image : Avatar}
                            loader_height="100%"
                            loader_width="100%"
                          />
                        </span>
                        <div>
                          <h3 className="fs-18 fs-md-15 fw-bold m-0 text-ellips-110px">
                            {value.name}
                          </h3>
                          <h6 className="fs-18 fs-md-16 text-gray m-0 text-ellips-110px">
                            {value.user_name}
                          </h6>
                        </div>
                      </div>
                      {value.joined ? (
                        <button className="added">{lang?.added}</button>
                      ) : (
                        <button
                          type="button"
                          className={`not-added ${
                            loadingButtons.includes(value.id) ? "loading" : ""
                          }`}
                          onClick={() => handleInviteMember(value.id)}
                        >
                          {loadingButtons.includes(value.id) ? (
                            <Spinner size={"sm"} />
                          ) : (
                            lang?.add
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="row">
                <div className="block-account d-flex flex-column gap-4 mb-2 mt-2">
                  {addgroupmembers.map((item) => (
                    <div className="box-shadow1 d-flex justify-content-between align-items-center px-3 py-2 manue-hover rounded">
                      <div
                        className="d-flex align-items-center gap-md-4 gap-sm-3 gap-2"
                        key={item.id}
                      >
                        <span>
                          <ImageComponent
                            src={item.image ? BaseURL + item.image : Avatar}
                            loader_height="100%"
                            loader_width="100%"
                          />
                        </span>
                        <div>
                          <h3 className="fs-18 fs-md-15 fw-bold m-0 text-ellips-110px">
                            {item.name}
                          </h3>
                          <h6 className="fs-18 fs-md-16 text-gray m-0 text-ellips-110px">
                            {item.user_name}
                          </h6>
                        </div>
                      </div>
                      {item.joined ? (
                        <button className="added">{lang?.added}</button>
                      ) : (
                        <button
                          type="button"
                          className={`not-added ${
                            loadingButtons.includes(item.id) ? "loading" : ""
                          }`}
                          onClick={() => handleInviteMember(item.id)}
                        >
                          {loadingButtons.includes(item.id) ? (
                            <Spinner size={"sm"} />
                          ) : (
                            lang?.add
                          )}
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* edit group info modal */}
      <Modal
        isOpen={editgroupmodalOpen}
        centered
        toggle={toggleEditGroupModal}
        size="lg"
      >
        <ModalHeader toggle={toggleEditGroupModal}>
          {lang?.edit_group}
        </ModalHeader>
        <ModalBody>
          <div className="container-fluid job-scroll ">
            <div className="row justify-content-center">
              <div className="col-md-12 mt-4">
                <div className="edit-profile br-8 pxy-20 bg-white  m-xl-0 m-lg-auto m-md-auto ">
                  <h3 className="d-flex justify-content-center mt--12 fs-20 fs-md-15 fw-bold">
                    {lang?.edit_group_info}
                  </h3>
                  {selectedUser ? (
                    <div>
                      <div className="d-flex justify-content-center mt-4">
                        <div className="profile_img position-relative">
                          <div onClick={openImageModal}>
                            <ImageComponent
                              src={
                                groupimageUpdate instanceof Blob
                                  ? URL.createObjectURL(groupimageUpdate)
                                  : selectedUser.image
                                  ? BaseURL + selectedUser.image
                                  : GroupAvatar
                              }
                              compClassName=""
                              loader_height="100%"
                              loader_width="100%"
                            />
                          </div>
                          <Modal
                            isOpen={isImageModalOpen}
                            centered
                            zIndex={9}
                            toggle={closeImageModal}
                          >
                            <img
                              src={
                                groupimageUpdate instanceof Blob
                                  ? URL.createObjectURL(groupimageUpdate)
                                  : selectedUser.image
                                  ? BaseURL + selectedUser.image
                                  : GroupAvatar
                              }
                              alt="Profile Image"
                              className="img-fluid"
                            />
                          </Modal>
                          <input
                            type="file"
                            id="groupmedia"
                            accept="image/*"
                            className="d-none"
                            onChange={(e) =>
                              setGroupImageUpdate(e.target.files[0])
                            }
                          />
                          <label
                            className="plus manue-hover br-8 border border-2"
                            htmlFor="groupmedia"
                          >
                            <HiPlus color="black" size={20} />
                          </label>
                        </div>
                      </div>

                      <div className="d-flex flex-column gap-4 mt-4">
                        <div>
                          <h6 className="fs-16 fw-bold opacity-75">
                            {" "}
                            {lang?.group_name}
                          </h6>
                          <input
                            type="text"
                            placeholder={lang?.type_group_name_here}
                            defaultValue={selectedUser.group_name}
                            onChange={(e) => setGroupNameUpdate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="Chatinfo px-3 p-2">
                      &nbsp;&nbsp;
                      <span className="chatheadertext"></span>
                    </div>
                  )}

                  <div className="d-flex flex-column gap-4 mt-3 my-5">
                    <button
                      type="button"
                      className="btn-blue border-0 px-4 py-2"
                      onClick={handleEditGroup}
                    >
                      {isloadinggroupUpdate ? (
                        <Spinner size={"sm"} />
                      ) : (
                        lang?.update
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* globalsearch modal */}

      <Modal
        scrollable
        size="lg"
        isOpen={isglobalsearchModalOpen}
        toggle={() => setIsGlobalSearchModalOpen(!isglobalsearchModalOpen)}
      >
        <ModalHeader toggle={setIsGlobalSearchModalOpen}>
          <div className="py-3">
            <div className="position-relative">
              <img
                src={Search}
                className="position-absolute start-0 ms-3 mt-11"
                width={20}
                height={20}
                alt="picture"
              />
              <input
                type="text"
                className="form-control searchgroupmembers py-2 ps-5"
                placeholder={lang?.search}
                autoFocus
                onChange={(e) => handleGlobalSearch(e)}
              />
            </div>
          </div>
        </ModalHeader>

        {/* Add Participants Modal Content */}
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h4>{lang?.friends}</h4>
              {selectedUserNames.map((user_name, index) => (
                <React.Fragment key={index}>
                  <button className="selectedusersgroup p-1">
                    {user_name}
                  </button>
                  {index < selectedUserNames.length - 1 && " "}{" "}
                  {/* Add space if not the last element */}
                </React.Fragment>
              ))}
            </div>
          </div>

          <div className="row">
            <div className="block-account d-flex flex-column gap-4 mb-5 mt-4 createglobalgroupheight scroll-hide">
              {searchglobalMembers.map((item) => (
                <div
                  className="box-shadow1 d-flex justify-content-between align-items-center p-16 br-10"
                  key={item}
                >
                  <div className="d-flex align-items-center gap-md-4 gap-sm-3 gap-2 ">
                    <span>
                      <img
                        src={item.image ? BaseURL + item.image : Avatar}
                        alt="picture"
                      />
                    </span>
                    <div>
                      <h3 className="fs-18 fs-md-15 fw-bold m-0 text-ellips-110px">
                        {item.name}
                      </h3>
                      <h6 className="fs-18 fs-md-16 text-gray m-0 text-ellips-110px">
                        {item.user_name}
                      </h6>
                    </div>
                  </div>
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange(item)}
                    checked={checkedItems[item.id] || false}
                    className="bg-blue border-0 pxy-17 br-6 text-white fs-18 fs-md-16"
                    style={{ transform: "scale(1.5)" }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default UserSidebarChat;
