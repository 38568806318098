import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Textarea from "../../src/shared/Textarea";
import Input from "../../src/shared/Input";
import close from "../../src/assets/images/icon/close-icon.svg";
import upload from "../../src/assets/images/icon/upload-icon.svg";
import { PhotoProvider, PhotoView } from "react-photo-view";
import Cropper from "../../src/cropper/components/CroppersWizard.tsx";
import axios from "axios";
import { BaseURL } from "../constants/API";
import { Col, Progress } from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FEED } from "../constants/RoutesUrl.js";
import { Language } from "../App.js";
const UploadPoll = () => {
  const { lang } = useContext(Language);
  const navigate = useNavigate();

  const localData = JSON.parse(sessionStorage.getItem("profileData"));
  const user_id = localData?.user?.id;

  const maxNumber = 1;
  const [coverImg, setCoverImg] = useState([]);
  const [imgError, setImgError] = useState("");
  const [showUploadStatus, setShowUploadStatus] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [option3, setOption3] = useState("");
  const [option4, setOption4] = useState("");
  const [progress, setProgress] = React.useState(0);
  const [height, setHeight] = React.useState(undefined);
  const [width, setWidth] = React.useState(undefined);
  const [top, setTop] = React.useState(undefined);
  const [left, setLeft] = React.useState(undefined);
  const [hasSettings, setHasSettings] = React.useState(true);
  const [updateCoordinatesCheck, setUpdateCoordinatesCheck] = React.useState(
    true
  );
  const [cropperOpen, setCropperOpen] = React.useState(false);
  const [ar, setAR] = React.useState(undefined);
  const [minARatio, setMinAR] = React.useState(undefined);
  const [maxARatio, setMaxAR] = React.useState(undefined);
  const [resizable, setResizable] = React.useState(true);
  const [fileUploadRefs, setFileUploadRefs] = React.useState({});
  const [numberOfImages, setNumberOfImages] = React.useState(
    coverImg.length < maxNumber ? coverImg.length : maxNumber
  );
  const handleClose = () => {
    setCropperOpen(false);
    // setcheckindex(null);
    // setCoverImg([]);
  };
  const onImageRemove = (index) => {
    const updatedImages = [...coverImg];
    updatedImages.splice(index, 1);
    setCoverImg(updatedImages);
    setHasSettings(true);
  };
  const formik = useFormik({
    initialValues: {
      question: "",
      option_1: "",
      option_2: "",
    },
    onSubmit: (values) => {
      uploadPoll(values);
      setHasSettings(true);
      setCoverImg("");
      setOption3("");
      setOption4("");
      setLoading(true);
      formik.resetForm({
        question: "",
        option_1: "",
        option_2: "",
      });
      // window.location.reload()
    },
    validationSchema: yup.object({
      question: yup.string().required(lang?.please_enter_your_question),
      option_1: yup.string().required(lang?.please_enter_option_1),
      option_2: yup.string().required(lang?.please_enter_option_2),
    }),
  });
  const uploadPoll = async (data) => {
    setShowUploadStatus(true);
    let newData = {
      title: data.question,
      option_1: data.option_1,
      option_2: data.option_2,
      option_3: option3,
      option_4: option4,
      type: "poll",
      caption: "...",
      mediaBase64: coverImg[0],
    };
    try {
      await axios
        .post(BaseURL + `api/poll/create/${user_id}`, newData, {
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        })
        .then((res) => {
          if (res.data.status) {
            navigate(FEED);
          } else {
            toast.error(res.data.error);
          }
        });
    } catch (error) {
      setShowUploadStatus(false);
    } finally {
      setShowUploadStatus(false);
      setLoading(false);
    }
  };
  return (
    <>
      <Col md={8} className="bg-white br-14 p-3">
        <div className="text-center">
          <h5>{lang?.create_pll}</h5>
        </div>
        <form>
          <div className="add-img">
            <p className="title-size">
              {lang?.attach_image}{" "}
              <span className="optioanl">({lang?.optional})</span>
            </p>
            <div className="d-flex">
              <div className="p-relative">
                {!coverImg.length && !cropperOpen && (
                  <div
                    className="upload-file margin-bottom c-pointer p-relative"
                    onClick={() =>
                      fileUploadRefs[numberOfImages - 1].current.click()
                    }
                  >
                    <img src={upload} alt={"Choose file upload"} />
                    <p>{lang?.choose_file_upload}</p>
                  </div>
                )}
                <Cropper
                  images={coverImg}
                  setImages={setCoverImg}
                  setImagesArr={setCoverImg}
                  value={coverImg}
                  max={maxNumber}
                  allowCrop={true}
                  ar={ar}
                  setAR={setAR}
                  setMaxAR={setMaxAR}
                  setMinAR={setMinAR}
                  resizable={resizable}
                  setResizable={setResizable}
                  handleClose={handleClose}
                  fileUploadRefs={fileUploadRefs}
                  setFileUploadRefs={setFileUploadRefs}
                  numberOfImages={numberOfImages}
                  setNumberOfImages={setNumberOfImages}
                  hasSettings={hasSettings}
                  setHasSettings={setHasSettings}
                  updateCoordinatesCheck={updateCoordinatesCheck}
                  setUpdateCoordinatesCheck={setUpdateCoordinatesCheck}
                  height={height}
                  setHeight={setHeight}
                  width={width}
                  setWidth={setWidth}
                  top={top}
                  setTop={setTop}
                  left={left}
                  setLeft={setLeft}
                  cropperOpen={cropperOpen}
                  setCropperOpen={setCropperOpen}
                />
                <div>
                  {Array(maxNumber)
                    .fill()
                    .map((_, index) => (
                      <>
                        {coverImg[index] ? (
                          <>
                            <PhotoProvider
                              speed={() => 800}
                              easing={(type) =>
                                type === 2
                                  ? "cubic-bezier(0.36, 0, 0.66, -0.56)"
                                  : "cubic-bezier(0.34, 1.56, 0.64, 1)"
                              }
                            >
                              <PhotoView src={coverImg[index]}>
                                <div key={index} className="course-img">
                                  <img
                                    className="cropperImg"
                                    src={coverImg[index]}
                                    alt="picture"
                                  />
                                </div>
                              </PhotoView>
                            </PhotoProvider>
                            <img
                              className="close-icon c-pointer"
                              onClick={() => onImageRemove(index)}
                              value={coverImg}
                              onChange={(e) => setCoverImg(e.target.value)}
                              src={close}
                              alt="Remove"
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                </div>
              </div>
              <div className="img-error">
                {coverImg.length === 0 && imgError && <p>{imgError}</p>}
              </div>
            </div>
          </div>
          <div className="textarea p-relative title-size">
            <Textarea
              label={lang?.your_question}
              placeholder={lang?.enter_question}
              type="text"
              id="question"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.question}
              errors={formik.touched.question && formik.errors.question}
              helperText={formik.touched.question && formik.errors.question}
              required
            />
          </div>
          <div className="title-size course-feilds select m-top20">
            <Input
              label={lang?.option_1}
              placeholder={lang?.add_option}
              id="option_1"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.option_1}
              errors={formik.touched.option_1 && formik.errors.option_1}
              helperText={formik.touched.option_1 && formik.errors.option_1}
              required
            />
          </div>
          <div className="title-size course-feilds select m-top20">
            <Input
              label={lang?.option_2}
              placeholder={lang?.add_option}
              id="option_2"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.option_2}
              errors={formik.touched.option_2 && formik.errors.option_2}
              helperText={formik.touched.option_2 && formik.errors.option_2}
              required
            />
          </div>
          <div className="title-size course-feilds select m-top20">
            <Input
              label={lang?.option_3_optional}
              type="text"
              value={option3}
              onChange={(e) => setOption3(e.target.value)}
              placeholder={lang?.add_option}
            />
          </div>
          <div className="title-size course-feilds select m-top20">
            <Input
              label={lang?.option_4_optional}
              type="text"
              value={option4}
              onChange={(e) => setOption4(e.target.value)}
              placeholder={lang?.add_option}
            />
          </div>
          {!showUploadStatus && (
            <button
              className="upload-btn c-pointer"
              onClick={formik.handleSubmit}
            >
              {lang?.upload}
            </button>
          )}
          {showUploadStatus && (
            <div className="progresss heading-title">
              <p>
                {lang?.upload_status} {progress}%
              </p>
              <p>
                {lang?.during_uploading_please_refresh}
                <br /> {lang?.or_dont_another_page_this}
                <br /> {lang?.then_upload_process_cancel}{" "}
              </p>
              <Progress animated color="primary" striped value={progress} />
            </div>
          )}
        </form>
      </Col>
    </>
  );
};

export default UploadPoll;
