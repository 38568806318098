import React, { useState,useContext } from "react";
import { BaseURL } from "../../constants/API";
import { useNavigate } from "react-router-dom";
import { COURSE_DETAILS } from "../../constants/RoutesUrl";
import SpinnerGif from "../../assets/images/image-loader.gif";
import ImageComponent from "../common/ImageComponent";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { Carousel } from "react-responsive-carousel";
import { Language } from "../../App";

const CoursePreview = (props) => {
  const navigate = useNavigate();
  const { lang } = useContext(Language);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const strNumber = props.price;
  const doubleNumber = parseFloat(strNumber);
  const hasDecimal = strNumber.includes(".");
  const formattedNumber = hasDecimal
    ? doubleNumber.toString()
    : doubleNumber.toFixed(2);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const handleClick = () => {
    sessionStorage.setItem("CourseId", props?.course_id);
    navigate(COURSE_DETAILS);
  };

  return (
    <div className="border border-1 cursor br-12" onClick={handleClick}>
      <div className="position-relative">
        <div
          style={{
            borderRadius: "12px 12px 0px 0px",
            height: "auto",
            width: "270px",
          }}
        >
          <img
            src={BaseURL + props?.image}
            className={isImageLoaded ? "d-block" : "d-none"}
            style={{
              borderRadius: "12px 12px 0px 0px",
              height: "315px",
              width: "100%",
              objectFit: "fill",
            }}
            onLoad={handleImageLoad}
            alt="picture"
          />
        </div>
        {!isImageLoaded && (
          <div className="position-relative">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                borderRadius: "12px 12px 0px 0px",
                background: "#f9f9f9",
                height: "315px",
                width: "270px",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
            <div
              className={`position-absolute text-center align-self-center d-flex align-items-center justify-content-center m-0 top-0 bg-transparent`}
              style={{
                height: "315px",
                width: "270px",
              }}
            >
              <div className="text-center">
                <img src={SpinnerGif} alt="picture" width={50} height={50} />
              </div>
            </div>
          </div>
        )}

        <div
          className="position-absolute top-0 start-0 px-3 py-1 bg-green"
          style={{ borderRadius: "11px 0px 9px 0px" }}
        >
          <span className="fs-15 roboto-medium text-white">
            {props?.price === "0" ? lang?.free : "$" + formattedNumber}
          </span>
        </div>
      </div>
      <div
        className="p-3 bg-white br-b-5"
        style={{ borderRadius: "0px 0px 12px 12px", width: "270px" }}
      >
        {props.certificates && props.certificates.length !== 0 ? (
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            showIndicators={false}
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            interval={1500}
          >
            {props.certificates.map((value, index) => (
              <div className="d-flex gap-2 align-items-center">
                <div style={{ width: "65px", height: "35px" }}>
                  <ImageComponent
                    key={index}
                    src={BaseURL + value.media}
                    img_height="30px"
                    img_width="65px"
                    loader_height="30px"
                    loader_width="65px"
                    compClassName="rounded object-contain"
                  />
                </div>
                <div className="align-self-center">
                  <h3
                    className="fs-14 roboto-medium text-start mb-0"
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {value.title}
                  </h3>
                </div>
              </div>
            ))}
          </Carousel>
        ) : null}

        {props?.certificates &&
        props.certificates.length === 0 &&
        props.owner_image ? (
          <div className="d-flex gap-2 align-items-center">
            <div style={{ width: "35px", height: "35px" }}>
              <ImageComponent
                src={
                  props.owner_image[0] === "..."
                    ? Avatar
                    : BaseURL + props.owner_image[0]
                }
                compClassName="br-12"
                img_height="35px"
                img_width="35px"
                loader_height="35px"
                loader_width="35px"
              />
            </div>
            <div className="align-self-center">
              <h3
                className="fs-14 roboto-medium text-start mb-0"
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {props.owner_username}
              </h3>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CoursePreview;
