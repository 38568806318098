import React from "react";
const Textarea = ({
  label,
  errors,
  name,
  touched,
  className,
  inputClassName,
  type,
  ...rest
}) => {
  return (
    <div className={`textarea ${className || "title-size"}`}>
      {label && <label>{label}</label>}
      <br />
      <textarea type={type} {...rest} className={inputClassName || ""} />
      {errors && <div className="description-error">{errors}</div>}
    </div>
  );
};
export default Textarea;
